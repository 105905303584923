

import * as Msw from "msw";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as DateFns from "date-fns";
import * as MockOverlay from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/mock/MockOverlay.bs.js";
import * as Browser from "msw/browser";
import * as Caml_splice_call from "../../../../node_modules/rescript/lib/es6/caml_splice_call.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V5_Admin from "../../src/v5/ColiswebApi__V5_Admin.bs.js";
import * as ApiComponents__RouteTimelineBanner from "../../src/components/ApiComponents__RouteTimelineBanner.bs.js";

function makePickupTask(index) {
  return {
          NAME: "singlePickup",
          VAL: {
            deliveryId: String(index),
            taskStatus: "idle",
            deletionCause: undefined,
            deliveryState: "idle",
            geofencingEntranceTime: undefined
          }
        };
}

function makeDropoffTask(index) {
  return {
          NAME: "singleDropoff",
          VAL: {
            deliveryId: String(index),
            taskStatus: "idle",
            deletionCause: undefined,
            deliveryState: "idle",
            stateTime: new Date(),
            tryCount: 0,
            heftingOptions: undefined,
            geofencingEntranceTime: undefined
          }
        };
}

function makeTask(index, taskType) {
  return {
          coordinates: Toolkit__Decoders.Coordinates.make(50 + index / 100, 3),
          plannedSlot: {
            start: DateFns.addHours(new Date(), index),
            end: DateFns.addHours(new Date(), index)
          },
          estimatedArrivalSlot: {
            start: DateFns.addHours(new Date(), index),
            end: DateFns.addHours(new Date(), index)
          },
          estimatedDeliveredSlot: {
            start: DateFns.addHours(new Date(), index),
            end: DateFns.addHours(new Date(), index)
          },
          realisedTime: undefined,
          taskType: taskType === "singleDropoff" ? makeDropoffTask(index) : (
              taskType === "break" ? "break" : makePickupTask(index)
            ),
          address: undefined,
          skills: [],
          packaging: {
            weightKg: 10,
            description: "description",
            packetsCount: 1,
            longestPacket: {
              weight: 100,
              lengthCm: undefined,
              widthCm: undefined,
              heightCm: undefined,
              volumeM3: undefined
            },
            heaviestPacket: {
              weight: 5,
              lengthCm: undefined,
              widthCm: undefined,
              heightCm: undefined,
              volumeM3: undefined
            },
            biggestPacket: {
              weight: 10,
              lengthCm: undefined,
              widthCm: undefined,
              heightCm: undefined,
              volumeM3: undefined
            }
          },
          transitionFactors: {
            handlingTimeInMinutes: 4,
            travelTimeFromPreviousInMinutes: 4,
            distanceFromPreviousInKm: 4,
            waitingTimeInMinutes: 4,
            slackTimeInMinutes: 4,
            breakTimeInMinutes: 10
          },
          taskOrder: index,
          updatedEstimation: undefined,
          maximumArrivalAdvanceInMinutes: 3,
          maximumCompletionDelayInMinutes: undefined
        };
}

var data_routeSummary = {
  planId: "planId",
  routeId: "routeId",
  projectId: "projectId",
  deliveryDay: new Date(),
  pickupBefore: new Date(),
  endAt: new Date(),
  vehicleFormat: undefined,
  leaveDepot: new Date(),
  arriveAtDepot: new Date(),
  totalTimeInMinutes: 110,
  totalDistanceInKm: 100,
  totalBuyPriceInEuros: 200,
  packetsCount: 8,
  routeStatus: "idle",
  firstLoad: new Date(),
  loadCount: 4,
  firstUnload: new Date(),
  unloadCount: 4,
  failedUnload: 0,
  successfulUnload: 0,
  rateLabel: "rate label",
  prepaid: false,
  projectName: "projectname",
  totalSpotPrice: {
    NAME: "EUR",
    VAL: 300
  },
  spotMissingPrice: ["1"],
  vehicleEnergyType: undefined,
  energyConsumedRatio: undefined,
  energyConsumed: undefined,
  totalBreakTimeInMinutes: 20,
  costOrigin: "grid"
};

var data_proposals = [];

var data_proposalsHistory = [];

var data_performedTasks = [
  makeTask(0, "singlePickup"),
  makeTask(1, "singlePickup"),
  makeTask(2, "break"),
  makeTask(3, "singlePickup"),
  makeTask(0, "singleDropoff"),
  makeTask(1, "singleDropoff"),
  makeTask(3, "singleDropoff"),
  makeTask(3, "break")
];

var data_incidents = [];

var data = {
  routeSummary: data_routeSummary,
  proposals: data_proposals,
  proposalsHistory: data_proposalsHistory,
  performedTasks: data_performedTasks,
  incidents: data_incidents
};

var handlers = [Msw.http.get("https://api.testing.colisweb.com/api/v5/admin/routes/routeId/latestLocations", (function (param) {
          var response = {
            locations: [{
                deliveryId: "deliveryId",
                geocodedAt: new Date(),
                latitude: 50,
                longitude: 3
              }]
          };
          return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Admin.GetRouteLatestLocations.Config.response_encode, response), {
                      status: 200
                    });
        }))];

var worker = Caml_splice_call.spliceApply(Browser.setupWorker, [handlers]);

function Playground_RouteTimelineBanner(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(MockOverlay.make, {
                    worker: worker,
                    workerOptions: import.meta.env.PROD ? ({
                          serviceWorker: {
                            url: "/mockServiceWorker.js"
                          }
                        }) : ({}),
                    className: "!absolute -top-20",
                    children: JsxRuntime.jsx(ApiComponents__RouteTimelineBanner.make, {
                          routeDetails: data,
                          onMarkerClick: (function (i) {
                              console.log(i);
                            })
                        })
                  })
            });
}

var make = Playground_RouteTimelineBanner;

export {
  data ,
  handlers ,
  worker ,
  make ,
}
/* data Not a pure module */
