

import * as Ky from "ky";
import * as Qs from "qs";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V6_Utils from "./ColiswebApi__V6_Utils.bs.js";

var prefixBaseUrl = ColiswebApi__Env.v6ApiUrl + "/account/external";

var prefixPublicBaseUrl = ColiswebApi__Env.v6ApiUrl + "/account/public";

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "alreadyExistingEmails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.alreadyExistingEmails)
                  ]]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var alreadyExistingEmails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "alreadyExistingEmails"), null));
  if (alreadyExistingEmails.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              alreadyExistingEmails: alreadyExistingEmails._0
            }
          };
  }
  var e = alreadyExistingEmails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".alreadyExistingEmails" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function error_encode(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

function error_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("MAX_NUMBER_OF_ADMINISTRATOR" === v) {
          return {
                  TAG: "Ok",
                  _0: "MAX_NUMBER_OF_ADMINISTRATOR"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Invitation = {
  response_encode: response_encode,
  response_decode: response_decode,
  error_encode: error_encode,
  error_decode: error_decode
};

function userUpdateError_encode(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

function userUpdateError_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("MAX_NUMBER_OF_ADMINISTRATOR" === v) {
          return {
                  TAG: "Ok",
                  _0: "MAX_NUMBER_OF_ADMINISTRATOR"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function t_encode(v) {
  switch (v) {
    case "superAdmin" :
        return "superAdmin";
    case "techAdmin" :
        return "techAdmin";
    case "clientAdmin" :
        return "clientAdmin";
    case "savAdmin" :
        return "savAdmin";
    case "admin" :
        return "admin";
    case "clientSuperAdmin" :
        return "clientSuperAdmin";
    case "storeSuperAdmin" :
        return "storeSuperAdmin";
    case "storeAdmin" :
        return "storeAdmin";
    case "transporterSuperAdmin" :
        return "transporterSuperAdmin";
    case "transporterAdmin" :
        return "transporterAdmin";
    case "eRetailer" :
        return "eRetailer";
    case "carrier" :
        return "carrier";
    case "transporterPoleAdmin" :
        return "transporterPoleAdmin";
    case "customer" :
        return "customer";
    case "supplier" :
        return "supplier";
    case "hubHandler" :
        return "hubHandler";
    case "externalService" :
        return "externalService";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("superAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "superAdmin"
                };
        } else if ("techAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "techAdmin"
                };
        } else if ("clientAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "clientAdmin"
                };
        } else if ("savAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "savAdmin"
                };
        } else if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("clientSuperAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "clientSuperAdmin"
                };
        } else if ("storeSuperAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "storeSuperAdmin"
                };
        } else if ("storeAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "storeAdmin"
                };
        } else if ("transporterSuperAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporterSuperAdmin"
                };
        } else if ("transporterAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporterAdmin"
                };
        } else if ("eRetailer" === v) {
          return {
                  TAG: "Ok",
                  _0: "eRetailer"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("transporterPoleAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporterPoleAdmin"
                };
        } else if ("customer" === v) {
          return {
                  TAG: "Ok",
                  _0: "customer"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else if ("hubHandler" === v) {
          return {
                  TAG: "Ok",
                  _0: "hubHandler"
                };
        } else if ("externalService" === v) {
          return {
                  TAG: "Ok",
                  _0: "externalService"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode;

var Role = {
  t_encode: t_encode,
  t_decode: t_decode,
  toString: toString
};

function user_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ],
                  [
                    "expiresAt",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.Datetime.t_encode, v.expiresAt)
                  ]
                ]));
}

function user_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
      if (organizationRole.TAG === "Ok") {
        var expiresAt = Spice.optionFromJson(Toolkit__Decoders.Datetime.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "expiresAt"), null));
        if (expiresAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    uuid: uuid._0,
                    email: email._0,
                    organizationRole: organizationRole._0,
                    expiresAt: expiresAt._0
                  }
                };
        }
        var e = expiresAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".expiresAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = organizationRole._0;
      return {
              TAG: "Error",
              _0: {
                path: ".organizationRole" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var Shared = {
  Invitation: Invitation,
  userUpdateError_encode: userUpdateError_encode,
  userUpdateError_decode: userUpdateError_decode,
  Role: Role,
  user_encode: user_encode,
  user_decode: user_decode
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/users"
    });

var kyInstance$2 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/admin"
    });

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "role",
                    false,
                    t_encode(v.role)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
      if (password.TAG === "Ok") {
        var role = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
        if (role.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    username: username._0,
                    email: email._0,
                    password: password._0,
                    role: role._0
                  }
                };
        }
        var e = role._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".role" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = password._0;
      return {
              TAG: "Error",
              _0: {
                path: ".password" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$1 = Spice.unitToJson;

var response_decode$1 = Spice.unitFromJson;

function error_encode$1(v) {
  switch (v) {
    case "USER_CONFLICT" :
        return "USER_CONFLICT";
    case "EMAIL_CONFLICT" :
        return "EMAIL_CONFLICT";
    case "INSUFFISANT_PERMISSION" :
        return "INSUFFISANT_PERMISSION";
    
  }
}

function error_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else if ("EMAIL_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "EMAIL_CONFLICT"
                };
        } else if ("INSUFFISANT_PERMISSION" === v) {
          return {
                  TAG: "Ok",
                  _0: "INSUFFISANT_PERMISSION"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config(body) {
  return {
          kyInstance: kyInstance$2,
          path: "",
          requestOptions: {
            method: "POST",
            json: body_encode(body)
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$1, param);
            })
        };
}

var Create = {
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  error_encode: error_encode$1,
  error_decode: error_decode$1,
  config: config
};

var Admin = {
  kyInstance: kyInstance$2,
  Create: Create
};

function argument_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              email: email._0
            }
          };
  }
  var e = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "isAvailable",
                    false,
                    Spice.boolToJson(v.isAvailable)
                  ]]));
}

function response_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var isAvailable = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "isAvailable"), null));
  if (isAvailable.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              isAvailable: isAvailable._0
            }
          };
  }
  var e = isAvailable._0;
  return {
          TAG: "Error",
          _0: {
            path: ".isAvailable" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$1(params) {
  return {
          kyInstance: kyInstance$1,
          path: "isAvailable",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode(params))
          }
        };
}

var IsAvailable = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  config: config$1
};

function argument_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ]]));
}

function argument_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              username: username._0
            }
          };
  }
  var e = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$3 = Spice.unitToJson;

var response_decode$3 = Spice.unitFromJson;

function config$2(params) {
  return {
          kyInstance: kyInstance$1,
          path: "",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$1(params))
          }
        };
}

var GetByUsername = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  config: config$2
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "password",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.password)
                  ],
                  [
                    "phoneNumber",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phoneNumber)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
    if (email.TAG === "Ok") {
      var password = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
      if (password.TAG === "Ok") {
        var phoneNumber = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phoneNumber"), null));
        if (phoneNumber.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    username: username._0,
                    email: email._0,
                    password: password._0,
                    phoneNumber: phoneNumber._0
                  }
                };
        }
        var e = phoneNumber._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phoneNumber" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = password._0;
      return {
              TAG: "Error",
              _0: {
                path: ".password" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = email._0;
    return {
            TAG: "Error",
            _0: {
              path: ".email" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$4 = Spice.unitToJson;

var response_decode$4 = Spice.unitFromJson;

function error_encode$2(v) {
  switch (v) {
    case "USER_CONFLICT" :
        return "USER_CONFLICT";
    case "EMAIL_CONFLICT" :
        return "EMAIL_CONFLICT";
    case "USER_NOT_FOUND" :
        return "USER_NOT_FOUND";
    
  }
}

function error_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_CONFLICT"
                };
        } else if ("EMAIL_CONFLICT" === v) {
          return {
                  TAG: "Ok",
                  _0: "EMAIL_CONFLICT"
                };
        } else if ("USER_NOT_FOUND" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_NOT_FOUND"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$3(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode$1(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$2, param);
            })
        };
}

var Update = {
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  error_encode: error_encode$2,
  error_decode: error_decode$2,
  config: config$3
};

var response_encode$5 = Spice.unitToJson;

var response_decode$5 = Spice.unitFromJson;

function error_encode$3(v) {
  return "USER_NOT_FOUND";
}

function error_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("USER_NOT_FOUND" === v) {
          return {
                  TAG: "Ok",
                  _0: "USER_NOT_FOUND"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$4(userId) {
  return {
          kyInstance: kyInstance$1,
          path: userId,
          requestOptions: {
            method: "DELETE"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$3, param);
            })
        };
}

var Delete = {
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  error_encode: error_encode$3,
  error_decode: error_decode$3,
  config: config$4
};

var kyInstance$3 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/human"
    });

function response_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.UserId.t_encode, v.id)
                  ],
                  [
                    "uuid",
                    false,
                    Curry._1(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "email",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.email)
                  ],
                  [
                    "role",
                    false,
                    t_encode(v.role)
                  ],
                  [
                    "roleAssociationId",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.roleAssociationId)
                  ]
                ]));
}

function response_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.UserId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var uuid = Curry._1(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
    if (uuid.TAG === "Ok") {
      var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
      if (username.TAG === "Ok") {
        var email = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
        if (email.TAG === "Ok") {
          var role = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
          if (role.TAG === "Ok") {
            var roleAssociationId = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "roleAssociationId"), null));
            if (roleAssociationId.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        id: id._0,
                        uuid: uuid._0,
                        username: username._0,
                        email: email._0,
                        role: role._0,
                        roleAssociationId: roleAssociationId._0
                      }
                    };
            }
            var e = roleAssociationId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".roleAssociationId" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = role._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".role" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = email._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".email" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = username._0;
      return {
              TAG: "Error",
              _0: {
                path: ".username" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = uuid._0;
    return {
            TAG: "Error",
            _0: {
              path: ".uuid" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function error_encode$4(v) {
  return ["UserNotFound"];
}

function error_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string" && match === "UserNotFound") {
    if (v.length !== 1) {
      return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
    } else {
      return {
              TAG: "Ok",
              _0: "USER_NOT_FOUND"
            };
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function config$5(userId) {
  return {
          kyInstance: kyInstance$3,
          path: userId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "AccountHumanGet",
            userId
          ],
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$4, param);
            })
        };
}

var Get = {
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  error_encode: error_encode$4,
  error_decode: error_decode$4,
  config: config$5
};

var response_encode$7 = Spice.unitToJson;

var response_decode$7 = Spice.unitFromJson;

function config$6(userUuid) {
  return {
          kyInstance: kyInstance$3,
          path: userUuid + "/ownerPromote",
          requestOptions: {
            method: "PUT"
          }
        };
}

var OwnerPromote = {
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  config: config$6
};

var kyInstance$4 = kyInstance$3.extend({
      prefixUrl: prefixBaseUrl + "/users/human/invitations"
    });

var response_encode$8 = Spice.unitToJson;

var response_decode$8 = Spice.unitFromJson;

function config$7(invitationUuid) {
  return {
          kyInstance: kyInstance$4,
          path: invitationUuid + "/renewInvitation",
          requestOptions: {
            method: "PUT"
          }
        };
}

var RenewInvitation = {
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  config: config$7
};

var Invitations = {
  kyInstance: kyInstance$4,
  RenewInvitation: RenewInvitation
};

var Human = {
  kyInstance: kyInstance$3,
  Get: Get,
  OwnerPromote: OwnerPromote,
  Invitations: Invitations
};

var kyInstance$5 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/clients"
    });

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$9 = Spice.unitToJson;

var response_decode$9 = Spice.unitFromJson;

function error_encode$5(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$5 = userUpdateError_decode;

function config$8(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$2(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$5, param);
            })
        };
}

var Update$1 = {
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  error_encode: error_encode$5,
  error_decode: error_decode$5,
  config: config$8
};

var response_encode$10 = Spice.unitToJson;

var response_decode$10 = Spice.unitFromJson;

function error_encode$6(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$6 = userUpdateError_decode;

function config$9(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/invitations/" + param[1] + "/renewInvitation",
          requestOptions: {
            method: "PUT"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$6, param);
            })
        };
}

var Renew = {
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  error_encode: error_encode$6,
  error_decode: error_decode$6,
  config: config$9
};

var response_encode$11 = Spice.unitToJson;

var response_decode$11 = Spice.unitFromJson;

function config$10(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$1 = {
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  config: config$10
};

var Invitations$1 = {
  Update: Update$1,
  Renew: Renew,
  Delete: Delete$1
};

function response_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$11(clientId) {
  return {
          kyInstance: kyInstance$5,
          path: clientId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            clientId
          ]
        };
}

var List = {
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  config: config$11
};

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$13 = response_encode;

var response_decode$13 = response_decode;

function error_encode$7(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$7 = error_decode;

function config$12(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$3(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$7, param);
            })
        };
}

var SendInvitation = {
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  error_encode: error_encode$7,
  error_decode: error_decode$7,
  config: config$12
};

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$14 = Spice.unitToJson;

var response_decode$14 = Spice.unitFromJson;

function error_encode$8(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$8 = userUpdateError_decode;

function config$13(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$4(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$8, param);
            })
        };
}

var Update$2 = {
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  error_encode: error_encode$8,
  error_decode: error_decode$8,
  config: config$13
};

var response_encode$15 = Spice.unitToJson;

var response_decode$15 = Spice.unitFromJson;

var error_encode$9 = Spice.unitToJson;

var error_decode$9 = Spice.unitFromJson;

function config$14(param) {
  return {
          kyInstance: kyInstance$5,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$2 = {
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  error_encode: error_encode$9,
  error_decode: error_decode$9,
  config: config$14
};

var Human$1 = {
  List: List,
  SendInvitation: SendInvitation,
  Update: Update$2,
  Delete: Delete$2
};

function response_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$15(clientId) {
  return {
          kyInstance: kyInstance$5,
          path: clientId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetInvitedUsersList",
            clientId
          ]
        };
}

var List$1 = {
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  config: config$15
};

var Invitation$1 = {
  List: List$1
};

var Clients = {
  kyInstance: kyInstance$5,
  Invitations: Invitations$1,
  Human: Human$1,
  Invitation: Invitation$1
};

var kyInstance$6 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/clients"
    });

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$17 = Spice.unitToJson;

var response_decode$17 = Spice.unitFromJson;

function error_encode$10(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$10 = userUpdateError_decode;

function config$16(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/invitations/" + param[2],
          requestOptions: {
            method: "PUT",
            json: body_encode$5(param[3])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$10, param);
            })
        };
}

var Update$3 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  error_encode: error_encode$10,
  error_decode: error_decode$10,
  config: config$16
};

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function error_encode$11(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$11 = userUpdateError_decode;

function config$17(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/invitations/" + param[2] + "/renewInvitation",
          requestOptions: {
            method: "PUT"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$11, param);
            })
        };
}

var Renew$1 = {
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  error_encode: error_encode$11,
  error_decode: error_decode$11,
  config: config$17
};

var response_encode$19 = Spice.unitToJson;

var response_decode$19 = Spice.unitFromJson;

function config$18(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/invitations/" + param[2],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$3 = {
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  config: config$18
};

var Invitations$2 = {
  Update: Update$3,
  Renew: Renew$1,
  Delete: Delete$3
};

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$19(param) {
  var storeId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$6,
          path: clientId + "/stores/" + storeId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            clientId,
            storeId
          ]
        };
}

var List$2 = {
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  config: config$19
};

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$21 = response_encode;

var response_decode$21 = response_decode;

function error_encode$12(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$12 = error_decode;

function config$20(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$6(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$12, param);
            })
        };
}

var SendInvitation$1 = {
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  error_encode: error_encode$12,
  error_decode: error_decode$12,
  config: config$20
};

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$22 = Spice.unitToJson;

var response_decode$22 = Spice.unitFromJson;

function error_encode$13(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$13 = userUpdateError_decode;

function config$21(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/human/" + param[2],
          requestOptions: {
            method: "PUT",
            json: body_encode$7(param[3])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$13, param);
            })
        };
}

var Update$4 = {
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  error_encode: error_encode$13,
  error_decode: error_decode$13,
  config: config$21
};

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

var error_encode$14 = Spice.unitToJson;

var error_decode$14 = Spice.unitFromJson;

function config$22(param) {
  return {
          kyInstance: kyInstance$6,
          path: param[0] + "/stores/" + param[1] + "/human/" + param[2],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$4 = {
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  error_encode: error_encode$14,
  error_decode: error_decode$14,
  config: config$22
};

var Human$2 = {
  List: List$2,
  SendInvitation: SendInvitation$1,
  Update: Update$4,
  Delete: Delete$4
};

function response_encode$24(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$24(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$23(param) {
  var storeId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$6,
          path: clientId + "/stores/" + storeId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetInvitedUsersList",
            clientId,
            storeId
          ]
        };
}

var List$3 = {
  response_encode: response_encode$24,
  response_decode: response_decode$24,
  config: config$23
};

var Invitation$2 = {
  List: List$3
};

var Stores = {
  kyInstance: kyInstance$6,
  Invitations: Invitations$2,
  Human: Human$2,
  Invitation: Invitation$2
};

var kyInstance$7 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/transporters"
    });

function body_encode$8(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$8(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$25 = Spice.unitToJson;

var response_decode$25 = Spice.unitFromJson;

function error_encode$15(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$15 = userUpdateError_decode;

function config$24(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$8(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$15, param);
            })
        };
}

var Update$5 = {
  body_encode: body_encode$8,
  body_decode: body_decode$8,
  response_encode: response_encode$25,
  response_decode: response_decode$25,
  error_encode: error_encode$15,
  error_decode: error_decode$15,
  config: config$24
};

var response_encode$26 = Spice.unitToJson;

var response_decode$26 = Spice.unitFromJson;

function error_encode$16(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$16 = userUpdateError_decode;

function config$25(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/invitations/" + param[1] + "/renewInvitation",
          requestOptions: {
            method: "PUT"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$16, param);
            })
        };
}

var Renew$2 = {
  response_encode: response_encode$26,
  response_decode: response_decode$26,
  error_encode: error_encode$16,
  error_decode: error_decode$16,
  config: config$25
};

var response_encode$27 = Spice.unitToJson;

var response_decode$27 = Spice.unitFromJson;

function config$26(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$5 = {
  response_encode: response_encode$27,
  response_decode: response_decode$27,
  config: config$26
};

var Invitations$3 = {
  Update: Update$5,
  Renew: Renew$2,
  Delete: Delete$5
};

function response_encode$28(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$28(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$27(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            transporterId
          ]
        };
}

var List$4 = {
  response_encode: response_encode$28,
  response_decode: response_decode$28,
  config: config$27
};

function body_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$29 = response_encode;

var response_decode$29 = response_decode;

function error_encode$17(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$17 = error_decode;

function config$28(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$9(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$17, param);
            })
        };
}

var SendInvitation$2 = {
  body_encode: body_encode$9,
  body_decode: body_decode$9,
  response_encode: response_encode$29,
  response_decode: response_decode$29,
  error_encode: error_encode$17,
  error_decode: error_decode$17,
  config: config$28
};

function body_encode$10(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$10(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$30 = Spice.unitToJson;

var response_decode$30 = Spice.unitFromJson;

function error_encode$18(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$18 = userUpdateError_decode;

function config$29(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$10(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$18, param);
            })
        };
}

var Update$6 = {
  body_encode: body_encode$10,
  body_decode: body_decode$10,
  response_encode: response_encode$30,
  response_decode: response_decode$30,
  error_encode: error_encode$18,
  error_decode: error_decode$18,
  config: config$29
};

var response_encode$31 = Spice.unitToJson;

var response_decode$31 = Spice.unitFromJson;

var error_encode$19 = Spice.unitToJson;

var error_decode$19 = Spice.unitFromJson;

function config$30(param) {
  return {
          kyInstance: kyInstance$7,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$6 = {
  response_encode: response_encode$31,
  response_decode: response_decode$31,
  error_encode: error_encode$19,
  error_decode: error_decode$19,
  config: config$30
};

var Human$3 = {
  List: List$4,
  SendInvitation: SendInvitation$2,
  Update: Update$6,
  Delete: Delete$6
};

function response_encode$32(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$32(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$31(transporterId) {
  return {
          kyInstance: kyInstance$7,
          path: transporterId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "TransporterGetInvitedUsersList",
            transporterId
          ]
        };
}

var List$5 = {
  response_encode: response_encode$32,
  response_decode: response_decode$32,
  config: config$31
};

var Invitation$3 = {
  List: List$5
};

var Transporters = {
  kyInstance: kyInstance$7,
  Invitations: Invitations$3,
  Human: Human$3,
  Invitation: Invitation$3
};

var kyInstance$8 = kyInstance$1.extend({
      prefixUrl: prefixBaseUrl + "/users/hubs"
    });

function body_encode$11(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$33 = Spice.unitToJson;

var response_decode$33 = Spice.unitFromJson;

function error_encode$20(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$20 = userUpdateError_decode;

function config$32(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$11(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$20, param);
            })
        };
}

var Update$7 = {
  body_encode: body_encode$11,
  body_decode: body_decode$11,
  response_encode: response_encode$33,
  response_decode: response_decode$33,
  error_encode: error_encode$20,
  error_decode: error_decode$20,
  config: config$32
};

var response_encode$34 = Spice.unitToJson;

var response_decode$34 = Spice.unitFromJson;

function error_encode$21(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$21 = userUpdateError_decode;

function config$33(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/invitations/" + param[1] + "/renewInvitation",
          requestOptions: {
            method: "PUT"
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$21, param);
            })
        };
}

var Renew$3 = {
  response_encode: response_encode$34,
  response_decode: response_decode$34,
  error_encode: error_encode$21,
  error_decode: error_decode$21,
  config: config$33
};

var response_encode$35 = Spice.unitToJson;

var response_decode$35 = Spice.unitFromJson;

function config$34(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/invitations/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$7 = {
  response_encode: response_encode$35,
  response_decode: response_decode$35,
  config: config$34
};

var Invitations$4 = {
  Update: Update$7,
  Renew: Renew$3,
  Delete: Delete$7
};

function response_encode$36(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$36(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$35(hubId) {
  return {
          kyInstance: kyInstance$8,
          path: hubId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "HubGetHumanUsersList",
            hubId
          ]
        };
}

var List$6 = {
  response_encode: response_encode$36,
  response_decode: response_decode$36,
  config: config$35
};

function body_encode$12(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$37 = response_encode;

var response_decode$37 = response_decode;

function error_encode$22(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$22 = error_decode;

function config$36(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$12(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$22, param);
            })
        };
}

var SendInvitation$3 = {
  body_encode: body_encode$12,
  body_decode: body_decode$12,
  response_encode: response_encode$37,
  response_decode: response_decode$37,
  error_encode: error_encode$22,
  error_decode: error_decode$22,
  config: config$36
};

function body_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$38 = Spice.unitToJson;

var response_decode$38 = Spice.unitFromJson;

function error_encode$23(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$23 = userUpdateError_decode;

function config$37(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$13(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$23, param);
            })
        };
}

var Update$8 = {
  body_encode: body_encode$13,
  body_decode: body_decode$13,
  response_encode: response_encode$38,
  response_decode: response_decode$38,
  error_encode: error_encode$23,
  error_decode: error_decode$23,
  config: config$37
};

var response_encode$39 = Spice.unitToJson;

var response_decode$39 = Spice.unitFromJson;

var error_encode$24 = Spice.unitToJson;

var error_decode$24 = Spice.unitFromJson;

function config$38(param) {
  return {
          kyInstance: kyInstance$8,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$8 = {
  response_encode: response_encode$39,
  response_decode: response_decode$39,
  error_encode: error_encode$24,
  error_decode: error_decode$24,
  config: config$38
};

var Human$4 = {
  List: List$6,
  SendInvitation: SendInvitation$3,
  Update: Update$8,
  Delete: Delete$8
};

function response_encode$40(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$40(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$39(hubId) {
  return {
          kyInstance: kyInstance$8,
          path: hubId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "HubGetInvitedUsersList",
            hubId
          ]
        };
}

var List$7 = {
  response_encode: response_encode$40,
  response_decode: response_decode$40,
  config: config$39
};

var Invitation$4 = {
  List: List$7
};

var Hubs = {
  kyInstance: kyInstance$8,
  Invitations: Invitations$4,
  Human: Human$4,
  Invitation: Invitation$4
};

var Users = {
  kyInstance: kyInstance$1,
  Admin: Admin,
  IsAvailable: IsAvailable,
  GetByUsername: GetByUsername,
  Update: Update,
  Delete: Delete,
  Human: Human,
  Clients: Clients,
  Stores: Stores,
  Transporters: Transporters,
  Hubs: Hubs
};

var kyInstance$9 = kyInstance.extend({
      prefixUrl: prefixPublicBaseUrl + "/users"
    });

var kyInstance$10 = kyInstance$9.extend({
      prefixUrl: prefixPublicBaseUrl + "/users/human"
    });

function argument_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Spice.stringToJson(v.uuid)
                  ],
                  [
                    "token",
                    false,
                    Spice.stringToJson(v.token)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ]
                ]));
}

function argument_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
    if (token.TAG === "Ok") {
      var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
      if (password.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  uuid: uuid._0,
                  token: token._0,
                  password: password._0
                }
              };
      }
      var e = password._0;
      return {
              TAG: "Error",
              _0: {
                path: ".password" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = token._0;
    return {
            TAG: "Error",
            _0: {
              path: ".token" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$41 = Spice.unitToJson;

var response_decode$41 = Spice.unitFromJson;

function error_encode$25(v) {
  return "SETUP_TOKEN_EXPIRED";
}

function error_decode$25(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("SETUP_TOKEN_EXPIRED" === v) {
          return {
                  TAG: "Ok",
                  _0: "SETUP_TOKEN_EXPIRED"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$40(body) {
  return {
          kyInstance: kyInstance$10,
          path: "setupPassword",
          requestOptions: {
            method: "POST",
            json: argument_encode$2(body)
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$25, param);
            })
        };
}

var SetupPassword = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  response_encode: response_encode$41,
  response_decode: response_decode$41,
  error_encode: error_encode$25,
  error_decode: error_decode$25,
  config: config$40
};

function argument_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "uuid",
                    false,
                    Spice.stringToJson(v.uuid)
                  ],
                  [
                    "token",
                    false,
                    Spice.stringToJson(v.token)
                  ]
                ]));
}

function argument_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var uuid = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
  if (uuid.TAG === "Ok") {
    var token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
    if (token.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                uuid: uuid._0,
                token: token._0
              }
            };
    }
    var e = token._0;
    return {
            TAG: "Error",
            _0: {
              path: ".token" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = uuid._0;
  return {
          TAG: "Error",
          _0: {
            path: ".uuid" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$42(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

function response_decode$42(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              email: email._0
            }
          };
  }
  var e = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function error_encode$26(v) {
  return "SETUP_TOKEN_EXPIRED";
}

function error_decode$26(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("SETUP_TOKEN_EXPIRED" === v) {
          return {
                  TAG: "Ok",
                  _0: "SETUP_TOKEN_EXPIRED"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

function config$41(params) {
  return {
          kyInstance: kyInstance$10,
          path: "verifySetupToken",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$3(params))
          },
          key: [
            "verifySetupToken",
            params.token
          ]
        };
}

var VerifySetupPasswordToken = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  response_encode: response_encode$42,
  response_decode: response_decode$42,
  error_encode: error_encode$26,
  error_decode: error_decode$26,
  config: config$41
};

function argument_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

function argument_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              email: email._0
            }
          };
  }
  var e = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$43 = Spice.unitToJson;

var response_decode$43 = Spice.unitFromJson;

function config$42(body) {
  return {
          kyInstance: kyInstance$10,
          path: "askResetPassword",
          requestOptions: {
            method: "POST",
            json: argument_encode$4(body)
          }
        };
}

var AskResetPassword = {
  argument_encode: argument_encode$4,
  argument_decode: argument_decode$4,
  response_encode: response_encode$43,
  response_decode: response_decode$43,
  config: config$42
};

function argument_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "token",
                    false,
                    Spice.stringToJson(v.token)
                  ]]));
}

function argument_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
  if (token.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              token: token._0
            }
          };
  }
  var e = token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".token" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function response_encode$44(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ]]));
}

function response_decode$44(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              email: email._0
            }
          };
  }
  var e = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function error_encode$27(v) {
  return ["InvalidToken"];
}

function error_decode$27(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string" && match === "InvalidToken") {
    if (v.length !== 1) {
      return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
    } else {
      return {
              TAG: "Ok",
              _0: "INVALID_TOKEN"
            };
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function config$43(params) {
  return {
          kyInstance: kyInstance$10,
          path: "verifyResetPasswordToken",
          requestOptions: {
            method: "GET",
            searchParams: Qs.stringify(argument_encode$5(params))
          },
          key: [
            "verifyResetPasswordToken",
            params.token
          ]
        };
}

var VerifyResetPasswordToken = {
  argument_encode: argument_encode$5,
  argument_decode: argument_decode$5,
  response_encode: response_encode$44,
  response_decode: response_decode$44,
  error_encode: error_encode$27,
  error_decode: error_decode$27,
  config: config$43
};

function argument_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "token",
                    false,
                    Spice.stringToJson(v.token)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ]
                ]));
}

function argument_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var token = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "token"), null));
  if (token.TAG === "Ok") {
    var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                token: token._0,
                password: password._0
              }
            };
    }
    var e = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = token._0;
  return {
          TAG: "Error",
          _0: {
            path: ".token" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$45 = Spice.unitToJson;

var response_decode$45 = Spice.unitFromJson;

function error_encode$28(v) {
  return ["RESET_TOKEN_EXPIRED"];
}

function error_decode$28(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string" && match === "RESET_TOKEN_EXPIRED") {
    if (v.length !== 1) {
      return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
    } else {
      return {
              TAG: "Ok",
              _0: "RESET_TOKEN_EXPIRED"
            };
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function config$44(body) {
  return {
          kyInstance: kyInstance$10,
          path: "resetPassword",
          requestOptions: {
            method: "POST",
            json: argument_encode$6(body)
          }
        };
}

var ResetPassword = {
  argument_encode: argument_encode$6,
  argument_decode: argument_decode$6,
  response_encode: response_encode$45,
  response_decode: response_decode$45,
  error_encode: error_encode$28,
  error_decode: error_decode$28,
  config: config$44
};

var Human$5 = {
  kyInstance: kyInstance$10,
  SetupPassword: SetupPassword,
  VerifySetupPasswordToken: VerifySetupPasswordToken,
  AskResetPassword: AskResetPassword,
  VerifyResetPasswordToken: VerifyResetPasswordToken,
  ResetPassword: ResetPassword
};

var Users$1 = {
  kyInstance: kyInstance$9,
  Human: Human$5
};

var kyInstance$11 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/users/clients"
    });

function response_encode$46(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$46(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$45(clientId) {
  return {
          kyInstance: kyInstance$11,
          path: clientId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            clientId
          ]
        };
}

var List$8 = {
  response_encode: response_encode$46,
  response_decode: response_decode$46,
  config: config$45
};

function body_encode$14(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$47 = response_encode;

var response_decode$47 = response_decode;

function error_encode$29(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$29 = error_decode;

function config$46(param) {
  return {
          kyInstance: kyInstance$11,
          path: param[0] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$14(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$29, param);
            })
        };
}

var SendInvitation$4 = {
  body_encode: body_encode$14,
  body_decode: body_decode$14,
  response_encode: response_encode$47,
  response_decode: response_decode$47,
  error_encode: error_encode$29,
  error_decode: error_decode$29,
  config: config$46
};

function body_encode$15(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$48 = Spice.unitToJson;

var response_decode$48 = Spice.unitFromJson;

function error_encode$30(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$30 = userUpdateError_decode;

function config$47(param) {
  return {
          kyInstance: kyInstance$11,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$15(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$30, param);
            })
        };
}

var Update$9 = {
  body_encode: body_encode$15,
  body_decode: body_decode$15,
  response_encode: response_encode$48,
  response_decode: response_decode$48,
  error_encode: error_encode$30,
  error_decode: error_decode$30,
  config: config$47
};

var response_encode$49 = Spice.unitToJson;

var response_decode$49 = Spice.unitFromJson;

var error_encode$31 = Spice.unitToJson;

var error_decode$31 = Spice.unitFromJson;

function config$48(param) {
  return {
          kyInstance: kyInstance$11,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$9 = {
  response_encode: response_encode$49,
  response_decode: response_decode$49,
  error_encode: error_encode$31,
  error_decode: error_decode$31,
  config: config$48
};

var Human$6 = {
  List: List$8,
  SendInvitation: SendInvitation$4,
  Update: Update$9,
  Delete: Delete$9
};

function response_encode$50(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$50(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$49(clientId) {
  return {
          kyInstance: kyInstance$11,
          path: clientId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetInvitedUsersList",
            clientId
          ]
        };
}

var List$9 = {
  response_encode: response_encode$50,
  response_decode: response_decode$50,
  config: config$49
};

var Invitation$5 = {
  List: List$9
};

var Clients$1 = {
  kyInstance: kyInstance$11,
  Human: Human$6,
  Invitation: Invitation$5
};

var kyInstance$12 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/users/clients"
    });

function response_encode$51(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$51(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$50(param) {
  var storeId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$12,
          path: clientId + "/stores/" + storeId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            clientId,
            storeId
          ]
        };
}

var List$10 = {
  response_encode: response_encode$51,
  response_decode: response_decode$51,
  config: config$50
};

function body_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$52 = response_encode;

var response_decode$52 = response_decode;

function error_encode$32(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$32 = error_decode;

function config$51(param) {
  return {
          kyInstance: kyInstance$12,
          path: param[0] + "/stores/" + param[1] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$16(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$32, param);
            })
        };
}

var SendInvitation$5 = {
  body_encode: body_encode$16,
  body_decode: body_decode$16,
  response_encode: response_encode$52,
  response_decode: response_decode$52,
  error_encode: error_encode$32,
  error_decode: error_decode$32,
  config: config$51
};

function body_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$53 = Spice.unitToJson;

var response_decode$53 = Spice.unitFromJson;

function error_encode$33(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$33 = userUpdateError_decode;

function config$52(param) {
  return {
          kyInstance: kyInstance$12,
          path: param[0] + "/stores/" + param[1] + "/human/" + param[2],
          requestOptions: {
            method: "PUT",
            json: body_encode$17(param[3])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$33, param);
            })
        };
}

var Update$10 = {
  body_encode: body_encode$17,
  body_decode: body_decode$17,
  response_encode: response_encode$53,
  response_decode: response_decode$53,
  error_encode: error_encode$33,
  error_decode: error_decode$33,
  config: config$52
};

var response_encode$54 = Spice.unitToJson;

var response_decode$54 = Spice.unitFromJson;

var error_encode$34 = Spice.unitToJson;

var error_decode$34 = Spice.unitFromJson;

function config$53(param) {
  return {
          kyInstance: kyInstance$12,
          path: param[0] + "/stores/" + param[1] + "/human/" + param[2],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$10 = {
  response_encode: response_encode$54,
  response_decode: response_decode$54,
  error_encode: error_encode$34,
  error_decode: error_decode$34,
  config: config$53
};

var Human$7 = {
  List: List$10,
  SendInvitation: SendInvitation$5,
  Update: Update$10,
  Delete: Delete$10
};

function response_encode$55(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$55(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$54(param) {
  var storeId = param[1];
  var clientId = param[0];
  return {
          kyInstance: kyInstance$12,
          path: clientId + "/stores/" + storeId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetInvitedUsersList",
            clientId,
            storeId
          ]
        };
}

var List$11 = {
  response_encode: response_encode$55,
  response_decode: response_decode$55,
  config: config$54
};

var Invitation$6 = {
  List: List$11
};

var Stores$1 = {
  kyInstance: kyInstance$12,
  Human: Human$7,
  Invitation: Invitation$6
};

var kyInstance$13 = kyInstance.extend({
      prefixUrl: prefixBaseUrl + "/users/transporters"
    });

function response_encode$56(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "users",
                    false,
                    Spice.arrayToJson(user_encode, v.users)
                  ]]));
}

function response_decode$56(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var users = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "users"), null));
  if (users.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              users: users._0
            }
          };
  }
  var e = users._0;
  return {
          TAG: "Error",
          _0: {
            path: ".users" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$55(transporterId) {
  return {
          kyInstance: kyInstance$13,
          path: transporterId + "/human/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "ClientGetHumanUsersList",
            transporterId
          ]
        };
}

var List$12 = {
  response_encode: response_encode$56,
  response_decode: response_decode$56,
  config: config$55
};

function body_encode$18(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "emails",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.emails)
                  ],
                  [
                    "organizationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organizationRole)
                  ]
                ]));
}

function body_decode$18(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var emails = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "emails"), null));
  if (emails.TAG === "Ok") {
    var organizationRole = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "organizationRole"), null));
    if (organizationRole.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                emails: emails._0,
                organizationRole: organizationRole._0
              }
            };
    }
    var e = organizationRole._0;
    return {
            TAG: "Error",
            _0: {
              path: ".organizationRole" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = emails._0;
  return {
          TAG: "Error",
          _0: {
            path: ".emails" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$57 = response_encode;

var response_decode$57 = response_decode;

function error_encode$35(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$35 = error_decode;

function config$56(param) {
  return {
          kyInstance: kyInstance$13,
          path: param[0] + "/human/invitation",
          requestOptions: {
            method: "POST",
            json: body_encode$18(param[1])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$35, param);
            })
        };
}

var SendInvitation$6 = {
  body_encode: body_encode$18,
  body_decode: body_decode$18,
  response_encode: response_encode$57,
  response_decode: response_decode$57,
  error_encode: error_encode$35,
  error_decode: error_decode$35,
  config: config$56
};

function body_encode$19(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "role",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.role)
                  ]]));
}

function body_decode$19(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var role = ColiswebApi__V6_Utils.OrganizationRole.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
  if (role.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              role: role._0
            }
          };
  }
  var e = role._0;
  return {
          TAG: "Error",
          _0: {
            path: ".role" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$58 = Spice.unitToJson;

var response_decode$58 = Spice.unitFromJson;

function error_encode$36(v) {
  return "MAX_NUMBER_OF_ADMINISTRATOR";
}

var error_decode$36 = userUpdateError_decode;

function config$57(param) {
  return {
          kyInstance: kyInstance$13,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "PUT",
            json: body_encode$19(param[2])
          },
          mapCustomErrors: (function (param) {
              return ColiswebApi__Utils.handleError(error_decode$36, param);
            })
        };
}

var Update$11 = {
  body_encode: body_encode$19,
  body_decode: body_decode$19,
  response_encode: response_encode$58,
  response_decode: response_decode$58,
  error_encode: error_encode$36,
  error_decode: error_decode$36,
  config: config$57
};

var response_encode$59 = Spice.unitToJson;

var response_decode$59 = Spice.unitFromJson;

var error_encode$37 = Spice.unitToJson;

var error_decode$37 = Spice.unitFromJson;

function config$58(param) {
  return {
          kyInstance: kyInstance$13,
          path: param[0] + "/human/" + param[1],
          requestOptions: {
            method: "DELETE"
          }
        };
}

var Delete$11 = {
  response_encode: response_encode$59,
  response_decode: response_decode$59,
  error_encode: error_encode$37,
  error_decode: error_decode$37,
  config: config$58
};

var Human$8 = {
  List: List$12,
  SendInvitation: SendInvitation$6,
  Update: Update$11,
  Delete: Delete$11
};

function response_encode$60(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "invitations",
                    false,
                    Spice.arrayToJson(user_encode, v.invitations)
                  ]]));
}

function response_decode$60(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var invitations = Spice.arrayFromJson(user_decode, Belt_Option.getWithDefault(Js_dict.get(v, "invitations"), null));
  if (invitations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              invitations: invitations._0
            }
          };
  }
  var e = invitations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".invitations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$59(transporterId) {
  return {
          kyInstance: kyInstance$13,
          path: transporterId + "/invitation/list",
          requestOptions: {
            method: "GET"
          },
          key: [
            "TransporterGetInvitedUsersList",
            transporterId
          ]
        };
}

var List$13 = {
  response_encode: response_encode$60,
  response_decode: response_decode$60,
  config: config$59
};

var Invitation$7 = {
  List: List$13
};

var Transporters$1 = {
  kyInstance: kyInstance$13,
  Human: Human$8,
  Invitation: Invitation$7
};

var Public = {
  Users: Users$1,
  Clients: Clients$1,
  Stores: Stores$1,
  Transporters: Transporters$1
};

export {
  Shared ,
  Users ,
  Public ,
}
/* kyInstance Not a pure module */
