

import * as Cx from "../../../../node_modules/rescript-classnames/lib/es6_global/src/Cx.bs.js";
import * as Swr from "swr";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as React from "react";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as DateFns from "date-fns";
import * as ReactIntl from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as ReactTable from "react-table";
import * as Toolkit__Form from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/form/Toolkit__Form.bs.js";
import * as ReSchemaRegExp from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/form/ReSchemaRegExp.bs.js";
import * as Toolkit__Hooks from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/hooks/Toolkit__Hooks.bs.js";
import * as Fa from "react-icons/fa";
import * as Md from "react-icons/md";
import * as Toolkit__Ui_Tag from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Tag.bs.js";
import * as Toolkit__Ui_Alert from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Alert.bs.js";
import * as Toolkit__Ui_Label from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Label.bs.js";
import * as Toolkit__Ui_Modal from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Modal.bs.js";
import * as Toolkit__Ui_Table from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Table.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as Toolkit__Ui_Select from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Select.bs.js";
import * as Toolkit__Ui_Tooltip from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Tooltip.bs.js";
import * as Toolkit__Ui_Snackbar from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Snackbar.bs.js";
import * as ColiswebApi__V6_Utils from "../v6/ColiswebApi__V6_Utils.bs.js";
import * as ColiswebApi__V6_Account from "../v6/ColiswebApi__V6_Account.bs.js";
import * as ColiswebApi__V6_Authent from "../v6/ColiswebApi__V6_Authent.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";

function refreshList(swrConfig, entity) {
  switch (entity.TAG) {
    case "Client" :
        var clientId = entity._0;
        var $$let = ColiswebApi__V6_Account.Users.Clients.Invitation.List;
        $$Request.mutate(swrConfig, {
              response_decode: $$let.response_decode,
              config: $$let.config
            }, clientId);
        var $$let$1 = ColiswebApi__V6_Account.Users.Clients.Human.List;
        return $$Request.mutate(swrConfig, {
                    response_decode: $$let$1.response_decode,
                    config: $$let$1.config
                  }, clientId);
    case "Store" :
        var storeId = entity._1;
        var clientId$1 = entity._0;
        var $$let$2 = ColiswebApi__V6_Account.Users.Stores.Invitation.List;
        $$Request.mutate(swrConfig, {
              response_decode: $$let$2.response_decode,
              config: $$let$2.config
            }, [
              clientId$1,
              storeId
            ]);
        var $$let$3 = ColiswebApi__V6_Account.Users.Stores.Human.List;
        return $$Request.mutate(swrConfig, {
                    response_decode: $$let$3.response_decode,
                    config: $$let$3.config
                  }, [
                    clientId$1,
                    storeId
                  ]);
    case "Transporter" :
        var transporterId = entity._0;
        var $$let$4 = ColiswebApi__V6_Account.Users.Transporters.Human.List;
        $$Request.mutate(swrConfig, {
              response_decode: $$let$4.response_decode,
              config: $$let$4.config
            }, transporterId);
        var $$let$5 = ColiswebApi__V6_Account.Users.Transporters.Invitation.List;
        return $$Request.mutate(swrConfig, {
                    response_decode: $$let$5.response_decode,
                    config: $$let$5.config
                  }, transporterId);
    case "Hub" :
        var hubId = entity._0;
        var $$let$6 = ColiswebApi__V6_Account.Users.Hubs.Human.List;
        $$Request.mutate(swrConfig, {
              response_decode: $$let$6.response_decode,
              config: $$let$6.config
            }, hubId);
        var $$let$7 = ColiswebApi__V6_Account.Users.Hubs.Invitation.List;
        return $$Request.mutate(swrConfig, {
                    response_decode: $$let$7.response_decode,
                    config: $$let$7.config
                  }, hubId);
    
  }
}

function ApiComponents__UsersList$UserRoleEdition(props) {
  var onSuccess = props.onSuccess;
  var onCancel = props.onCancel;
  var entity = props.entity;
  var user = props.user;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function (param) {
        return user.organizationRole;
      });
  var setRole = match[1];
  var role = match[0];
  var $$let = ColiswebApi__V6_Account.Users.Human.OwnerPromote;
  var match$1 = $$Request.useManualRequest({
        response_decode: $$let.response_decode,
        config: $$let.config
      });
  var promoteToOwner = match$1[1];
  var match$2 = React.useState(function (param) {
        return "NotAsked";
      });
  var setUpdateRequest = match$2[1];
  var ownerDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var swrConfig = Swr.useSWRConfig();
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                      options: Curry._2(Toolkit__Primitives_Array.$$Array.map, Curry._2(Toolkit__Primitives_Array.$$Array.concat, [
                                "user",
                                "admin"
                              ], props.isColisweber && Belt_Option.isNone(user.expiresAt) ? ["owner"] : []), (function (v) {
                              return {
                                      label: ColiswebApi__V6_Utils.OrganizationRole.humanize(v, intl),
                                      value: v
                                    };
                            })),
                      onChange: (function (v) {
                          Curry._1(setRole, (function (param) {
                                  return v;
                                }));
                        }),
                      value: role
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                      onClick: (function (param) {
                          Curry._1(onCancel, undefined);
                        }),
                      className: "!px-2",
                      children: Caml_option.some(JsxRuntime.jsx(Md.MdClose, {
                                size: 20
                              }))
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                      color: "primary",
                      isLoading: match$2[0] === "Loading",
                      onClick: (function (param) {
                          if (role === "owner") {
                            return Curry._1(ownerDisclosure.show, undefined);
                          }
                          Curry._1(setUpdateRequest, (function (param) {
                                  return "Loading";
                                }));
                          var isInvitedUser = Belt_Option.isSome(user.expiresAt);
                          var request;
                          switch (entity.TAG) {
                            case "Client" :
                                var clientId = entity._0;
                                if (isInvitedUser) {
                                  var $$let = ColiswebApi__V6_Account.Users.Clients.Invitations.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let.response_decode,
                                        config: $$let.config
                                      }, [
                                        clientId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                } else {
                                  var $$let$1 = ColiswebApi__V6_Account.Users.Clients.Human.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$1.response_decode,
                                        config: $$let$1.config
                                      }, [
                                        clientId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                }
                                break;
                            case "Store" :
                                var storeId = entity._1;
                                var clientId$1 = entity._0;
                                if (isInvitedUser) {
                                  var $$let$2 = ColiswebApi__V6_Account.Users.Stores.Invitations.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$2.response_decode,
                                        config: $$let$2.config
                                      }, [
                                        clientId$1,
                                        storeId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                } else {
                                  var $$let$3 = ColiswebApi__V6_Account.Users.Stores.Human.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$3.response_decode,
                                        config: $$let$3.config
                                      }, [
                                        clientId$1,
                                        storeId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                }
                                break;
                            case "Transporter" :
                                var transporterId = entity._0;
                                if (isInvitedUser) {
                                  var $$let$4 = ColiswebApi__V6_Account.Users.Transporters.Invitations.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$4.response_decode,
                                        config: $$let$4.config
                                      }, [
                                        transporterId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                } else {
                                  var $$let$5 = ColiswebApi__V6_Account.Users.Transporters.Human.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$5.response_decode,
                                        config: $$let$5.config
                                      }, [
                                        transporterId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                }
                                break;
                            case "Hub" :
                                var hubId = entity._0;
                                if (isInvitedUser) {
                                  var $$let$6 = ColiswebApi__V6_Account.Users.Hubs.Invitations.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$6.response_decode,
                                        config: $$let$6.config
                                      }, [
                                        hubId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                } else {
                                  var $$let$7 = ColiswebApi__V6_Account.Users.Hubs.Human.Update;
                                  request = $$Request.fetchAPI({
                                        response_decode: $$let$7.response_decode,
                                        config: $$let$7.config
                                      }, [
                                        hubId,
                                        user.uuid,
                                        {
                                          role: role
                                        }
                                      ]);
                                }
                                break;
                            
                          }
                          $$Promise.tapError($$Promise.tapOk(request, (function (param) {
                                      Curry._1(setUpdateRequest, (function (param) {
                                              return {
                                                      TAG: "Done",
                                                      _0: {
                                                        TAG: "Ok",
                                                        _0: undefined
                                                      }
                                                    };
                                            }));
                                      Toolkit__Ui_Snackbar.success(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                id: "_88bb9240",
                                                defaultMessage: "Rôle du membre mis à jour"
                                              }));
                                      refreshList(swrConfig, entity);
                                      Curry._1(onSuccess, undefined);
                                    })), (function (err) {
                                  var msg;
                                  var exit = 0;
                                  switch (err.TAG) {
                                    case "DecodeError" :
                                    case "Unknown" :
                                        exit = 1;
                                        break;
                                    case "Custom" :
                                        msg = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_83dfd9fa",
                                              defaultMessage: "Échec ! Vous êtes limité à 5 administrateurs dans votre organisation."
                                            });
                                        break;
                                    
                                  }
                                  if (exit === 1) {
                                    msg = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_eeb242b8",
                                          defaultMessage: "Une erreur est survenue"
                                        });
                                  }
                                  Toolkit__Ui_Snackbar.error(msg);
                                  Curry._1(setUpdateRequest, (function (param) {
                                          return {
                                                  TAG: "Done",
                                                  _0: {
                                                    TAG: "Error",
                                                    _0: undefined
                                                  }
                                                };
                                        }));
                                }));
                        }),
                      disabled: role === user.organizationRole,
                      className: "!px-2",
                      children: Caml_option.some(JsxRuntime.jsx(Md.MdCheck, {
                                size: 20
                              }))
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
                      isVisible: ownerDisclosure.isOpen,
                      title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_fa003627",
                                defaultMessage: "Changement de propriétaire !"
                              })),
                      body: JsxRuntime.jsxs("section", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_e1d44f48",
                                          defaultMessage: "Vous êtes sur le point de transférer la propriété de l’organisation. "
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_96c6bb1f",
                                          defaultMessage: "Le compte de l’actuel propriétaire sera <b>supprimé définitivement</b>.",
                                          values: {
                                            b: (function (c) {
                                                return JsxRuntime.jsx("strong", {
                                                            children: c
                                                          });
                                              })
                                          }
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_9ecf85e2",
                                          defaultMessage: "<s>name</s> deviendra le nouveau propriétaire.",
                                          values: {
                                            s: (function (param) {
                                                return JsxRuntime.jsx("span", {
                                                            children: user.email,
                                                            className: "bg-neutral-300 font-semibold text-neutral-800 rounded px-1 py-px"
                                                          });
                                              })
                                          }
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_8ae1f254",
                                          defaultMessage: "Cette action est <b>irréversible</b>. Êtes-vous sûr de vouloir continuer ?",
                                          values: {
                                            b: (function (c) {
                                                return JsxRuntime.jsx("strong", {
                                                            children: c
                                                          });
                                              })
                                          }
                                        })
                                  }),
                              JsxRuntime.jsxs("section", {
                                    children: [
                                      JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            onClick: (function (param) {
                                                Curry._1(ownerDisclosure.hide, undefined);
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_d12c3b0d",
                                                      defaultMessage: "Annuler"
                                                    }))
                                          }),
                                      JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            color: "danger",
                                            isLoading: match$1[0] === "Loading",
                                            onClick: (function (param) {
                                                $$Promise.tapError($$Promise.tapOk(Curry._1(promoteToOwner, user.uuid), (function (param) {
                                                            Curry._1(ownerDisclosure.hide, undefined);
                                                            Toolkit__Ui_Snackbar.success(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                      id: "_489384f7",
                                                                      defaultMessage: "Propriétaire modifié !"
                                                                    }));
                                                            refreshList(swrConfig, entity);
                                                            Curry._1(onSuccess, undefined);
                                                          })), (function (param) {
                                                        Toolkit__Ui_Snackbar.error(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "_eeb242b8",
                                                                  defaultMessage: "Une erreur est survenue"
                                                                }));
                                                        Curry._1(setUpdateRequest, (function (param) {
                                                                return {
                                                                        TAG: "Done",
                                                                        _0: {
                                                                          TAG: "Error",
                                                                          _0: undefined
                                                                        }
                                                                      };
                                                              }));
                                                      }));
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_303a7409",
                                                      defaultMessage: "Confirmer"
                                                    }))
                                          })
                                    ],
                                    className: "mt-4 ml-auto flex flex-row gap-4 items-center"
                                  })
                            ],
                            className: "flex flex-col gap-3"
                          }),
                      hide: ownerDisclosure.hide,
                      type_: {
                        NAME: "colored",
                        VAL: "danger"
                      }
                    })
              ],
              className: "flex flex-row gap-1 items-center"
            });
}

function get(values, field) {
  if (field === "Email") {
    return values.email;
  } else {
    return values.role;
  }
}

function set(values, field, value) {
  if (field === "Email") {
    return {
            email: value,
            role: values.role
          };
  } else {
    return {
            email: values.email,
            role: value
          };
  }
}

var Form = Toolkit__Form.Make({
      set: set,
      get: get
    });

function ApiComponents__UsersList$InvitationModal(props) {
  var entity = props.entity;
  var disclosure = props.disclosure;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function (param) {
        return [];
      });
  var setAlreadyExistingEmails = match[1];
  var alreadyExistingEmails = match[0];
  var swrConfig = Swr.useSWRConfig();
  var form = Curry._7(Form.use, {
        email: "",
        role: "user"
      }, Curry._1(Form.ReSchema.Validation.schema, [
            Curry._3(Form.ReSchema.Validation.nonEmpty, undefined, undefined, "Email"),
            Curry._2(Form.ReSchema.Validation.custom, "Email", (function (param) {
                    if (ReSchemaRegExp.email.test(param.email)) {
                      return "Valid";
                    } else {
                      return {
                              TAG: "Error",
                              _0: intl.formatMessage({
                                    id: "_a41862e1",
                                    defaultMessage: "Le format d'e-mail est incorrect"
                                  })
                            };
                    }
                  }))
          ]), (function (param) {
          var match = param.state.values;
          var role = match.role;
          var email = match.email;
          var send = param.send;
          var request;
          switch (entity.TAG) {
            case "Client" :
                var $$let = ColiswebApi__V6_Account.Users.Clients.Human.SendInvitation;
                request = $$Request.fetchAPI({
                      response_decode: $$let.response_decode,
                      config: $$let.config
                    }, [
                      entity._0,
                      {
                        emails: [email],
                        organizationRole: role
                      }
                    ]);
                break;
            case "Store" :
                var $$let$1 = ColiswebApi__V6_Account.Users.Stores.Human.SendInvitation;
                request = $$Request.fetchAPI({
                      response_decode: $$let$1.response_decode,
                      config: $$let$1.config
                    }, [
                      entity._0,
                      entity._1,
                      {
                        emails: [email],
                        organizationRole: role
                      }
                    ]);
                break;
            case "Transporter" :
                var $$let$2 = ColiswebApi__V6_Account.Users.Transporters.Human.SendInvitation;
                request = $$Request.fetchAPI({
                      response_decode: $$let$2.response_decode,
                      config: $$let$2.config
                    }, [
                      entity._0,
                      {
                        emails: [email],
                        organizationRole: role
                      }
                    ]);
                break;
            case "Hub" :
                var $$let$3 = ColiswebApi__V6_Account.Users.Hubs.Human.SendInvitation;
                request = $$Request.fetchAPI({
                      response_decode: $$let$3.response_decode,
                      config: $$let$3.config
                    }, [
                      entity._0,
                      {
                        emails: [email],
                        organizationRole: role
                      }
                    ]);
                break;
            
          }
          return $$Promise.tapOk(request, (function (param) {
                        var alreadyExistingEmails = param.alreadyExistingEmails;
                        if (alreadyExistingEmails.length !== 0) {
                          Curry._1(send, "ResetForm");
                          Curry._1(setAlreadyExistingEmails, (function (param) {
                                  return alreadyExistingEmails;
                                }));
                        } else {
                          Curry._1(disclosure.hide, undefined);
                          Curry._1(send, "ResetForm");
                          Toolkit__Ui_Snackbar.success(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_a2836d07",
                                    defaultMessage: "Invitation envoyée"
                                  }));
                        }
                        refreshList(swrConfig, entity);
                      }));
        }), undefined, undefined, undefined, undefined);
  React.useEffect((function (param) {
          if (!disclosure.isOpen) {
            Curry._1(form.resetForm, undefined);
            Curry._1(setAlreadyExistingEmails, (function (param) {
                    return [];
                  }));
          }
          
        }), [disclosure.isOpen]);
  var err = form.state.formState;
  var tmp;
  if (typeof err !== "object") {
    tmp = null;
  } else {
    var err$1 = err._0;
    var tmp$1;
    var exit = 0;
    if (err$1 !== undefined) {
      switch (err$1.TAG) {
        case "DecodeError" :
        case "Unknown" :
            exit = 1;
            break;
        case "Custom" :
            tmp$1 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                  id: "_30d1931b",
                  defaultMessage: "Échec ! Vous êtes limité à 5 administrateurs dans votre organisation. Il vous reste {count} rôles d'administrateur disponibles.",
                  values: {
                    count: props.adminSeatAvailable
                  }
                });
            break;
        
      }
    } else {
      exit = 1;
    }
    if (exit === 1) {
      tmp$1 = JsxRuntime.jsx(ReactIntl.FormattedMessage, {
            id: "_eeb242b8",
            defaultMessage: "Une erreur est survenue"
          });
    }
    tmp = JsxRuntime.jsx(Toolkit__Ui_Alert.make, {
          title: tmp$1,
          status: "error"
        });
  }
  return JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
              isVisible: disclosure.isOpen,
              title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_00896fad",
                        defaultMessage: "Inviter un membre"
                      })),
              body: JsxRuntime.jsxs(Form.Provider.make, {
                    className: "flex flex-col gap-4",
                    children: [
                      JsxRuntime.jsxs("section", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsx(Form.Input.make, {
                                          field: "Email",
                                          label: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_ce8ae9da",
                                                    defaultMessage: "Email"
                                                  })),
                                          id: "email",
                                          type_: "text"
                                        }),
                                    className: "w-2/3"
                                  }),
                              JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsx(Toolkit__Ui_Label.make, {
                                            htmlFor: "role",
                                            children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                  id: "_bbbabdbe",
                                                  defaultMessage: "Role"
                                                })
                                          }),
                                      JsxRuntime.jsx(Toolkit__Ui_Select.make, {
                                            options: Curry._2(Toolkit__Primitives_Array.$$Array.map, [
                                                  "user",
                                                  "admin"
                                                ], (function (v) {
                                                    return {
                                                            label: ColiswebApi__V6_Utils.OrganizationRole.humanize(v, intl),
                                                            value: v
                                                          };
                                                  })),
                                            onChange: (function (v) {
                                                Curry._4(form.setFieldValue, "Role", v, undefined, undefined);
                                              }),
                                            id: "role",
                                            value: form.state.values.role
                                          })
                                    ],
                                    className: "flex flex-col"
                                  })
                            ],
                            className: "flex flex-row justify-between items-end gap-4"
                          }),
                      alreadyExistingEmails.length !== 0 ? JsxRuntime.jsx(Toolkit__Ui_Alert.make, {
                              title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                    id: "_3296d398",
                                    defaultMessage: "Une invitation a déjà été envoyée pour le membre suivant : "
                                  }),
                              description: Caml_option.some(JsxRuntime.jsxs("div", {
                                        children: [
                                          JsxRuntime.jsx("ul", {
                                                children: Curry._2(Toolkit__Primitives_Array.$$Array.map, alreadyExistingEmails, (function (e) {
                                                        return JsxRuntime.jsx("li", {
                                                                    children: e
                                                                  }, e);
                                                      }))
                                              }),
                                          JsxRuntime.jsx("p", {
                                                children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_6378a0d6",
                                                      defaultMessage: "(L'adresse e-mail existe déjà)"
                                                    })
                                              })
                                        ]
                                      })),
                              status: "warning"
                            }) : null,
                      tmp,
                      JsxRuntime.jsxs("section", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_0ea52ac6",
                                          defaultMessage: "L'invité recevra un email lui permettant de configurer son mot de passe sous 7 jours."
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_c2c07689",
                                          defaultMessage: "Passé ce délai, l'invitation expirera et une nouvelle devra être envoyée."
                                        })
                                  })
                            ],
                            className: "my-3 text-neutral-800"
                          }),
                      JsxRuntime.jsx("section", {
                            children: JsxRuntime.jsxs("div", {
                                  children: [
                                    JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                          onClick: (function (param) {
                                              Curry._1(disclosure.hide, undefined);
                                            }),
                                          type_: "button",
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_d12c3b0d",
                                                    defaultMessage: "Annuler"
                                                  }))
                                        }),
                                    JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                          color: "primary",
                                          disabled: form.state.values.email === "",
                                          children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_770a1a1e",
                                                    defaultMessage: "Envoyer"
                                                  })),
                                          leftIcon: {
                                            make: Md.MdSend
                                          }
                                        })
                                  ],
                                  className: "flex justify-end gap-4"
                                })
                          })
                    ],
                    form: form
                  }),
              hide: disclosure.hide
            });
}

function ApiComponents__UsersList$RenewInvitationButton(props) {
  var entity = props.entity;
  var email = props.email;
  var userUuid = props.userUuid;
  var match = React.useState(function (param) {
        return "NotAsked";
      });
  var setRequest = match[1];
  var disclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var swrConfig = Swr.useSWRConfig();
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Toolkit__Ui_Tooltip.make, {
                      triggerContent: JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                  size: "sm",
                                  onClick: (function (param) {
                                      Curry._1(disclosure.show, undefined);
                                    }),
                                  children: Caml_option.some(null),
                                  leftIcon: {
                                    make: Fa.FaRedo
                                  }
                                })
                          }),
                      tooltipContent: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                            id: "_fda5950f",
                            defaultMessage: "Relancer l'invitation"
                          }),
                      color: "Black",
                      triggerAsChild: true
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
                      isVisible: disclosure.isOpen,
                      title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                id: "_54d9177e",
                                defaultMessage: "Renvoyer l'invitation"
                              })),
                      body: JsxRuntime.jsxs("section", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_23e59b63",
                                          defaultMessage: "L'invitation de <s>email</s> a expiré",
                                          values: {
                                            s: (function (param) {
                                                return JsxRuntime.jsx("span", {
                                                            children: email,
                                                            className: "bg-neutral-300 font-semibold text-neutral-800 rounded px-1 py-px"
                                                          });
                                              })
                                          }
                                        })
                                  }),
                              JsxRuntime.jsx("strong", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_9e013bd1",
                                          defaultMessage: "Vérifiez que l’adresse e-mail est correcte et invitez le membre à consulter ses spams."
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_b4a07dbc",
                                          defaultMessage: "Souhaitez-vous réinviter <s>email</s> ?",
                                          values: {
                                            s: (function (param) {
                                                return JsxRuntime.jsx("span", {
                                                            children: email,
                                                            className: "bg-neutral-300 font-semibold text-neutral-800 rounded px-1 py-px"
                                                          });
                                              })
                                          }
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_d54e352e",
                                          defaultMessage: "En cliquant sur \"Confirmer\", un nouvel e-mail lui sera envoyé."
                                        })
                                  }),
                              JsxRuntime.jsxs("section", {
                                    children: [
                                      JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            onClick: (function (param) {
                                                Curry._1(disclosure.hide, undefined);
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_d12c3b0d",
                                                      defaultMessage: "Annuler"
                                                    }))
                                          }),
                                      JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            color: "primary",
                                            isLoading: match[0] === "Loading",
                                            onClick: (function (param) {
                                                Curry._1(setRequest, (function (param) {
                                                        return "Loading";
                                                      }));
                                                var request;
                                                switch (entity.TAG) {
                                                  case "Client" :
                                                      var $$let = ColiswebApi__V6_Account.Users.Clients.Invitations.Renew;
                                                      request = $$Request.fetchAPI({
                                                            response_decode: $$let.response_decode,
                                                            config: $$let.config
                                                          }, [
                                                            entity._0,
                                                            userUuid
                                                          ]);
                                                      break;
                                                  case "Store" :
                                                      var $$let$1 = ColiswebApi__V6_Account.Users.Stores.Invitations.Renew;
                                                      request = $$Request.fetchAPI({
                                                            response_decode: $$let$1.response_decode,
                                                            config: $$let$1.config
                                                          }, [
                                                            entity._0,
                                                            entity._1,
                                                            userUuid
                                                          ]);
                                                      break;
                                                  case "Transporter" :
                                                      var $$let$2 = ColiswebApi__V6_Account.Users.Transporters.Invitations.Renew;
                                                      request = $$Request.fetchAPI({
                                                            response_decode: $$let$2.response_decode,
                                                            config: $$let$2.config
                                                          }, [
                                                            entity._0,
                                                            userUuid
                                                          ]);
                                                      break;
                                                  case "Hub" :
                                                      var $$let$3 = ColiswebApi__V6_Account.Users.Hubs.Invitations.Renew;
                                                      request = $$Request.fetchAPI({
                                                            response_decode: $$let$3.response_decode,
                                                            config: $$let$3.config
                                                          }, [
                                                            entity._0,
                                                            userUuid
                                                          ]);
                                                      break;
                                                  
                                                }
                                                $$Promise.tapError($$Promise.tapOk(request, (function (param) {
                                                            Curry._1(setRequest, (function (param) {
                                                                    return {
                                                                            TAG: "Done",
                                                                            _0: {
                                                                              TAG: "Ok",
                                                                              _0: undefined
                                                                            }
                                                                          };
                                                                  }));
                                                            Curry._1(disclosure.hide, undefined);
                                                            refreshList(swrConfig, entity);
                                                            Toolkit__Ui_Snackbar.success(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                      id: "_d2ceb688",
                                                                      defaultMessage: "Invitation renvoyée !"
                                                                    }));
                                                          })), (function (param) {
                                                        Curry._1(setRequest, (function (param) {
                                                                return {
                                                                        TAG: "Done",
                                                                        _0: {
                                                                          TAG: "Error",
                                                                          _0: undefined
                                                                        }
                                                                      };
                                                              }));
                                                        Toolkit__Ui_Snackbar.error(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                                  id: "_eeb242b8",
                                                                  defaultMessage: "Une erreur est survenue"
                                                                }));
                                                      }));
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_303a7409",
                                                      defaultMessage: "Confirmer"
                                                    }))
                                          })
                                    ],
                                    className: "mt-4 ml-auto flex flex-row gap-4 items-center"
                                  })
                            ],
                            className: "flex flex-col gap-3"
                          }),
                      hide: disclosure.hide
                    })
              ]
            });
}

function ApiComponents__UsersList$UserDeletionModal(props) {
  var onSuccess = props.onSuccess;
  var onCancel = props.onCancel;
  var selectedUserToDelete = props.selectedUserToDelete;
  var entity = props.entity;
  var match = React.useState(function (param) {
        return "NotAsked";
      });
  var setDeletionRequest = match[1];
  var deletionRequest = match[0];
  var intl = ReactIntl.useIntl();
  var swrConfig = Swr.useSWRConfig();
  var __x = selectedUserToDelete.organizationRole;
  var tmp;
  tmp = typeof deletionRequest !== "object" || deletionRequest._0.TAG === "Ok" ? null : JsxRuntime.jsx(Toolkit__Ui_Alert.make, {
          title: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                id: "_eeb242b8",
                defaultMessage: "Une erreur est survenue"
              }),
          status: "error"
        });
  return JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
              isVisible: true,
              title: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                        id: "_076a9bc8",
                        defaultMessage: "Suppression d'un membre de votre organisation"
                      })),
              body: JsxRuntime.jsxs("section", {
                    children: [
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_1de14112",
                                          defaultMessage: "Vous êtes sur le point de supprimer le membre suivant : "
                                        })
                                  }),
                              JsxRuntime.jsxs("p", {
                                    children: [
                                      selectedUserToDelete.email,
                                      JsxRuntime.jsxs("span", {
                                            children: [
                                              "- ",
                                              ColiswebApi__V6_Utils.OrganizationRole.humanize(__x, intl)
                                            ],
                                            className: "pl-1"
                                          })
                                    ],
                                    className: "px-2 py-1 text-lg bg-neutral-200 inline-block font-medium"
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("strong", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_31623484",
                                          defaultMessage: "Cette action est irréversible."
                                        })
                                  }),
                              JsxRuntime.jsx("p", {
                                    children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                          id: "_45329fd1",
                                          defaultMessage: "Êtes-vous sûr(e) de vouloir continuer ?"
                                        })
                                  })
                            ]
                          }),
                      tmp,
                      JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                    onClick: (function (param) {
                                        Curry._1(onCancel, undefined);
                                      }),
                                    children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_d12c3b0d",
                                              defaultMessage: "Annuler"
                                            }))
                                  }),
                              JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                    color: "danger",
                                    isLoading: deletionRequest === "Loading",
                                    onClick: (function (param) {
                                        Curry._1(setDeletionRequest, (function (param) {
                                                return "Loading";
                                              }));
                                        var isInvitedUser = Belt_Option.isSome(selectedUserToDelete.expiresAt);
                                        var request;
                                        switch (entity.TAG) {
                                          case "Client" :
                                              var clientId = entity._0;
                                              if (isInvitedUser) {
                                                var $$let = ColiswebApi__V6_Account.Users.Clients.Invitations.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let.response_decode,
                                                      config: $$let.config
                                                    }, [
                                                      clientId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              } else {
                                                var $$let$1 = ColiswebApi__V6_Account.Users.Clients.Human.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$1.response_decode,
                                                      config: $$let$1.config
                                                    }, [
                                                      clientId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              }
                                              break;
                                          case "Store" :
                                              var storeId = entity._1;
                                              var clientId$1 = entity._0;
                                              if (isInvitedUser) {
                                                var $$let$2 = ColiswebApi__V6_Account.Users.Stores.Invitations.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$2.response_decode,
                                                      config: $$let$2.config
                                                    }, [
                                                      clientId$1,
                                                      storeId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              } else {
                                                var $$let$3 = ColiswebApi__V6_Account.Users.Stores.Human.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$3.response_decode,
                                                      config: $$let$3.config
                                                    }, [
                                                      clientId$1,
                                                      storeId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              }
                                              break;
                                          case "Transporter" :
                                              var transporterId = entity._0;
                                              if (isInvitedUser) {
                                                var $$let$4 = ColiswebApi__V6_Account.Users.Transporters.Invitations.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$4.response_decode,
                                                      config: $$let$4.config
                                                    }, [
                                                      transporterId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              } else {
                                                var $$let$5 = ColiswebApi__V6_Account.Users.Transporters.Human.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$5.response_decode,
                                                      config: $$let$5.config
                                                    }, [
                                                      transporterId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              }
                                              break;
                                          case "Hub" :
                                              var hubId = entity._0;
                                              if (isInvitedUser) {
                                                var $$let$6 = ColiswebApi__V6_Account.Users.Hubs.Invitations.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$6.response_decode,
                                                      config: $$let$6.config
                                                    }, [
                                                      hubId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              } else {
                                                var $$let$7 = ColiswebApi__V6_Account.Users.Hubs.Human.Delete;
                                                request = $$Request.fetchAPI({
                                                      response_decode: $$let$7.response_decode,
                                                      config: $$let$7.config
                                                    }, [
                                                      hubId,
                                                      selectedUserToDelete.uuid
                                                    ]);
                                              }
                                              break;
                                          
                                        }
                                        $$Promise.tapError($$Promise.tapOk(request, (function (param) {
                                                    Curry._1(setDeletionRequest, (function (param) {
                                                            return {
                                                                    TAG: "Done",
                                                                    _0: {
                                                                      TAG: "Ok",
                                                                      _0: undefined
                                                                    }
                                                                  };
                                                          }));
                                                    refreshList(swrConfig, entity);
                                                    Curry._1(onSuccess, undefined);
                                                  })), (function (err) {
                                                Curry._1(setDeletionRequest, (function (param) {
                                                        return {
                                                                TAG: "Done",
                                                                _0: {
                                                                  TAG: "Error",
                                                                  _0: err
                                                                }
                                                              };
                                                      }));
                                              }));
                                      }),
                                    children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                              id: "_ccfd9851",
                                              defaultMessage: "Supprimer"
                                            }))
                                  })
                            ],
                            className: "flex justify-end gap-4"
                          })
                    ],
                    className: "flex flex-col gap-4"
                  }),
              hide: (function (param) {
                  Curry._1(onCancel, undefined);
                }),
              size: "sm",
              type_: {
                NAME: "colored",
                VAL: "danger"
              }
            });
}

function ApiComponents__UsersList$List(props) {
  var entity = props.entity;
  var users = props.users;
  var intl = ReactIntl.useIntl();
  var match = React.useState(function (param) {
        
      });
  var setEditedUser = match[1];
  var editedUser = match[0];
  var match$1 = React.useState(function (param) {
        
      });
  var setUserToDelete = match$1[1];
  var invitationModalDisclosure = Toolkit__Hooks.useDisclosure(undefined, undefined);
  var match$2 = $$Request.useFetcher(undefined, {
        response_decode: ColiswebApi__V6_Authent.UpdateSession.response_decode,
        config: ColiswebApi__V6_Authent.UpdateSession.config
      }, Caml_option.some(undefined));
  var session = match$2[0];
  var currentUserRole = session.organisationRole;
  var isColisweber = session.isAdmin;
  var columns = React.useMemo((function (param) {
          return [
                  {
                    accessor: (function (user) {
                        return user.uuid;
                      }),
                    id: "uuid",
                    header: null
                  },
                  {
                    accessor: (function (user) {
                        return user.email;
                      }),
                    id: "email",
                    header: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                          id: "_1e884e30",
                          defaultMessage: "E-mail"
                        }),
                    filterRender: (function (param) {
                        return null;
                      }),
                    cell: (function (param) {
                        return JsxRuntime.jsx("p", {
                                    children: param.cell.value,
                                    className: "text-neutral-700 truncate"
                                  });
                      }),
                    minWidth: 100,
                    width: 120
                  },
                  {
                    accessor: (function (user) {
                        return [
                                user.organizationRole,
                                user
                              ];
                      }),
                    id: "role",
                    header: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                          id: "_19772a94",
                          defaultMessage: "Rôle"
                        }),
                    filterRender: (function (param) {
                        return null;
                      }),
                    cell: (function (props) {
                        var match = props.cell.value;
                        var user = match[1];
                        if (editedUser !== undefined && editedUser.uuid === user.uuid) {
                          return JsxRuntime.jsx(ApiComponents__UsersList$UserRoleEdition, {
                                      user: user,
                                      entity: entity,
                                      onCancel: (function (param) {
                                          Curry._1(setEditedUser, (function (param) {
                                                  
                                                }));
                                        }),
                                      onSuccess: (function (param) {
                                          Curry._1(setEditedUser, (function (param) {
                                                  
                                                }));
                                        }),
                                      isColisweber: isColisweber
                                    });
                        }
                        return JsxRuntime.jsx("p", {
                                    children: ColiswebApi__V6_Utils.OrganizationRole.humanize(match[0], intl),
                                    className: "text-neutral-700"
                                  });
                      }),
                    minWidth: 100,
                    width: 120
                  },
                  {
                    accessor: (function (user) {
                        return user;
                      }),
                    id: "password",
                    header: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                          id: "_8dd568ad",
                          defaultMessage: "État"
                        }),
                    filterRender: (function (param) {
                        return null;
                      }),
                    cell: (function (param) {
                        var match = param.cell.value;
                        var expiresAt = match.expiresAt;
                        if (expiresAt === undefined) {
                          return JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                            id: "_c1f480d9",
                                            defaultMessage: "Activé"
                                          }),
                                      className: "text-neutral-700"
                                    });
                        }
                        var email = match.email;
                        var userUuid = match.uuid;
                        if (!DateFns.isBefore(Caml_option.valFromOption(expiresAt), new Date())) {
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsxs("p", {
                                              children: [
                                                JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_84a0494d",
                                                      defaultMessage: "Invitation en attente"
                                                    }),
                                                JsxRuntime.jsx(Fa.FaHourglassHalf, {
                                                      size: 14
                                                    })
                                              ],
                                              className: "text-info-600 inline-flex items-center gap-1 font-medium"
                                            }),
                                        isColisweber ? JsxRuntime.jsx(ApiComponents__UsersList$RenewInvitationButton, {
                                                userUuid: userUuid,
                                                email: email,
                                                entity: entity
                                              }) : null
                                      ],
                                      className: "flex flex-row gap-2 items-center"
                                    });
                        }
                        var tmp;
                        var exit = 0;
                        switch (match.organizationRole) {
                          case "owner" :
                              tmp = isColisweber ? JsxRuntime.jsx(ApiComponents__UsersList$RenewInvitationButton, {
                                      userUuid: userUuid,
                                      email: email,
                                      entity: entity
                                    }) : null;
                              break;
                          case "admin" :
                          case "user" :
                              exit = 1;
                              break;
                          
                        }
                        if (exit === 1) {
                          tmp = currentUserRole !== "user" ? JsxRuntime.jsx(ApiComponents__UsersList$RenewInvitationButton, {
                                  userUuid: userUuid,
                                  email: email,
                                  entity: entity
                                }) : null;
                        }
                        return JsxRuntime.jsxs("div", {
                                    children: [
                                      JsxRuntime.jsxs("p", {
                                            children: [
                                              JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                    id: "_2f614c83",
                                                    defaultMessage: "Invitation expirée"
                                                  }),
                                              JsxRuntime.jsx(Fa.FaExclamationCircle, {
                                                    size: 14
                                                  })
                                            ],
                                            className: "text-warning-600 inline-flex items-center gap-1 font-medium"
                                          }),
                                      tmp
                                    ],
                                    className: "flex flex-row gap-2 items-center"
                                  });
                      }),
                    minWidth: 100,
                    width: 120
                  },
                  {
                    accessor: (function (user) {
                        return user;
                      }),
                    id: "actions",
                    header: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                          id: "_06df3300",
                          defaultMessage: "Actions"
                        }),
                    filterRender: (function (param) {
                        return null;
                      }),
                    cell: (function (param) {
                        var cell = param.cell;
                        var match = cell.value;
                        var isSameUser = match.uuid === session.user.uuid;
                        var canEdit;
                        var exit = 0;
                        if (isSameUser) {
                          canEdit = false;
                        } else {
                          switch (match.organizationRole) {
                            case "owner" :
                                canEdit = false;
                                break;
                            case "admin" :
                            case "user" :
                                exit = 1;
                                break;
                            
                          }
                        }
                        if (exit === 1) {
                          if (isColisweber) {
                            canEdit = true;
                          } else {
                            switch (currentUserRole) {
                              case "owner" :
                              case "admin" :
                                  canEdit = true;
                                  break;
                              case "user" :
                                  canEdit = false;
                                  break;
                              
                            }
                          }
                        }
                        if (canEdit) {
                          return JsxRuntime.jsxs("div", {
                                      children: [
                                        JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                              onClick: (function (param) {
                                                  Curry._1(setEditedUser, (function (param) {
                                                          return cell.value;
                                                        }));
                                                }),
                                              className: "!px-2",
                                              children: Caml_option.some(JsxRuntime.jsx(Fa.FaPencilAlt, {}))
                                            }),
                                        JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                              color: "danger",
                                              onClick: (function (param) {
                                                  Curry._1(setUserToDelete, (function (param) {
                                                          return cell.value;
                                                        }));
                                                }),
                                              className: "!px-2",
                                              children: Caml_option.some(JsxRuntime.jsx(Fa.FaRegTrashAlt, {
                                                        size: 16
                                                      }))
                                            })
                                      ],
                                      className: "flex gap-4"
                                    });
                        } else {
                          return null;
                        }
                      }),
                    minWidth: 100,
                    width: 120,
                    disableSortBy: true
                  }
                ];
        }), [
        editedUser,
        currentUserRole,
        isColisweber
      ]);
  var swrConfig = Swr.useSWRConfig();
  var defaultSort = React.useMemo((function (param) {
          return [{
                    value: "desc",
                    id: "email"
                  }];
        }), []);
  var table = ReactTable.useTable({
        columns: columns,
        data: users,
        initialState: {
          hiddenColumns: ["uuid"],
          sortBy: defaultSort,
          pageSize: 25
        },
        manualPagination: false,
        manualFilters: false,
        manualSortBy: false
      }, ReactTable.useFilters, ReactTable.useSortBy, ReactTable.usePagination, ReactTable.useFlexLayout, ReactTable.useResizeColumns);
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("p", {
                                      children: JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                            id: "_15da6307",
                                            defaultMessage: "Liste des membres de votre organisation"
                                          }),
                                      className: "text-neutral-800 text-2xl ml-2"
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Tag.make, {
                                      color: "info",
                                      size: "sm",
                                      children: users.length
                                    })
                              ],
                              className: "flex flex-row px-2 gap-2"
                            }),
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("div", {
                                      children: JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                                            color: "primary",
                                            onClick: (function (param) {
                                                Curry._1(invitationModalDisclosure.show, undefined);
                                              }),
                                            children: Caml_option.some(JsxRuntime.jsx(ReactIntl.FormattedMessage, {
                                                      id: "_00896fad",
                                                      defaultMessage: "Inviter un membre"
                                                    })),
                                            leftIcon: {
                                              make: Fa.FaPlusCircle
                                            }
                                          })
                                    }),
                                JsxRuntime.jsx(Toolkit__Ui_Table.Pagination.make, {
                                      table: table
                                    })
                              ],
                              className: "flex flex-row items-center gap-6"
                            })
                      ],
                      className: "w-full flex items-center justify-between gap-4 pr-4 py-4"
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Table.Core.make, {
                      table: table,
                      className: Cx.cx(["e2e-users-list overflow-x-visible"]),
                      emptyMessage: intl.formatMessage({
                            id: "_17e6abcc",
                            defaultMessage: "Aucun résultat"
                          }),
                      tdClassName: "xl:!min-h-[2rem]"
                    }),
                JsxRuntime.jsx(ApiComponents__UsersList$InvitationModal, {
                      disclosure: invitationModalDisclosure,
                      entity: entity,
                      adminSeatAvailable: 5 - Curry._2(Toolkit__Primitives_Array.$$Array.keep, users, (function (param) {
                              return param.organizationRole === "admin";
                            })).length | 0
                    }),
                Belt_Option.mapWithDefault(match$1[0], null, (function (selectedUserToDelete) {
                        return JsxRuntime.jsx(ApiComponents__UsersList$UserDeletionModal, {
                                    entity: entity,
                                    selectedUserToDelete: selectedUserToDelete,
                                    onCancel: (function (param) {
                                        Curry._1(setUserToDelete, (function (param) {
                                                
                                              }));
                                      }),
                                    onSuccess: (function (param) {
                                        Curry._1(setUserToDelete, (function (param) {
                                                
                                              }));
                                        switch (entity.TAG) {
                                          case "Client" :
                                              var $$let = ColiswebApi__V6_Account.Users.Clients.Human.List;
                                              return $$Request.mutate(swrConfig, {
                                                          response_decode: $$let.response_decode,
                                                          config: $$let.config
                                                        }, entity._0);
                                          case "Store" :
                                              var $$let$1 = ColiswebApi__V6_Account.Users.Stores.Human.List;
                                              return $$Request.mutate(swrConfig, {
                                                          response_decode: $$let$1.response_decode,
                                                          config: $$let$1.config
                                                        }, [
                                                          entity._0,
                                                          entity._1
                                                        ]);
                                          case "Transporter" :
                                              var $$let$2 = ColiswebApi__V6_Account.Users.Transporters.Human.List;
                                              return $$Request.mutate(swrConfig, {
                                                          response_decode: $$let$2.response_decode,
                                                          config: $$let$2.config
                                                        }, entity._0);
                                          case "Hub" :
                                              var $$let$3 = ColiswebApi__V6_Account.Users.Hubs.Human.List;
                                              return $$Request.mutate(swrConfig, {
                                                          response_decode: $$let$3.response_decode,
                                                          config: $$let$3.config
                                                        }, entity._0);
                                          
                                        }
                                      })
                                  });
                      }))
              ],
              className: "bg-white"
            });
}

function ApiComponents__UsersList$Client(props) {
  var clientId = props.clientId;
  var $$let = ColiswebApi__V6_Account.Users.Clients.Human.List;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, clientId);
  var $$let$1 = ColiswebApi__V6_Account.Users.Clients.Invitation.List;
  var match$1 = $$Request.useFetcher(undefined, {
        response_decode: $$let$1.response_decode,
        config: $$let$1.config
      }, clientId);
  return JsxRuntime.jsx(ApiComponents__UsersList$List, {
              users: Curry._2(Toolkit__Primitives_Array.$$Array.concat, match[0].users, match$1[0].invitations),
              entity: {
                TAG: "Client",
                _0: clientId
              }
            });
}

var Client = {
  make: ApiComponents__UsersList$Client
};

function ApiComponents__UsersList$Store(props) {
  var storeId = props.storeId;
  var clientId = props.clientId;
  var $$let = ColiswebApi__V6_Account.Users.Stores.Human.List;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, [
        clientId,
        storeId
      ]);
  var $$let$1 = ColiswebApi__V6_Account.Users.Stores.Invitation.List;
  var match$1 = $$Request.useFetcher(undefined, {
        response_decode: $$let$1.response_decode,
        config: $$let$1.config
      }, [
        clientId,
        storeId
      ]);
  return JsxRuntime.jsx(ApiComponents__UsersList$List, {
              users: Curry._2(Toolkit__Primitives_Array.$$Array.concat, match[0].users, match$1[0].invitations),
              entity: {
                TAG: "Store",
                _0: clientId,
                _1: storeId
              }
            });
}

var Store = {
  make: ApiComponents__UsersList$Store
};

function ApiComponents__UsersList$Transporter(props) {
  var transporterId = props.transporterId;
  var $$let = ColiswebApi__V6_Account.Users.Transporters.Human.List;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, transporterId);
  var $$let$1 = ColiswebApi__V6_Account.Users.Transporters.Invitation.List;
  var match$1 = $$Request.useFetcher(undefined, {
        response_decode: $$let$1.response_decode,
        config: $$let$1.config
      }, transporterId);
  return JsxRuntime.jsx(ApiComponents__UsersList$List, {
              users: Curry._2(Toolkit__Primitives_Array.$$Array.concat, match[0].users, match$1[0].invitations),
              entity: {
                TAG: "Transporter",
                _0: transporterId
              }
            });
}

var Transporter = {
  make: ApiComponents__UsersList$Transporter
};

function ApiComponents__UsersList$Hub(props) {
  var hubId = props.hubId;
  var $$let = ColiswebApi__V6_Account.Users.Hubs.Human.List;
  var match = $$Request.useFetcher(undefined, {
        response_decode: $$let.response_decode,
        config: $$let.config
      }, hubId);
  var $$let$1 = ColiswebApi__V6_Account.Users.Hubs.Invitation.List;
  var match$1 = $$Request.useFetcher(undefined, {
        response_decode: $$let$1.response_decode,
        config: $$let$1.config
      }, hubId);
  return JsxRuntime.jsx(ApiComponents__UsersList$List, {
              users: Curry._2(Toolkit__Primitives_Array.$$Array.concat, match[0].users, match$1[0].invitations),
              entity: {
                TAG: "Hub",
                _0: hubId
              }
            });
}

var Hub = {
  make: ApiComponents__UsersList$Hub
};

export {
  Transporter ,
  Client ,
  Store ,
  Hub ,
}
/* Form Not a pure module */
