

import * as Ky from "ky";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as ColiswebApi__V6_Utils from "./ColiswebApi__V6_Utils.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

var kyInstance = Ky.default.create({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/authent/external",
      timeout: 20000,
      credentials: "include",
      mode: "cors"
    });

function t_encode(v) {
  switch (v) {
    case "INVALID_CREDENTIALS_ERROR" :
        return "INVALID_CREDENTIALS_ERROR";
    case "invalidRole" :
        return "invalidRole";
    case "accessBlocked" :
        return "accessBlocked";
    case "TOO_MANY_LOGGING_ATTEMPT" :
        return "TOO_MANY_LOGGING_ATTEMPT";
    
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("INVALID_CREDENTIALS_ERROR" === v) {
          return {
                  TAG: "Ok",
                  _0: "INVALID_CREDENTIALS_ERROR"
                };
        } else if ("invalidRole" === v) {
          return {
                  TAG: "Ok",
                  _0: "invalidRole"
                };
        } else if ("accessBlocked" === v) {
          return {
                  TAG: "Ok",
                  _0: "accessBlocked"
                };
        } else if ("TOO_MANY_LOGGING_ATTEMPT" === v) {
          return {
                  TAG: "Ok",
                  _0: "TOO_MANY_LOGGING_ATTEMPT"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error = {
  t_encode: t_encode,
  t_decode: t_decode
};

function errorCode_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "code",
                    false,
                    t_encode(v.code)
                  ]]));
}

function errorCode_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              code: code._0
            }
          };
  }
  var e = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "error",
                    false,
                    errorCode_encode(v.error)
                  ]]));
}

function errorResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var error = errorCode_decode(Belt_Option.getWithDefault(Js_dict.get(v, "error"), null));
  if (error.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              error: error._0
            }
          };
  }
  var e = error._0;
  return {
          TAG: "Error",
          _0: {
            path: ".error" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function handleError(error) {
  return Belt_Option.mapWithDefault(error.response, $$Promise.resolved({
                  TAG: "Unknown",
                  _0: error
                }), (function (response) {
                switch (response.status) {
                  case 401 :
                      return $$Promise.resolved({
                                  TAG: "Custom",
                                  _0: "INVALID_CREDENTIALS_ERROR"
                                });
                  case 402 :
                      break;
                  case 403 :
                      return $$Promise.resolved({
                                  TAG: "Custom",
                                  _0: "accessBlocked"
                                });
                  default:
                    
                }
                return $$Promise.map($$Request.decodeResponseError(response, errorResponse_decode), (function (res) {
                              if (res.TAG === "Ok") {
                                return {
                                        TAG: "Custom",
                                        _0: res._0.error.code
                                      };
                              } else {
                                return {
                                        TAG: "Unknown",
                                        _0: error
                                      };
                              }
                            }));
              }));
}

var Shared = {
  $$Error: $$Error,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  errorCode_encode: errorCode_encode,
  errorCode_decode: errorCode_decode,
  handleError: handleError
};

function t_encode$1(v) {
  switch (v) {
    case "admin" :
        return "admin";
    case "techAdmin" :
        return "techAdmin";
    case "store" :
        return "store";
    case "client" :
        return "client";
    case "transporter" :
        return "transporter";
    case "carrier" :
        return "carrier";
    case "supplier" :
        return "supplier";
    case "unknown" :
        return "unknown";
    case "hub_handler" :
        return "hub_handler";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("techAdmin" === v) {
          return {
                  TAG: "Ok",
                  _0: "techAdmin"
                };
        } else if ("store" === v) {
          return {
                  TAG: "Ok",
                  _0: "store"
                };
        } else if ("client" === v) {
          return {
                  TAG: "Ok",
                  _0: "client"
                };
        } else if ("transporter" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter"
                };
        } else if ("carrier" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier"
                };
        } else if ("supplier" === v) {
          return {
                  TAG: "Ok",
                  _0: "supplier"
                };
        } else if ("unknown" === v) {
          return {
                  TAG: "Ok",
                  _0: "unknown"
                };
        } else if ("hub_handler" === v) {
          return {
                  TAG: "Ok",
                  _0: "hub_handler"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var Role = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function user_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "transporterId",
                    false,
                    Spice.optionToJson(Identifiers.TransporterId.t_encode, v.transporterId)
                  ],
                  [
                    "carrierId",
                    false,
                    Spice.optionToJson(Identifiers.CarrierId.t_encode, v.carrierId)
                  ],
                  [
                    "clientId",
                    false,
                    Spice.optionToJson(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "groups",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.groups)
                  ],
                  [
                    "uuid",
                    false,
                    Spice.optionToJson(Identifiers.UserUuid.t_encode, v.uuid)
                  ]
                ]));
}

function user_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var transporterId = Spice.optionFromJson(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
      if (transporterId.TAG === "Ok") {
        var carrierId = Spice.optionFromJson(Identifiers.CarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
        if (carrierId.TAG === "Ok") {
          var clientId = Spice.optionFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
          if (clientId.TAG === "Ok") {
            var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
            if (storeId.TAG === "Ok") {
              var groups = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "groups"), null));
              if (groups.TAG === "Ok") {
                var uuid = Spice.optionFromJson(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
                if (uuid.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            id: id._0,
                            username: username._0,
                            transporterId: transporterId._0,
                            carrierId: carrierId._0,
                            clientId: clientId._0,
                            storeId: storeId._0,
                            groups: groups._0,
                            uuid: uuid._0
                          }
                        };
                }
                var e = uuid._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".uuid" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = groups._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".groups" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = storeId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".storeId" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = clientId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clientId" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = carrierId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carrierId" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = transporterId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transporterId" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function rawTokenPayload_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Spice.stringToJson(v.id)
                  ],
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "transporterId",
                    false,
                    Spice.optionToJson(Identifiers.TransporterId.t_encode, v.transporterId)
                  ],
                  [
                    "carrierId",
                    false,
                    Spice.optionToJson(Identifiers.CarrierId.t_encode, v.carrierId)
                  ],
                  [
                    "clientId",
                    false,
                    Spice.optionToJson(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Spice.optionToJson(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "uuid",
                    false,
                    Spice.optionToJson(Identifiers.UserUuid.t_encode, v.uuid)
                  ],
                  [
                    "groups",
                    false,
                    Spice.arrayToJson(Spice.stringToJson, v.groups)
                  ],
                  [
                    "fiat",
                    false,
                    Spice.intToJson(v.fiat)
                  ],
                  [
                    "iat",
                    false,
                    Spice.intToJson(v.iat)
                  ],
                  [
                    "exp",
                    false,
                    Spice.intToJson(v.exp)
                  ],
                  [
                    "iss",
                    false,
                    Spice.stringToJson(v.iss)
                  ]
                ]));
}

function rawTokenPayload_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
    if (username.TAG === "Ok") {
      var transporterId = Spice.optionFromJson(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
      if (transporterId.TAG === "Ok") {
        var carrierId = Spice.optionFromJson(Identifiers.CarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carrierId"), null));
        if (carrierId.TAG === "Ok") {
          var clientId = Spice.optionFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
          if (clientId.TAG === "Ok") {
            var storeId = Spice.optionFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
            if (storeId.TAG === "Ok") {
              var uuid = Spice.optionFromJson(Identifiers.UserUuid.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "uuid"), null));
              if (uuid.TAG === "Ok") {
                var groups = Spice.arrayFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "groups"), null));
                if (groups.TAG === "Ok") {
                  var fiat = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "fiat"), null));
                  if (fiat.TAG === "Ok") {
                    var iat = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "iat"), null));
                    if (iat.TAG === "Ok") {
                      var exp = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "exp"), null));
                      if (exp.TAG === "Ok") {
                        var iss = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "iss"), null));
                        if (iss.TAG === "Ok") {
                          return {
                                  TAG: "Ok",
                                  _0: {
                                    id: id._0,
                                    username: username._0,
                                    transporterId: transporterId._0,
                                    carrierId: carrierId._0,
                                    clientId: clientId._0,
                                    storeId: storeId._0,
                                    uuid: uuid._0,
                                    groups: groups._0,
                                    fiat: fiat._0,
                                    iat: iat._0,
                                    exp: exp._0,
                                    iss: iss._0
                                  }
                                };
                        }
                        var e = iss._0;
                        return {
                                TAG: "Error",
                                _0: {
                                  path: ".iss" + e.path,
                                  message: e.message,
                                  value: e.value
                                }
                              };
                      }
                      var e$1 = exp._0;
                      return {
                              TAG: "Error",
                              _0: {
                                path: ".exp" + e$1.path,
                                message: e$1.message,
                                value: e$1.value
                              }
                            };
                    }
                    var e$2 = iat._0;
                    return {
                            TAG: "Error",
                            _0: {
                              path: ".iat" + e$2.path,
                              message: e$2.message,
                              value: e$2.value
                            }
                          };
                  }
                  var e$3 = fiat._0;
                  return {
                          TAG: "Error",
                          _0: {
                            path: ".fiat" + e$3.path,
                            message: e$3.message,
                            value: e$3.value
                          }
                        };
                }
                var e$4 = groups._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".groups" + e$4.path,
                          message: e$4.message,
                          value: e$4.value
                        }
                      };
              }
              var e$5 = uuid._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".uuid" + e$5.path,
                        message: e$5.message,
                        value: e$5.value
                      }
                    };
            }
            var e$6 = storeId._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".storeId" + e$6.path,
                      message: e$6.message,
                      value: e$6.value
                    }
                  };
          }
          var e$7 = clientId._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".clientId" + e$7.path,
                    message: e$7.message,
                    value: e$7.value
                  }
                };
        }
        var e$8 = carrierId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carrierId" + e$8.path,
                  message: e$8.message,
                  value: e$8.value
                }
              };
      }
      var e$9 = transporterId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transporterId" + e$9.path,
                message: e$9.message,
                value: e$9.value
              }
            };
    }
    var e$10 = username._0;
    return {
            TAG: "Error",
            _0: {
              path: ".username" + e$10.path,
              message: e$10.message,
              value: e$10.value
            }
          };
  }
  var e$11 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$11.path,
            message: e$11.message,
            value: e$11.value
          }
        };
}

function humanizedResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "firstIssuedAt",
                    false,
                    Spice.intToJson(v.firstIssuedAt)
                  ],
                  [
                    "issuedAt",
                    false,
                    Spice.intToJson(v.issuedAt)
                  ],
                  [
                    "expiresAt",
                    false,
                    Spice.intToJson(v.expiresAt)
                  ],
                  [
                    "issuer",
                    false,
                    Spice.stringToJson(v.issuer)
                  ],
                  [
                    "user",
                    false,
                    user_encode(v.user)
                  ],
                  [
                    "role",
                    false,
                    t_encode$1(v.role)
                  ],
                  [
                    "isAdmin",
                    false,
                    Spice.boolToJson(v.isAdmin)
                  ],
                  [
                    "organisationRole",
                    false,
                    ColiswebApi__V6_Utils.OrganizationRole.t_encode(v.organisationRole)
                  ]
                ]));
}

function humanizedResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var firstIssuedAt = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstIssuedAt"), null));
  if (firstIssuedAt.TAG === "Ok") {
    var issuedAt = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "issuedAt"), null));
    if (issuedAt.TAG === "Ok") {
      var expiresAt = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "expiresAt"), null));
      if (expiresAt.TAG === "Ok") {
        var issuer = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "issuer"), null));
        if (issuer.TAG === "Ok") {
          var user = user_decode(Belt_Option.getWithDefault(Js_dict.get(v, "user"), null));
          if (user.TAG === "Ok") {
            var role = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "role"), t_decode$1), {
                  TAG: "Ok",
                  _0: "unknown"
                });
            if (role.TAG === "Ok") {
              var isAdmin = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "isAdmin"), Spice.boolFromJson), {
                    TAG: "Ok",
                    _0: false
                  });
              if (isAdmin.TAG === "Ok") {
                var organisationRole = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "organisationRole"), ColiswebApi__V6_Utils.OrganizationRole.t_decode), {
                      TAG: "Ok",
                      _0: "user"
                    });
                if (organisationRole.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            firstIssuedAt: firstIssuedAt._0,
                            issuedAt: issuedAt._0,
                            expiresAt: expiresAt._0,
                            issuer: issuer._0,
                            user: user._0,
                            role: role._0,
                            isAdmin: isAdmin._0,
                            organisationRole: organisationRole._0
                          }
                        };
                }
                var e = organisationRole._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".organisationRole" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = isAdmin._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".isAdmin" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = role._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".role" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = user._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".user" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = issuer._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".issuer" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = expiresAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".expiresAt" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = issuedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".issuedAt" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = firstIssuedAt._0;
  return {
          TAG: "Error",
          _0: {
            path: ".firstIssuedAt" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function makeRole(user) {
  if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return group.includes("tech-admin");
          }))) {
    return "techAdmin";
  } else if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return /^admin-/.test(group);
          }))) {
    return "admin";
  } else if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return group.includes("supplier");
          }))) {
    return "supplier";
  } else if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return group.includes("hub-handler");
          }))) {
    return "hub_handler";
  } else if (user.storeId !== undefined) {
    return "store";
  } else if (user.clientId !== undefined) {
    return "client";
  } else if (user.transporterId !== undefined) {
    if (user.carrierId !== undefined) {
      return "carrier";
    } else {
      return "transporter";
    }
  } else {
    return "unknown";
  }
}

function makeUserOrganizationRole(user) {
  if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return group.includes("-owner-");
          }))) {
    return "owner";
  } else if (Curry._2(Toolkit__Primitives_Array.$$Array.some, user.groups, (function (group) {
            return group.includes("-admin-");
          }))) {
    return "admin";
  } else {
    return "user";
  }
}

function isAdmin(role) {
  switch (role) {
    case "admin" :
    case "techAdmin" :
        return true;
    default:
      return false;
  }
}

function rawTokenPayloadToHumanizedResponse(payload) {
  var user_id = payload.id;
  var user_username = payload.username;
  var user_transporterId = payload.transporterId;
  var user_carrierId = payload.carrierId;
  var user_clientId = payload.clientId;
  var user_storeId = payload.storeId;
  var user_groups = payload.groups;
  var user_uuid = payload.uuid;
  var user = {
    id: user_id,
    username: user_username,
    transporterId: user_transporterId,
    carrierId: user_carrierId,
    clientId: user_clientId,
    storeId: user_storeId,
    groups: user_groups,
    uuid: user_uuid
  };
  var role = makeRole(user);
  var organisationRole = makeUserOrganizationRole(user);
  return {
          firstIssuedAt: payload.fiat,
          issuedAt: payload.iat,
          expiresAt: payload.exp,
          issuer: payload.iss,
          user: user,
          role: role,
          isAdmin: isAdmin(role),
          organisationRole: organisationRole
        };
}

function argument_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "username",
                    false,
                    Spice.stringToJson(v.username)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ]
                ]));
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var username = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "username"), null));
  if (username.TAG === "Ok") {
    var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                username: username._0,
                password: password._0
              }
            };
    }
    var e = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = username._0;
  return {
          TAG: "Error",
          _0: {
            path: ".username" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode = humanizedResponse_encode;

var response_decode = humanizedResponse_decode;

var error_encode = t_encode;

var error_decode = t_decode;

function config(argument) {
  return {
          kyInstance: kyInstance,
          path: "session",
          requestOptions: {
            method: "POST",
            json: argument_encode(argument)
          },
          mapCustomErrors: handleError,
          mapRawResponse: (function (response) {
              return $$Promise.resolved(Curry._2(Toolkit__Primitives_Result.Result.mapOk, $$Request.decodeRawResponse(response, rawTokenPayload_decode), rawTokenPayloadToHumanizedResponse));
            })
        };
}

var CreateSession = {
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  error_encode: error_encode,
  error_decode: error_decode,
  config: config
};

var response_encode$1 = humanizedResponse_encode;

var response_decode$1 = humanizedResponse_decode;

function config$1(param) {
  return {
          kyInstance: kyInstance,
          path: "session",
          requestOptions: {
            method: "PUT"
          },
          key: [
            "ColiswebApi__V6_Authent",
            "UpdateSession"
          ],
          mapCustomErrors: handleError,
          mapRawResponse: (function (res) {
              return $$Promise.resolved(Curry._2(Toolkit__Primitives_Result.Result.mapOk, $$Request.decodeRawResponse(res, rawTokenPayload_decode), rawTokenPayloadToHumanizedResponse));
            })
        };
}

var UpdateSession = {
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  config: config$1
};

var response_encode$2 = Spice.unitToJson;

var response_decode$2 = Spice.unitFromJson;

function config$2(param) {
  return {
          kyInstance: kyInstance,
          path: "session",
          requestOptions: {
            method: "DELETE"
          }
        };
}

var DeleteSession = {
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  config: config$2
};

var kyInstance$1 = kyInstance.extend({
      prefixUrl: ColiswebApi__Env.v6ApiUrl + "/authent/external/security/clients"
    });

function response_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "ipWhiteList",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(Spice.stringToJson, param);
                          }), v.ipWhiteList)
                  ]]));
}

function response_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ipWhiteList = Spice.optionFromJson((function (param) {
          return Spice.arrayFromJson(Spice.stringFromJson, param);
        }), Belt_Option.getWithDefault(Js_dict.get(v, "ipWhiteList"), null));
  if (ipWhiteList.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              ipWhiteList: ipWhiteList._0
            }
          };
  }
  var e = ipWhiteList._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ipWhiteList" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function config$3(clientId) {
  return {
          kyInstance: kyInstance$1,
          path: clientId,
          requestOptions: {
            method: "GET"
          },
          key: [
            "SecurityClientsGet",
            clientId
          ]
        };
}

var Get = {
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  config: config$3
};

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "ipWhiteList",
                    false,
                    Spice.optionToJson((function (param) {
                            return Spice.arrayToJson(Spice.stringToJson, param);
                          }), v.ipWhiteList)
                  ]]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var ipWhiteList = Spice.optionFromJson((function (param) {
          return Spice.arrayFromJson(Spice.stringFromJson, param);
        }), Belt_Option.getWithDefault(Js_dict.get(v, "ipWhiteList"), null));
  if (ipWhiteList.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              ipWhiteList: ipWhiteList._0
            }
          };
  }
  var e = ipWhiteList._0;
  return {
          TAG: "Error",
          _0: {
            path: ".ipWhiteList" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

var response_encode$4 = Spice.unitToJson;

var response_decode$4 = Spice.unitFromJson;

function config$4(param) {
  return {
          kyInstance: kyInstance$1,
          path: param[0],
          requestOptions: {
            method: "PUT",
            json: body_encode(param[1])
          }
        };
}

var Update = {
  body_encode: body_encode,
  body_decode: body_decode,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  config: config$4
};

var Clients = {
  kyInstance: kyInstance$1,
  Get: Get,
  Update: Update
};

var Security = {
  Clients: Clients
};

export {
  kyInstance ,
  Shared ,
  Role ,
  user_encode ,
  user_decode ,
  rawTokenPayload_encode ,
  rawTokenPayload_decode ,
  humanizedResponse_encode ,
  humanizedResponse_decode ,
  makeRole ,
  makeUserOrganizationRole ,
  isAdmin ,
  rawTokenPayloadToHumanizedResponse ,
  CreateSession ,
  UpdateSession ,
  DeleteSession ,
  Security ,
}
/* kyInstance Not a pure module */
