

import * as Ky from "ky";
import * as Axios from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/Axios.bs.js";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as $$Promise from "../../../../node_modules/@dck/rescript-promise/lib/es6_global/src/js/promise.bs.js";
import * as Js_array from "../../../../node_modules/rescript/lib/es6/js_array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as ColiswebApi__Env from "../ColiswebApi__Env.bs.js";
import * as Toolkit__Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Toolkit__Request.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as ColiswebApi__Utils from "../ColiswebApi__Utils.bs.js";
import * as ColiswebApi__V5_Utils from "./ColiswebApi__V5_Utils.bs.js";
import * as ColiswebApi__V5_Shared from "./ColiswebApi__V5_Shared.bs.js";

var baseUrl = "/admin";

var arg = ColiswebApi__Env.v5ApiUrl;

var arg$1 = ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000;

function config(param, param$1, param$2, param$3, param$4, param$5, param$6, param$7) {
  var partial_arg = true;
  return function (param$8, param$9, param$10, param$11, param$12, param$13, param$14, param$15, param$16, param$17, param$18, param$19, param$20, param$21) {
    var tmp = {};
    if (param !== undefined) {
      tmp.url = param;
    }
    if (param$1 !== undefined) {
      tmp._method = param$1;
    }
    if (arg !== undefined) {
      tmp.baseURL = arg;
    }
    if (param$2 !== undefined) {
      tmp.transformRequest = Caml_option.valFromOption(param$2);
    }
    if (param$3 !== undefined) {
      tmp.transformResponse = Caml_option.valFromOption(param$3);
    }
    if (param$4 !== undefined) {
      tmp.headers = Caml_option.valFromOption(param$4);
    }
    if (param$5 !== undefined) {
      tmp.params = Caml_option.valFromOption(param$5);
    }
    if (param$6 !== undefined) {
      tmp.paramsSerializer = Caml_option.valFromOption(param$6);
    }
    if (param$7 !== undefined) {
      tmp.data = Caml_option.valFromOption(param$7);
    }
    if (arg$1 !== undefined) {
      tmp.timeout = arg$1;
    }
    if (partial_arg !== undefined) {
      tmp.withCredentials = partial_arg;
    }
    if (param$8 !== undefined) {
      tmp.adapter = Caml_option.valFromOption(param$8);
    }
    if (param$9 !== undefined) {
      tmp.auth = Caml_option.valFromOption(param$9);
    }
    if (param$10 !== undefined) {
      tmp.responseType = param$10;
    }
    if (param$11 !== undefined) {
      tmp.xsrfCookieName = param$11;
    }
    if (param$12 !== undefined) {
      tmp.xsrfHeaderName = param$12;
    }
    if (param$13 !== undefined) {
      tmp.onUploadProgress = Caml_option.valFromOption(param$13);
    }
    if (param$14 !== undefined) {
      tmp.onDownloadProgress = Caml_option.valFromOption(param$14);
    }
    if (param$15 !== undefined) {
      tmp.maxContentLength = param$15;
    }
    if (param$16 !== undefined) {
      tmp.validateStatus = Caml_option.valFromOption(param$16);
    }
    if (param$17 !== undefined) {
      tmp.maxRedirects = param$17;
    }
    if (param$18 !== undefined) {
      tmp.socketPath = param$18;
    }
    if (param$19 !== undefined) {
      tmp.proxy = Caml_option.valFromOption(param$19);
    }
    if (param$20 !== undefined) {
      tmp.cancelToken = Caml_option.valFromOption(param$20);
    }
    return tmp;
  };
}

var prefixBaseUrl = ColiswebApi__Env.v5ApiUrl + "/admin";

var kyInstance = Ky.default.create({
      prefixUrl: prefixBaseUrl,
      timeout: ColiswebApi__Env.isNodeDevelopment ? 5000 : 20000,
      credentials: "include",
      mode: "cors"
    });

function t_encode(v) {
  if (v === "delivery_not_found") {
    return "delivery_not_found";
  } else {
    return "rescheduling_non_terminated_delivery_not_authorized";
  }
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("rescheduling_non_terminated_delivery_not_authorized" === v) {
          return {
                  TAG: "Ok",
                  _0: "rescheduling_non_terminated_delivery_not_authorized"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error = {
  t_encode: t_encode,
  t_decode: t_decode
};

function body_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    ColiswebApi__V5_Utils.TimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "keepPreviousAssignment",
                    false,
                    Spice.boolToJson(v.keepPreviousAssignment)
                  ],
                  [
                    "notifyPickup",
                    false,
                    Spice.boolToJson(v.notifyPickup)
                  ],
                  [
                    "notifyDropoff",
                    false,
                    Spice.boolToJson(v.notifyDropoff)
                  ]
                ]));
}

function body_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = ColiswebApi__V5_Utils.TimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var keepPreviousAssignment = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "keepPreviousAssignment"), null));
    if (keepPreviousAssignment.TAG === "Ok") {
      var notifyPickup = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyPickup"), null));
      if (notifyPickup.TAG === "Ok") {
        var notifyDropoff = Spice.boolFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "notifyDropoff"), null));
        if (notifyDropoff.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    timeSlot: timeSlot._0,
                    keepPreviousAssignment: keepPreviousAssignment._0,
                    notifyPickup: notifyPickup._0,
                    notifyDropoff: notifyDropoff._0
                  }
                };
        }
        var e = notifyDropoff._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".notifyDropoff" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = notifyPickup._0;
      return {
              TAG: "Error",
              _0: {
                path: ".notifyPickup" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = keepPreviousAssignment._0;
    return {
            TAG: "Error",
            _0: {
              path: ".keepPreviousAssignment" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function argument_encode(v) {
  return [
          Curry._1(Identifiers.DeliveryId.t_encode, v[0]),
          body_encode(v[1])
        ];
}

function argument_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.DeliveryId.t_decode, v0);
  var match$1 = body_decode(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ]]));
}

function response_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveryId: deliveryId._0
            }
          };
  }
  var e = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function errorResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "type",
                    false,
                    t_encode(v.type_)
                  ]]));
}

function errorResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              type_: type_._0
            }
          };
  }
  var e = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e.path),
            message: e.message,
            value: e.value
          }
        };
}

function exec(param) {
  return Axios.WithResult.post(undefined, body_encode(param[1]), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var err = errorResponse_decode(match.data);
                if (err.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: err._0.type_
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            err._0
                          ]
                        };
                }
              }), baseUrl + "/deliveries/" + param[0] + "/reschedule");
}

var Config = {
  body_encode: body_encode,
  body_decode: body_decode,
  argument_encode: argument_encode,
  argument_decode: argument_decode,
  response_encode: response_encode,
  response_decode: response_decode,
  errorResponse_encode: errorResponse_encode,
  errorResponse_decode: errorResponse_decode,
  exec: exec
};

var $$Request = Toolkit__Request.Make({
      exec: exec
    });

var RescheduleDelivery = {
  $$Error: $$Error,
  Config: Config,
  $$Request: $$Request
};

function body_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientIds",
                    false,
                    Spice.arrayToJson(Identifiers.ClientId.t_encode, v.clientIds)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.pageSize)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ]
                ]));
}

function body_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientIds = Spice.arrayFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
  if (clientIds.TAG === "Ok") {
    var page = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
    if (page.TAG === "Ok") {
      var pageSize = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
      if (pageSize.TAG === "Ok") {
        var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
        if (nameFilter.TAG === "Ok") {
          var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
          if (nameOrder.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      clientIds: clientIds._0,
                      page: page._0,
                      pageSize: pageSize._0,
                      nameFilter: nameFilter._0,
                      nameOrder: nameOrder._0
                    }
                  };
          }
          var e = nameOrder._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nameOrder" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = nameFilter._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".nameFilter" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = pageSize._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pageSize" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = page._0;
    return {
            TAG: "Error",
            _0: {
              path: ".page" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = clientIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientIds" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

var argument_encode$1 = body_encode$1;

var argument_decode$1 = body_decode$1;

function client_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmReference",
                    false,
                    Spice.optionToJson(Identifiers.ClientCRMReference.t_encode, v.crmReference)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function client_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var crmReference = Spice.optionFromJson(Identifiers.ClientCRMReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "crmReference"), null));
      if (crmReference.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    crmReference: crmReference._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = crmReference._0;
      return {
              TAG: "Error",
              _0: {
                path: ".crmReference" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode, v.clients)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "clientsCount",
                    false,
                    Spice.intToJson(v.clientsCount)
                  ]
                ]));
}

function response_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Spice.arrayFromJson(client_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
  if (clients.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var clientsCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientsCount"), null));
      if (clientsCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clients: clients._0,
                  pagesCount: pagesCount._0,
                  clientsCount: clientsCount._0
                }
              };
      }
      var e = clientsCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientsCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$1(body) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(body_encode$1(body)), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$1, undefined, baseUrl + "/clients");
}

var Config$1 = {
  argument_encode: argument_encode$1,
  argument_decode: argument_decode$1,
  body_encode: body_encode$1,
  body_decode: body_decode$1,
  response_encode: response_encode$1,
  response_decode: response_decode$1,
  client_encode: client_encode,
  client_decode: client_decode,
  exec: exec$1
};

var $$Request$1 = Toolkit__Request.Make({
      exec: exec$1
    });

var GetClientsPaginated = {
  Config: Config$1,
  $$Request: $$Request$1
};

function filters_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientIds = Spice.arrayFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
  if (clientIds.TAG === "Ok") {
    var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
    if (nameFilter.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                clientIds: clientIds._0,
                nameFilter: nameFilter._0
              }
            };
    }
    var e = nameFilter._0;
    return {
            TAG: "Error",
            _0: {
              path: ".nameFilter" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = clientIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientIds" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function sorting_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
  if (nameOrder.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              nameOrder: nameOrder._0
            }
          };
  }
  var e = nameOrder._0;
  return {
          TAG: "Error",
          _0: {
            path: ".nameOrder" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function sorting_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ]]));
}

function filters_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientIds",
                    false,
                    Spice.arrayToJson(Identifiers.ClientId.t_encode, v.clientIds)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ]
                ]));
}

function body_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var filters = filters_decode(Belt_Option.getWithDefault(Js_dict.get(v, "filters"), null));
  if (filters.TAG === "Ok") {
    var sorting = sorting_decode(Belt_Option.getWithDefault(Js_dict.get(v, "sorting"), null));
    if (sorting.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                filters: filters._0,
                sorting: sorting._0
              }
            };
    }
    var e = sorting._0;
    return {
            TAG: "Error",
            _0: {
              path: ".sorting" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = filters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".filters" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function body_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "filters",
                    false,
                    filters_encode(v.filters)
                  ],
                  [
                    "sorting",
                    false,
                    sorting_encode(v.sorting)
                  ]
                ]));
}

var argument_encode$2 = body_encode$2;

var argument_decode$2 = body_decode$2;

function client_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function client_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  updatedAt: updatedAt._0
                }
              };
      }
      var e = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$2(v) {
  return Spice.arrayToJson(client_encode$1, v);
}

function response_decode$2(v) {
  return Spice.arrayFromJson(client_decode$1, v);
}

function exec$2(body) {
  var params = Object.assign(sorting_encode(body.sorting), filters_encode(body.filters));
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(params), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$2, undefined, baseUrl + "/clients/all");
}

var Config$2 = {
  argument_encode: argument_encode$2,
  argument_decode: argument_decode$2,
  body_encode: body_encode$2,
  body_decode: body_decode$2,
  filters_encode: filters_encode,
  filters_decode: filters_decode,
  sorting_encode: sorting_encode,
  sorting_decode: sorting_decode,
  response_encode: response_encode$2,
  response_decode: response_decode$2,
  client_encode: client_encode$1,
  client_decode: client_decode$1,
  exec: exec$2
};

var $$Request$2 = Toolkit__Request.Make({
      exec: exec$2
    });

var GetAllClients = {
  Config: Config$2,
  $$Request: $$Request$2
};

function argument_encode$3(v) {
  return Spice.arrayToJson(Identifiers.ClientId.t_encode, v);
}

function argument_decode$3(v) {
  return Spice.arrayFromJson(Identifiers.ClientId.t_decode, v);
}

function body_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientIds",
                    false,
                    Spice.arrayToJson(Identifiers.ClientId.t_encode, v.clientIds)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.pageSize)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ]
                ]));
}

function body_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientIds = Spice.arrayFromJson(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientIds"), null));
  if (clientIds.TAG === "Ok") {
    var page = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
    if (page.TAG === "Ok") {
      var pageSize = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
      if (pageSize.TAG === "Ok") {
        var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
        if (nameFilter.TAG === "Ok") {
          var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
          if (nameOrder.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      clientIds: clientIds._0,
                      page: page._0,
                      pageSize: pageSize._0,
                      nameFilter: nameFilter._0,
                      nameOrder: nameOrder._0
                    }
                  };
          }
          var e = nameOrder._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nameOrder" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = nameFilter._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".nameFilter" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = pageSize._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pageSize" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = page._0;
    return {
            TAG: "Error",
            _0: {
              path: ".page" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = clientIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientIds" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function client_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var crmReference = Spice.optionFromJson(Identifiers.ClientCRMReference.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "crmReference"), null));
      if (crmReference.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    crmReference: crmReference._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = crmReference._0;
      return {
              TAG: "Error",
              _0: {
                path: ".crmReference" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function client_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "crmReference",
                    false,
                    Spice.optionToJson(Identifiers.ClientCRMReference.t_encode, v.crmReference)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function bodyResponse_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clients",
                    false,
                    Spice.arrayToJson(client_encode$2, v.clients)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "clientsCount",
                    false,
                    Spice.intToJson(v.clientsCount)
                  ]
                ]));
}

function bodyResponse_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clients = Spice.arrayFromJson(client_decode$2, Belt_Option.getWithDefault(Js_dict.get(v, "clients"), null));
  if (clients.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var clientsCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "clientsCount"), null));
      if (clientsCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clients: clients._0,
                  pagesCount: pagesCount._0,
                  clientsCount: clientsCount._0
                }
              };
      }
      var e = clientsCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientsCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clients._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clients" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$3(v) {
  return Spice.arrayToJson(client_encode$2, v);
}

function response_decode$3(v) {
  return Spice.arrayFromJson(client_decode$2, v);
}

function exec$3(clientIds) {
  if (clientIds.length !== 0) {
    return $$Promise.mapOk(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(body_encode$3({
                                  clientIds: clientIds,
                                  page: undefined,
                                  pageSize: clientIds.length,
                                  nameFilter: undefined,
                                  nameOrder: undefined
                                })), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), bodyResponse_decode, undefined, baseUrl + "/clients"), (function (response) {
                  return response.clients;
                }));
  } else {
    return $$Promise.resolved({
                TAG: "Ok",
                _0: []
              });
  }
}

var Config$3 = {
  argument_encode: argument_encode$3,
  argument_decode: argument_decode$3,
  body_encode: body_encode$3,
  body_decode: body_decode$3,
  bodyResponse_encode: bodyResponse_encode,
  bodyResponse_decode: bodyResponse_decode,
  response_encode: response_encode$3,
  response_decode: response_decode$3,
  client_encode: client_encode$2,
  client_decode: client_decode$2,
  exec: exec$3
};

var $$Request$3 = Toolkit__Request.Make({
      exec: exec$3
    });

var GetClientsInformation = {
  Config: Config$3,
  $$Request: $$Request$3
};

function argument_encode$4(v) {
  return Spice.arrayToJson(Identifiers.StoreId.t_encode, v);
}

function argument_decode$4(v) {
  return Spice.arrayFromJson(Identifiers.StoreId.t_decode, v);
}

function client_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function client_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function store_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "client",
                    false,
                    client_encode$3(v.client)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function store_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var client = client_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
      if (client.TAG === "Ok") {
        var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
        if (updatedAt.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    client: client._0,
                    updatedAt: updatedAt._0
                  }
                };
        }
        var e = updatedAt._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".updatedAt" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = client._0;
      return {
              TAG: "Error",
              _0: {
                path: ".client" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function bodyResponse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode, v.stores)
                  ],
                  [
                    "storesCount",
                    false,
                    Spice.intToJson(v.storesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function bodyResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    var storesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storesCount"), null));
    if (storesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  stores: stores._0,
                  storesCount: storesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$4(v) {
  return Spice.arrayToJson(store_encode, v);
}

function response_decode$4(v) {
  return Spice.arrayFromJson(store_decode, v);
}

function exec$4(storeIds) {
  if (storeIds.length !== 0) {
    return $$Promise.mapOk(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, {
                          storeIds: storeIds,
                          pageSize: storeIds.length
                        }, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), bodyResponse_decode$1, undefined, baseUrl + "/stores"), (function (response) {
                  return response.stores;
                }));
  } else {
    return $$Promise.resolved({
                TAG: "Ok",
                _0: []
              });
  }
}

var Config$4 = {
  argument_encode: argument_encode$4,
  argument_decode: argument_decode$4,
  client_encode: client_encode$3,
  client_decode: client_decode$3,
  bodyResponse_encode: bodyResponse_encode$1,
  bodyResponse_decode: bodyResponse_decode$1,
  store_encode: store_encode,
  store_decode: store_decode,
  response_encode: response_encode$4,
  response_decode: response_decode$4,
  exec: exec$4
};

var $$Request$4 = Toolkit__Request.Make({
      exec: exec$4
    });

var GetStoresInformations = {
  Config: Config$4,
  $$Request: $$Request$4
};

function argument_encode$5(v) {
  return [
          Curry._1(Identifiers.ClientId.t_encode, v[0]),
          Curry._1(Identifiers.StoreCode.t_encode, v[1])
        ];
}

function argument_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.ClientId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.StoreCode.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function address_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.Lift.t_encode, v.lift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ]
                ]));
}

function address_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              var lift = Spice.optionFromJson(ColiswebApi__V5_Utils.Lift.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
              if (lift.TAG === "Ok") {
                var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            city: city._0,
                            postalCode: postalCode._0,
                            country: country._0,
                            latitude: latitude._0,
                            longitude: longitude._0,
                            lift: lift._0,
                            floor: floor._0
                          }
                        };
                }
                var e = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = lift._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".lift" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function store_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "address",
                    false,
                    address_encode(v.address)
                  ]
                ]));
}

function store_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
      if (storeCode.TAG === "Ok") {
        var address = address_decode(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
        if (address.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    name: name._0,
                    storeCode: storeCode._0,
                    address: address._0
                  }
                };
        }
        var e = address._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".address" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = storeCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeCode" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$5 = store_encode$1;

var response_decode$5 = store_decode$1;

function exec$5(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$5, undefined, baseUrl + "/clients/" + param[0] + "/stores/storeCode/" + param[1]);
}

var Config$5 = {
  argument_encode: argument_encode$5,
  argument_decode: argument_decode$5,
  store_encode: store_encode$1,
  store_decode: store_decode$1,
  address_encode: address_encode,
  address_decode: address_decode,
  response_encode: response_encode$5,
  response_decode: response_decode$5,
  exec: exec$5
};

var $$Request$5 = Toolkit__Request.Make({
      exec: exec$5
    });

var GetStoreAddressByApiCode = {
  Config: Config$5,
  $$Request: $$Request$5
};

var argument_encode$6 = Spice.unitToJson;

var argument_decode$6 = Spice.unitFromJson;

function address_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ],
                  [
                    "lift",
                    false,
                    Spice.optionToJson(ColiswebApi__V5_Utils.Lift.t_encode, v.lift)
                  ],
                  [
                    "floor",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.floor)
                  ]
                ]));
}

function address_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
    if (city.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              var lift = Spice.optionFromJson(ColiswebApi__V5_Utils.Lift.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "lift"), null));
              if (lift.TAG === "Ok") {
                var floor = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "floor"), null));
                if (floor.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            line1: line1._0,
                            city: city._0,
                            postalCode: postalCode._0,
                            country: country._0,
                            latitude: latitude._0,
                            longitude: longitude._0,
                            lift: lift._0,
                            floor: floor._0
                          }
                        };
                }
                var e = floor._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".floor" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = lift._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".lift" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = city._0;
    return {
            TAG: "Error",
            _0: {
              path: ".city" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function store_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeCode",
                    false,
                    Spice.optionToJson(Identifiers.StoreCode.t_encode, v.storeCode)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "address",
                    false,
                    address_encode$1(v.address)
                  ]
                ]));
}

function store_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
  if (storeId.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var storeCode = Spice.optionFromJson(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
      if (storeCode.TAG === "Ok") {
        var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
        if (name.TAG === "Ok") {
          var address = address_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
          if (address.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      storeId: storeId._0,
                      clientId: clientId._0,
                      storeCode: storeCode._0,
                      name: name._0,
                      address: address._0
                    }
                  };
          }
          var e = address._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".address" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = name._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".name" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = storeCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".storeCode" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = storeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeId" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function response_encode$6(v) {
  return Spice.arrayToJson(store_encode$2, v);
}

function response_decode$6(v) {
  return Spice.arrayFromJson(store_decode$2, v);
}

function exec$6(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$6, undefined, baseUrl + "/stores/all");
}

var Config$6 = {
  argument_encode: argument_encode$6,
  argument_decode: argument_decode$6,
  store_encode: store_encode$2,
  store_decode: store_decode$2,
  address_encode: address_encode$1,
  address_decode: address_decode$1,
  response_encode: response_encode$6,
  response_decode: response_decode$6,
  exec: exec$6
};

var $$Request$6 = Toolkit__Request.Make({
      exec: exec$6
    });

var GetAllStoresAddresses = {
  Config: Config$6,
  $$Request: $$Request$6
};

function argument_encode$7(v) {
  return [
          Spice.arrayToJson(Identifiers.StoreId.t_encode, v[0]),
          Spice.optionToJson(Spice.stringToJson, v[1]),
          Spice.intToJson(v[2]),
          Spice.intToJson(v[3])
        ];
}

function argument_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 4) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var v2 = v[2];
  var v3 = v[3];
  var match = Spice.arrayFromJson(Identifiers.StoreId.t_decode, v0);
  var match$1 = Spice.optionFromJson(Spice.stringFromJson, v1);
  var match$2 = Spice.intFromJson(v2);
  var match$3 = Spice.intFromJson(v3);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      if (match$2.TAG === "Ok") {
        if (match$3.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: [
                    match._0,
                    match$1._0,
                    match$2._0,
                    match$3._0
                  ]
                };
        }
        var e = match$3._0;
        return {
                TAG: "Error",
                _0: {
                  path: "[3]" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = match$2._0;
      return {
              TAG: "Error",
              _0: {
                path: "[2]" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function client_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function client_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function params_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "page",
                    false,
                    Spice.intToJson(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Spice.intToJson(v.pageSize)
                  ],
                  [
                    "nameFilter",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "storeIds",
                    false,
                    Spice.arrayToJson(Identifiers.StoreId.t_encode, v.storeIds)
                  ]
                ]));
}

function params_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var page = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
  if (page.TAG === "Ok") {
    var pageSize = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
    if (pageSize.TAG === "Ok") {
      var nameFilter = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
      if (nameFilter.TAG === "Ok") {
        var storeIds = Spice.arrayFromJson(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeIds"), null));
        if (storeIds.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    page: page._0,
                    pageSize: pageSize._0,
                    nameFilter: nameFilter._0,
                    storeIds: storeIds._0
                  }
                };
        }
        var e = storeIds._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeIds" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = nameFilter._0;
      return {
              TAG: "Error",
              _0: {
                path: ".nameFilter" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = pageSize._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pageSize" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = page._0;
  return {
          TAG: "Error",
          _0: {
            path: ".page" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function store_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        var oldBackOfficeMagicLink = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "oldBackOfficeMagicLink"), null));
        if (oldBackOfficeMagicLink.TAG === "Ok") {
          var client = client_decode$4(Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
          if (client.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      name: name._0,
                      updatedAt: updatedAt._0,
                      oldBackOfficeMagicLink: oldBackOfficeMagicLink._0,
                      client: client._0
                    }
                  };
          }
          var e = client._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".client" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = oldBackOfficeMagicLink._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".oldBackOfficeMagicLink" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function store_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ],
                  [
                    "oldBackOfficeMagicLink",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.oldBackOfficeMagicLink)
                  ],
                  [
                    "client",
                    false,
                    client_encode$4(v.client)
                  ]
                ]));
}

function response_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode$3, v.stores)
                  ],
                  [
                    "storesCount",
                    false,
                    Spice.intToJson(v.storesCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    var storesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "storesCount"), null));
    if (storesCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  stores: stores._0,
                  storesCount: storesCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = storesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$7(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(params_encode({
                            page: param[2],
                            pageSize: param[3],
                            nameFilter: param[1],
                            storeIds: param[0]
                          })), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$7, undefined, baseUrl + "/stores");
}

var Config$7 = {
  argument_encode: argument_encode$7,
  argument_decode: argument_decode$7,
  client_encode: client_encode$4,
  client_decode: client_decode$4,
  params_encode: params_encode,
  params_decode: params_decode,
  response_encode: response_encode$7,
  response_decode: response_decode$7,
  store_encode: store_encode$3,
  store_decode: store_decode$3,
  exec: exec$7
};

var $$Request$7 = Toolkit__Request.Make({
      exec: exec$7
    });

var SearchStoresPaginated = {
  Config: Config$7,
  $$Request: $$Request$7
};

function transporter_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  updatedAt: updatedAt._0
                }
              };
      }
      var e = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function transporter_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function response_encode$8(v) {
  return Spice.arrayToJson(transporter_encode, v);
}

function response_decode$8(v) {
  return Spice.arrayFromJson(transporter_decode, v);
}

function exec$8(param) {
  return Axios.WithResult.post(undefined, {}, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$8, undefined, baseUrl + "/transporters/all");
}

var Config$8 = {
  response_encode: response_encode$8,
  response_decode: response_decode$8,
  transporter_encode: transporter_encode,
  transporter_decode: transporter_decode,
  exec: exec$8
};

var $$Request$8 = Toolkit__Request.Make({
      exec: exec$8
    });

var GetAllTransporters = {
  Config: Config$8,
  $$Request: $$Request$8
};

function body_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterIds = Spice.arrayFromJson(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterIds"), null));
  if (transporterIds.TAG === "Ok") {
    var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
    if (nameFilter.TAG === "Ok") {
      var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
      if (nameOrder.TAG === "Ok") {
        var page = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
        if (page.TAG === "Ok") {
          var pageSize = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
          if (pageSize.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      transporterIds: transporterIds._0,
                      nameFilter: nameFilter._0,
                      nameOrder: nameOrder._0,
                      page: page._0,
                      pageSize: pageSize._0
                    }
                  };
          }
          var e = pageSize._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pageSize" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = page._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".page" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = nameOrder._0;
      return {
              TAG: "Error",
              _0: {
                path: ".nameOrder" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = nameFilter._0;
    return {
            TAG: "Error",
            _0: {
              path: ".nameFilter" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = transporterIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterIds" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function body_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporterIds",
                    false,
                    Spice.arrayToJson(Identifiers.TransporterId.t_encode, v.transporterIds)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.pageSize)
                  ]
                ]));
}

var argument_encode$8 = body_encode$4;

var argument_decode$8 = body_decode$4;

function transporter_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function transporter_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  updatedAt: updatedAt._0
                }
              };
      }
      var e = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$9(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporters",
                    false,
                    Spice.arrayToJson(transporter_encode$1, v.transporters)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "transportersCount",
                    false,
                    Spice.intToJson(v.transportersCount)
                  ]
                ]));
}

function response_decode$9(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporters = Spice.arrayFromJson(transporter_decode$1, Belt_Option.getWithDefault(Js_dict.get(v, "transporters"), null));
  if (transporters.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var transportersCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "transportersCount"), null));
      if (transportersCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  transporters: transporters._0,
                  pagesCount: pagesCount._0,
                  transportersCount: transportersCount._0
                }
              };
      }
      var e = transportersCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".transportersCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = transporters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$9(body) {
  return Axios.WithResult.post(undefined, {}, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(body_encode$4(body)), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$9, undefined, baseUrl + "/transporters");
}

var Config$9 = {
  argument_encode: argument_encode$8,
  argument_decode: argument_decode$8,
  body_encode: body_encode$4,
  body_decode: body_decode$4,
  response_encode: response_encode$9,
  response_decode: response_decode$9,
  transporter_encode: transporter_encode$1,
  transporter_decode: transporter_decode$1,
  exec: exec$9
};

var $$Request$9 = Toolkit__Request.Make({
      exec: exec$9
    });

var SearchTransportersPaginated = {
  Config: Config$9,
  $$Request: $$Request$9
};

function body_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "transporterIds",
                    false,
                    Spice.arrayToJson(Identifiers.TransporterId.t_encode, v.transporterIds)
                  ]]));
}

function body_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterIds = Spice.arrayFromJson(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterIds"), null));
  if (transporterIds.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              transporterIds: transporterIds._0
            }
          };
  }
  var e = transporterIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterIds" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function argument_encode$9(v) {
  return Spice.arrayToJson(Identifiers.TransporterId.t_encode, v);
}

function argument_decode$9(v) {
  return Spice.arrayFromJson(Identifiers.TransporterId.t_decode, v);
}

function transporter_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var updatedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "updatedAt"), null));
      if (updatedAt.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  updatedAt: updatedAt._0
                }
              };
      }
      var e = updatedAt._0;
      return {
              TAG: "Error",
              _0: {
                path: ".updatedAt" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function transporter_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "updatedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.updatedAt)
                  ]
                ]));
}

function response_encode$10(v) {
  return Spice.arrayToJson(transporter_encode$2, v);
}

function response_decode$10(v) {
  return Spice.arrayFromJson(transporter_decode$2, v);
}

function exec$10(transporterIds) {
  return Axios.WithResult.post(undefined, body_encode$5({
                  transporterIds: transporterIds
                }), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$10, undefined, baseUrl + "/transporters/all");
}

var Config$10 = {
  body_encode: body_encode$5,
  body_decode: body_decode$5,
  argument_encode: argument_encode$9,
  argument_decode: argument_decode$9,
  response_encode: response_encode$10,
  response_decode: response_decode$10,
  transporter_encode: transporter_encode$2,
  transporter_decode: transporter_decode$2,
  exec: exec$10
};

var $$Request$10 = Toolkit__Request.Make({
      exec: exec$10
    });

var GetTransportersInformations = {
  Config: Config$10,
  $$Request: $$Request$10
};

function store_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function store_encode$4(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function response_encode$11(v) {
  return Spice.arrayToJson(store_encode$4, v);
}

function response_decode$11(v) {
  return Spice.arrayFromJson(store_decode$4, v);
}

function exec$11(clientId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$11, undefined, baseUrl + "/clients/" + clientId + "/stores");
}

var Config$11 = {
  response_encode: response_encode$11,
  response_decode: response_decode$11,
  store_encode: store_encode$4,
  store_decode: store_decode$4,
  exec: exec$11
};

var $$Request$11 = Toolkit__Request.Make({
      exec: exec$11
    });

var GetClientStores = {
  Config: Config$11,
  $$Request: $$Request$11
};

function argument_encode$10(v) {
  return Curry._1(Identifiers.DeliveryId.t_encode, v);
}

function argument_decode$10(v) {
  return Curry._1(Identifiers.DeliveryId.t_decode, v);
}

function transporter_encode$3(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "planning_is_matching",
                    false,
                    Spice.boolToJson(v.planningIsMatching)
                  ],
                  [
                    "dimensions_are_matching",
                    false,
                    Spice.boolToJson(v.dimensionsAreMatching)
                  ],
                  [
                    "skills_constraints_are_matching",
                    false,
                    Spice.boolToJson(v.skillsConstraintsAreMatching)
                  ]
                ]));
}

function transporter_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var planningIsMatching = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "planning_is_matching"), Spice.boolFromJson), {
            TAG: "Ok",
            _0: false
          });
      if (planningIsMatching.TAG === "Ok") {
        var dimensionsAreMatching = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "dimensions_are_matching"), Spice.boolFromJson), {
              TAG: "Ok",
              _0: false
            });
        if (dimensionsAreMatching.TAG === "Ok") {
          var skillsConstraintsAreMatching = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "skills_constraints_are_matching"), Spice.boolFromJson), {
                TAG: "Ok",
                _0: false
              });
          if (skillsConstraintsAreMatching.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      name: name._0,
                      planningIsMatching: planningIsMatching._0,
                      dimensionsAreMatching: dimensionsAreMatching._0,
                      skillsConstraintsAreMatching: skillsConstraintsAreMatching._0
                    }
                  };
          }
          var e = skillsConstraintsAreMatching._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "." + ("skills_constraints_are_matching" + e.path),
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = dimensionsAreMatching._0;
        return {
                TAG: "Error",
                _0: {
                  path: "." + ("dimensions_are_matching" + e$1.path),
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = planningIsMatching._0;
      return {
              TAG: "Error",
              _0: {
                path: "." + ("planning_is_matching" + e$2.path),
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function result_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporter",
                    false,
                    transporter_encode$3(v.transporter)
                  ],
                  [
                    "priority",
                    false,
                    Spice.optionToJson(Spice.intToJson, v.priority)
                  ]
                ]));
}

function result_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporter = transporter_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "transporter"), null));
  if (transporter.TAG === "Ok") {
    var priority = Spice.optionFromJson(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priority"), null));
    if (priority.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                transporter: transporter._0,
                priority: priority._0
              }
            };
    }
    var e = priority._0;
    return {
            TAG: "Error",
            _0: {
              path: ".priority" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = transporter._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporter" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$12(v) {
  return Spice.arrayToJson(result_encode, v);
}

function response_decode$12(v) {
  return Spice.arrayFromJson(result_decode, v);
}

function exec$12(deliveryId) {
  return $$Promise.mapOk(Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$12, undefined, baseUrl + "/deliveries/" + deliveryId + "/eligibleTransporters"), (function (data) {
                return Js_array.sortInPlaceWith((function (a, b) {
                              var a$1 = Belt_Option.getWithDefault(a.priority, 999);
                              var b$1 = Belt_Option.getWithDefault(b.priority, 999);
                              if (a$1 > b$1) {
                                return 1;
                              } else if (a$1 < b$1) {
                                return -1;
                              } else {
                                return 0;
                              }
                            }), data);
              }));
}

var Config$12 = {
  argument_encode: argument_encode$10,
  argument_decode: argument_decode$10,
  response_encode: response_encode$12,
  response_decode: response_decode$12,
  result_encode: result_encode,
  result_decode: result_decode,
  transporter_encode: transporter_encode$3,
  transporter_decode: transporter_decode$3,
  exec: exec$12
};

var $$Request$12 = Toolkit__Request.Make({
      exec: exec$12
    });

var GetEligibleTransporters = {
  Config: Config$12,
  $$Request: $$Request$12
};

function filters_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterNameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "transporterNameFilter"), null));
  if (transporterNameFilter.TAG === "Ok") {
    var pickupRadialDistanceSort = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "pickupRadialDistanceSort"), null));
    if (pickupRadialDistanceSort.TAG === "Ok") {
      var shippingRadialDistanceSort = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "shippingRadialDistanceSort"), null));
      if (shippingRadialDistanceSort.TAG === "Ok") {
        var page = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
        if (page.TAG === "Ok") {
          var pageSize = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
          if (pageSize.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      transporterNameFilter: transporterNameFilter._0,
                      pickupRadialDistanceSort: pickupRadialDistanceSort._0,
                      shippingRadialDistanceSort: shippingRadialDistanceSort._0,
                      page: page._0,
                      pageSize: pageSize._0
                    }
                  };
          }
          var e = pageSize._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".pageSize" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = page._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".page" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = shippingRadialDistanceSort._0;
      return {
              TAG: "Error",
              _0: {
                path: ".shippingRadialDistanceSort" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = pickupRadialDistanceSort._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pickupRadialDistanceSort" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = transporterNameFilter._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterNameFilter" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function filters_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporterNameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.transporterNameFilter)
                  ],
                  [
                    "pickupRadialDistanceSort",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.pickupRadialDistanceSort)
                  ],
                  [
                    "shippingRadialDistanceSort",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.shippingRadialDistanceSort)
                  ],
                  [
                    "page",
                    false,
                    Spice.intToJson(v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Spice.intToJson(v.pageSize)
                  ]
                ]));
}

function argument_encode$11(v) {
  return [
          Curry._1(Identifiers.DeliveryId.t_encode, v[0]),
          filters_encode$1(v[1])
        ];
}

function argument_decode$11(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.DeliveryId.t_decode, v0);
  var match$1 = filters_decode$1(v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function response_encode$13(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporters",
                    false,
                    Spice.arrayToJson(transporter_encode$3, v.transporters)
                  ],
                  [
                    "transportersCount",
                    false,
                    Spice.intToJson(v.transportersCount)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ]
                ]));
}

function response_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporters = Spice.arrayFromJson(transporter_decode$3, Belt_Option.getWithDefault(Js_dict.get(v, "transporters"), null));
  if (transporters.TAG === "Ok") {
    var transportersCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "transportersCount"), null));
    if (transportersCount.TAG === "Ok") {
      var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
      if (pagesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  transporters: transporters._0,
                  transportersCount: transportersCount._0,
                  pagesCount: pagesCount._0
                }
              };
      }
      var e = pagesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pagesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = transportersCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".transportersCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = transporters._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporters" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$13(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(filters_encode$1(param[1])), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$13, undefined, baseUrl + "/deliveries/" + param[0] + "/nonEligibleTransporters");
}

var Config$13 = {
  argument_encode: argument_encode$11,
  argument_decode: argument_decode$11,
  filters_encode: filters_encode$1,
  filters_decode: filters_decode$1,
  response_encode: response_encode$13,
  response_decode: response_decode$13,
  exec: exec$13
};

var $$Request$13 = Toolkit__Request.Make({
      exec: exec$13
    });

var GetNonEligibleTransporters = {
  Config: Config$13,
  $$Request: $$Request$13
};

function argument_encode$12(v) {
  return [
          Curry._1(Identifiers.DeliveryId.t_encode, v[0]),
          Spice.arrayToJson(Identifiers.TransporterId.t_encode, v[1])
        ];
}

function argument_decode$12(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.DeliveryId.t_decode, v0);
  var match$1 = Spice.arrayFromJson(Identifiers.TransporterId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function distancesKmFromTradingArea_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var toPickup = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "toPickup"), null));
  if (toPickup.TAG === "Ok") {
    var toDropOff = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "toDropOff"), null));
    if (toDropOff.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                toPickup: toPickup._0,
                toDropOff: toDropOff._0
              }
            };
    }
    var e = toDropOff._0;
    return {
            TAG: "Error",
            _0: {
              path: ".toDropOff" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = toPickup._0;
  return {
          TAG: "Error",
          _0: {
            path: ".toPickup" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function distancesKmFromTradingArea_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "toPickup",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.toPickup)
                  ],
                  [
                    "toDropOff",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.toDropOff)
                  ]
                ]));
}

function result_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var transporterId = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "transporterId"), null));
  if (transporterId.TAG === "Ok") {
    var priceEur = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "priceEur"), null));
    if (priceEur.TAG === "Ok") {
      var distancesKmFromTradingArea = distancesKmFromTradingArea_decode(Belt_Option.getWithDefault(Js_dict.get(v, "distancesKmFromTradingArea"), null));
      if (distancesKmFromTradingArea.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  transporterId: transporterId._0,
                  priceEur: priceEur._0,
                  distancesKmFromTradingArea: distancesKmFromTradingArea._0
                }
              };
      }
      var e = distancesKmFromTradingArea._0;
      return {
              TAG: "Error",
              _0: {
                path: ".distancesKmFromTradingArea" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = priceEur._0;
    return {
            TAG: "Error",
            _0: {
              path: ".priceEur" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = transporterId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".transporterId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function result_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "transporterId",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.transporterId)
                  ],
                  [
                    "priceEur",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.priceEur)
                  ],
                  [
                    "distancesKmFromTradingArea",
                    false,
                    distancesKmFromTradingArea_encode(v.distancesKmFromTradingArea)
                  ]
                ]));
}

function response_encode$14(v) {
  return Spice.arrayToJson(result_encode$1, v);
}

function response_decode$14(v) {
  return Spice.arrayFromJson(result_decode$1, v);
}

function exec$14(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, {
                    transporterIds: param[1]
                  }, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$14, undefined, baseUrl + "/deliveries/" + param[0] + "/eligibilityDetails");
}

var Config$14 = {
  argument_encode: argument_encode$12,
  argument_decode: argument_decode$12,
  response_encode: response_encode$14,
  response_decode: response_decode$14,
  result_encode: result_encode$1,
  result_decode: result_decode$1,
  distancesKmFromTradingArea_encode: distancesKmFromTradingArea_encode,
  distancesKmFromTradingArea_decode: distancesKmFromTradingArea_decode,
  exec: exec$14
};

var $$Request$14 = Toolkit__Request.Make({
      exec: exec$14
    });

var GetTransporterEligibilityDetails = {
  Config: Config$14,
  $$Request: $$Request$14
};

function argument_encode$13(v) {
  return [
          Curry._1(Identifiers.DeliveryId.t_encode, v[0]),
          Curry._1(Identifiers.TransporterId.t_encode, v[1])
        ];
}

function argument_decode$13(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.DeliveryId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.TransporterId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function carrier_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.CarrierId.t_encode, v.id)
                  ],
                  [
                    "firstName",
                    false,
                    Spice.stringToJson(v.firstName)
                  ],
                  [
                    "lastName",
                    false,
                    Spice.stringToJson(v.lastName)
                  ],
                  [
                    "phone",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone)
                  ]
                ]));
}

function carrier_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.CarrierId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var firstName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "firstName"), null));
    if (firstName.TAG === "Ok") {
      var lastName = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "lastName"), null));
      if (lastName.TAG === "Ok") {
        var phone = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone"), null));
        if (phone.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    id: id._0,
                    firstName: firstName._0,
                    lastName: lastName._0,
                    phone: phone._0
                  }
                };
        }
        var e = phone._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".phone" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = lastName._0;
      return {
              TAG: "Error",
              _0: {
                path: ".lastName" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = firstName._0;
    return {
            TAG: "Error",
            _0: {
              path: ".firstName" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function address_encode$2(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "line1",
                    false,
                    Spice.stringToJson(v.line1)
                  ],
                  [
                    "line2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.line2)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "country",
                    false,
                    Spice.stringToJson(v.country)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.optionToJson(Spice.floatToJson, v.longitude)
                  ]
                ]));
}

function address_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var line1 = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "line1"), null));
  if (line1.TAG === "Ok") {
    var line2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "line2"), null));
    if (line2.TAG === "Ok") {
      var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
      if (postalCode.TAG === "Ok") {
        var country = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "country"), null));
        if (country.TAG === "Ok") {
          var latitude = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
          if (latitude.TAG === "Ok") {
            var longitude = Spice.optionFromJson(Spice.floatFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
            if (longitude.TAG === "Ok") {
              return {
                      TAG: "Ok",
                      _0: {
                        line1: line1._0,
                        line2: line2._0,
                        postalCode: postalCode._0,
                        country: country._0,
                        latitude: latitude._0,
                        longitude: longitude._0
                      }
                    };
            }
            var e = longitude._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".longitude" + e.path,
                      message: e.message,
                      value: e.value
                    }
                  };
          }
          var e$1 = latitude._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".latitude" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
        }
        var e$2 = country._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".country" + e$2.path,
                  message: e$2.message,
                  value: e$2.value
                }
              };
      }
      var e$3 = postalCode._0;
      return {
              TAG: "Error",
              _0: {
                path: ".postalCode" + e$3.path,
                message: e$3.message,
                value: e$3.value
              }
            };
    }
    var e$4 = line2._0;
    return {
            TAG: "Error",
            _0: {
              path: ".line2" + e$4.path,
              message: e$4.message,
              value: e$4.value
            }
          };
  }
  var e$5 = line1._0;
  return {
          TAG: "Error",
          _0: {
            path: ".line1" + e$5.path,
            message: e$5.message,
            value: e$5.value
          }
        };
}

function response_encode$15(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.TransporterId.t_encode, v.id)
                  ],
                  [
                    "phone1",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone1)
                  ],
                  [
                    "phone2",
                    false,
                    Spice.optionToJson(Spice.stringToJson, v.phone2)
                  ],
                  [
                    "carriers",
                    false,
                    Spice.arrayToJson(carrier_encode, v.carriers)
                  ],
                  [
                    "address",
                    false,
                    address_encode$2(v.address)
                  ]
                ]));
}

function response_decode$15(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.TransporterId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var phone1 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone1"), null));
    if (phone1.TAG === "Ok") {
      var phone2 = Spice.optionFromJson(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "phone2"), null));
      if (phone2.TAG === "Ok") {
        var carriers = Spice.arrayFromJson(carrier_decode, Belt_Option.getWithDefault(Js_dict.get(v, "carriers"), null));
        if (carriers.TAG === "Ok") {
          var address = address_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "address"), null));
          if (address.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      id: id._0,
                      phone1: phone1._0,
                      phone2: phone2._0,
                      carriers: carriers._0,
                      address: address._0
                    }
                  };
          }
          var e = address._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".address" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = carriers._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".carriers" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = phone2._0;
      return {
              TAG: "Error",
              _0: {
                path: ".phone2" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = phone1._0;
    return {
            TAG: "Error",
            _0: {
              path: ".phone1" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function exec$15(param) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$15, undefined, baseUrl + "/deliveries/" + param[0] + "/transporters/" + param[1] + "/details");
}

var Config$15 = {
  argument_encode: argument_encode$13,
  argument_decode: argument_decode$13,
  carrier_encode: carrier_encode,
  carrier_decode: carrier_decode,
  response_encode: response_encode$15,
  response_decode: response_decode$15,
  address_encode: address_encode$2,
  address_decode: address_decode$2,
  exec: exec$15
};

var $$Request$15 = Toolkit__Request.Make({
      exec: exec$15
    });

var GetEligibleTransporterDetails = {
  Config: Config$15,
  $$Request: $$Request$15
};

function t_encode$1(v) {
  switch (v) {
    case "canceled_delivery_cant_be_assigned" :
        return "canceled_delivery_cant_be_assigned";
    case "delivery_not_found" :
        return "delivery_not_found";
    case "carrier_not_found" :
        return "carrier_not_found";
    case "unknown" :
        return "unknown";
    
  }
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("canceled_delivery_cant_be_assigned" === v) {
          return {
                  TAG: "Ok",
                  _0: "canceled_delivery_cant_be_assigned"
                };
        } else if ("delivery_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "delivery_not_found"
                };
        } else if ("carrier_not_found" === v) {
          return {
                  TAG: "Ok",
                  _0: "carrier_not_found"
                };
        } else if ("unknown" === v) {
          return {
                  TAG: "Ok",
                  _0: "unknown"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var $$Error$1 = {
  t_encode: t_encode$1,
  t_decode: t_decode$1
};

function argument_encode$14(v) {
  return [
          Curry._1(Identifiers.DeliveryId.t_encode, v[0]),
          Curry._1(Identifiers.CarrierId.t_encode, v[1])
        ];
}

function argument_decode$14(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a tuple", v);
  }
  if (v.length !== 2) {
    return Spice.error(undefined, "Incorrect cardinality", v);
  }
  var v0 = v[0];
  var v1 = v[1];
  var match = Curry._1(Identifiers.DeliveryId.t_decode, v0);
  var match$1 = Curry._1(Identifiers.CarrierId.t_decode, v1);
  if (match.TAG === "Ok") {
    if (match$1.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: [
                match._0,
                match$1._0
              ]
            };
    }
    var e = match$1._0;
    return {
            TAG: "Error",
            _0: {
              path: "[1]" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = match._0;
  return {
          TAG: "Error",
          _0: {
            path: "[0]" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

var response_encode$16 = Spice.unitToJson;

var response_decode$16 = Spice.unitFromJson;

function errorResponse_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "type",
                    false,
                    t_encode$1(v.type_)
                  ]]));
}

function errorResponse_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = t_decode$1(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              type_: type_._0
            }
          };
  }
  var e = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e.path),
            message: e.message,
            value: e.value
          }
        };
}

function exec$16(param) {
  return Axios.WithResult.post(undefined, {
              carrierId: param[1]
            }, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$16, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var v = errorResponse_decode$1(match.data);
                if (v.TAG === "Ok") {
                  return {
                          NAME: "custom",
                          VAL: v._0.type_
                        };
                } else {
                  return {
                          NAME: "decodeError",
                          VAL: [
                            config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            v._0
                          ]
                        };
                }
              }), baseUrl + "/deliveries/" + param[0] + "/manuallyAssign");
}

var Config$16 = {
  argument_encode: argument_encode$14,
  argument_decode: argument_decode$14,
  response_encode: response_encode$16,
  response_decode: response_decode$16,
  errorResponse_encode: errorResponse_encode$1,
  errorResponse_decode: errorResponse_decode$1,
  exec: exec$16
};

var $$Request$16 = Toolkit__Request.Make({
      exec: exec$16
    });

var PostManuallyAssignCourier = {
  $$Error: $$Error$1,
  Config: Config$16,
  $$Request: $$Request$16
};

function body_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var zoneIds = Spice.arrayFromJson(Identifiers.ZoneId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "zoneIds"), null));
  if (zoneIds.TAG === "Ok") {
    var page = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "page"), null));
    if (page.TAG === "Ok") {
      var pageSize = Toolkit__Decoders.$$Option.t_decode(Spice.intFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "pageSize"), null));
      if (pageSize.TAG === "Ok") {
        var nameFilter = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "nameFilter"), null));
        if (nameFilter.TAG === "Ok") {
          var nameOrder = Toolkit__Decoders.$$Option.t_decode(ColiswebApi__Utils.SortOrder.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "nameOrder"), null));
          if (nameOrder.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      zoneIds: zoneIds._0,
                      page: page._0,
                      pageSize: pageSize._0,
                      nameFilter: nameFilter._0,
                      nameOrder: nameOrder._0
                    }
                  };
          }
          var e = nameOrder._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".nameOrder" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
        }
        var e$1 = nameFilter._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".nameFilter" + e$1.path,
                  message: e$1.message,
                  value: e$1.value
                }
              };
      }
      var e$2 = pageSize._0;
      return {
              TAG: "Error",
              _0: {
                path: ".pageSize" + e$2.path,
                message: e$2.message,
                value: e$2.value
              }
            };
    }
    var e$3 = page._0;
    return {
            TAG: "Error",
            _0: {
              path: ".page" + e$3.path,
              message: e$3.message,
              value: e$3.value
            }
          };
  }
  var e$4 = zoneIds._0;
  return {
          TAG: "Error",
          _0: {
            path: ".zoneIds" + e$4.path,
            message: e$4.message,
            value: e$4.value
          }
        };
}

function body_encode$6(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "zoneIds",
                    false,
                    Spice.arrayToJson(Identifiers.ZoneId.t_encode, v.zoneIds)
                  ],
                  [
                    "page",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.page)
                  ],
                  [
                    "pageSize",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.intToJson, v.pageSize)
                  ],
                  [
                    "nameFilter",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.nameFilter)
                  ],
                  [
                    "nameOrder",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(ColiswebApi__Utils.SortOrder.t_encode, v.nameOrder)
                  ]
                ]));
}

var argument_encode$15 = body_encode$6;

var argument_decode$15 = body_decode$6;

function zoneType_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ZoneTypeId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]
                ]));
}

function zoneType_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ZoneTypeId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                id: id._0,
                name: name._0
              }
            };
    }
    var e = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function zone_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "id",
                    false,
                    Curry._1(Identifiers.ZoneId.t_encode, v.id)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "zoneType",
                    false,
                    Spice.optionToJson(zoneType_encode, v.zoneType)
                  ]
                ]));
}

function zone_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var id = Curry._1(Identifiers.ZoneId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "id"), null));
  if (id.TAG === "Ok") {
    var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
    if (name.TAG === "Ok") {
      var zoneType = Spice.optionFromJson(zoneType_decode, Belt_Option.getWithDefault(Js_dict.get(v, "zoneType"), null));
      if (zoneType.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  id: id._0,
                  name: name._0,
                  zoneType: zoneType._0
                }
              };
      }
      var e = zoneType._0;
      return {
              TAG: "Error",
              _0: {
                path: ".zoneType" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = name._0;
    return {
            TAG: "Error",
            _0: {
              path: ".name" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = id._0;
  return {
          TAG: "Error",
          _0: {
            path: ".id" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function response_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "zones",
                    false,
                    Spice.arrayToJson(zone_encode, v.zones)
                  ],
                  [
                    "pagesCount",
                    false,
                    Spice.intToJson(v.pagesCount)
                  ],
                  [
                    "zonesCount",
                    false,
                    Spice.intToJson(v.zonesCount)
                  ]
                ]));
}

function response_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var zones = Spice.arrayFromJson(zone_decode, Belt_Option.getWithDefault(Js_dict.get(v, "zones"), null));
  if (zones.TAG === "Ok") {
    var pagesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "pagesCount"), null));
    if (pagesCount.TAG === "Ok") {
      var zonesCount = Spice.intFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "zonesCount"), null));
      if (zonesCount.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  zones: zones._0,
                  pagesCount: pagesCount._0,
                  zonesCount: zonesCount._0
                }
              };
      }
      var e = zonesCount._0;
      return {
              TAG: "Error",
              _0: {
                path: ".zonesCount" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = pagesCount._0;
    return {
            TAG: "Error",
            _0: {
              path: ".pagesCount" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = zones._0;
  return {
          TAG: "Error",
          _0: {
            path: ".zones" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function exec$17(body) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, Caml_option.some(body_encode$6(body)), undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$17, undefined, baseUrl + "/zones");
}

var Config$17 = {
  argument_encode: argument_encode$15,
  argument_decode: argument_decode$15,
  body_encode: body_encode$6,
  body_decode: body_decode$6,
  zoneType_encode: zoneType_encode,
  zoneType_decode: zoneType_decode,
  response_encode: response_encode$17,
  response_decode: response_decode$17,
  zone_encode: zone_encode,
  zone_decode: zone_decode,
  exec: exec$17
};

var $$Request$17 = Toolkit__Request.Make({
      exec: exec$17
    });

var GetZonesPaginated = {
  Config: Config$17,
  $$Request: $$Request$17
};

function t_encode$2(v) {
  switch (v) {
    case "super_admin" :
        return "super_admin";
    case "tech_admin" :
        return "tech_admin";
    case "transporter_pole_admin" :
        return "transporter_pole_admin";
    case "hub_handler" :
        return "hub_handler";
    
  }
}

function t_decode$2(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("super_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "super_admin"
                };
        } else if ("tech_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "tech_admin"
                };
        } else if ("transporter_pole_admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "transporter_pole_admin"
                };
        } else if ("hub_handler" === v) {
          return {
                  TAG: "Ok",
                  _0: "hub_handler"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString = t_encode$2;

var Role = {
  t_encode: t_encode$2,
  t_decode: t_decode$2,
  toString: toString
};

function argument_encode$16(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "email",
                    false,
                    Spice.stringToJson(v.email)
                  ],
                  [
                    "password",
                    false,
                    Spice.stringToJson(v.password)
                  ],
                  [
                    "role",
                    false,
                    t_encode$2(v.role)
                  ],
                  [
                    "hubId",
                    false,
                    Spice.optionToJson(Identifiers.HubId.t_encode, v.hubId)
                  ]
                ]));
}

function argument_decode$16(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var email = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "email"), null));
  if (email.TAG === "Ok") {
    var password = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "password"), null));
    if (password.TAG === "Ok") {
      var role = t_decode$2(Belt_Option.getWithDefault(Js_dict.get(v, "role"), null));
      if (role.TAG === "Ok") {
        var hubId = Spice.optionFromJson(Identifiers.HubId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubId"), null));
        if (hubId.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    email: email._0,
                    password: password._0,
                    role: role._0,
                    hubId: hubId._0
                  }
                };
        }
        var e = hubId._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".hubId" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = role._0;
      return {
              TAG: "Error",
              _0: {
                path: ".role" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = password._0;
    return {
            TAG: "Error",
            _0: {
              path: ".password" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = email._0;
  return {
          TAG: "Error",
          _0: {
            path: ".email" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

var response_encode$18 = Spice.unitToJson;

var response_decode$18 = Spice.unitFromJson;

function exec$18(payload) {
  return Axios.WithResult.post(undefined, argument_encode$16(payload), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$18, (function (error) {
                var match = error.response;
                if (match === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                if (match.status === 409) {
                  return {
                          NAME: "custom",
                          VAL: "EmailNotAvailable"
                        };
                }
                var errorMessage = match.data.error;
                if (errorMessage === undefined) {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
                var errorMessage$1 = Caml_option.valFromOption(errorMessage);
                if (errorMessage$1.includes("too short")) {
                  return {
                          NAME: "custom",
                          VAL: "PasswordTooShort"
                        };
                } else if (errorMessage$1.includes("super_admin not authorized to create tech_admin")) {
                  return {
                          NAME: "custom",
                          VAL: "SuperAdminCantCreateTechAdmin"
                        };
                } else {
                  return {
                          NAME: "default",
                          VAL: error
                        };
                }
              }), baseUrl + "/users");
}

var Config$18 = {
  argument_encode: argument_encode$16,
  argument_decode: argument_decode$16,
  response_encode: response_encode$18,
  response_decode: response_decode$18,
  exec: exec$18
};

var $$Request$18 = Toolkit__Request.Make({
      exec: exec$18
    });

var CreateUser = {
  Role: Role,
  Config: Config$18,
  $$Request: $$Request$18
};

function response_encode$19(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "totalPrice",
                    false,
                    Spice.floatToJson(v.totalPrice)
                  ],
                  [
                    "deliveriesWithoutPrice",
                    false,
                    Spice.arrayToJson(Identifiers.DeliveryId.t_encode, v.deliveriesWithoutPrice)
                  ]
                ]));
}

function response_decode$19(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var totalPrice = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "totalPrice"), null));
  if (totalPrice.TAG === "Ok") {
    var deliveriesWithoutPrice = Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(v, "deliveriesWithoutPrice"), (function (param) {
                return Spice.arrayFromJson(Identifiers.DeliveryId.t_decode, param);
              })), {
          TAG: "Ok",
          _0: []
        });
    if (deliveriesWithoutPrice.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                totalPrice: totalPrice._0,
                deliveriesWithoutPrice: deliveriesWithoutPrice._0
              }
            };
    }
    var e = deliveriesWithoutPrice._0;
    return {
            TAG: "Error",
            _0: {
              path: ".deliveriesWithoutPrice" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = totalPrice._0;
  return {
          TAG: "Error",
          _0: {
            path: ".totalPrice" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function exec$19(routeId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$19, undefined, baseUrl + "/routes/" + encodeURIComponent(routeId) + "/computePrice");
}

var Config$19 = {
  response_encode: response_encode$19,
  response_decode: response_decode$19,
  exec: exec$19
};

var $$Request$19 = Toolkit__Request.Make({
      exec: exec$19
    });

var GetRoutePrice = {
  Config: Config$19,
  $$Request: $$Request$19
};

function reference_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]
                ]));
}

function reference_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
    if (storeCode.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                clientId: clientId._0,
                storeCode: storeCode._0
              }
            };
    }
    var e = storeCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeCode" + e.path,
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function argument_encode$17(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "references",
                    false,
                    Spice.arrayToJson(reference_encode, v.references)
                  ]]));
}

function argument_decode$17(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var references = Spice.arrayFromJson(reference_decode, Belt_Option.getWithDefault(Js_dict.get(v, "references"), null));
  if (references.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              references: references._0
            }
          };
  }
  var e = references._0;
  return {
          TAG: "Error",
          _0: {
            path: ".references" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function store_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "storeCode",
                    false,
                    Curry._1(Identifiers.StoreCode.t_encode, v.storeCode)
                  ]
                ]));
}

function store_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
      if (name.TAG === "Ok") {
        var storeCode = Curry._1(Identifiers.StoreCode.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeCode"), null));
        if (storeCode.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    clientId: clientId._0,
                    storeId: storeId._0,
                    name: name._0,
                    storeCode: storeCode._0
                  }
                };
        }
        var e = storeCode._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".storeCode" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = name._0;
      return {
              TAG: "Error",
              _0: {
                path: ".name" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$20(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "stores",
                    false,
                    Spice.arrayToJson(store_encode$5, v.stores)
                  ]]));
}

function response_decode$20(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var stores = Spice.arrayFromJson(store_decode$5, Belt_Option.getWithDefault(Js_dict.get(v, "stores"), null));
  if (stores.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              stores: stores._0
            }
          };
  }
  var e = stores._0;
  return {
          TAG: "Error",
          _0: {
            path: ".stores" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$20(params) {
  return Axios.WithResult.post(undefined, argument_encode$17(params), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$20, undefined, baseUrl + "/stores/list");
}

var Config$20 = {
  reference_encode: reference_encode,
  reference_decode: reference_decode,
  argument_encode: argument_encode$17,
  argument_decode: argument_decode$17,
  store_encode: store_encode$5,
  store_decode: store_decode$5,
  response_encode: response_encode$20,
  response_decode: response_decode$20,
  exec: exec$20
};

var $$Request$20 = Toolkit__Request.Make({
      exec: exec$20
    });

var GetStoresListByStoreCode = {
  Config: Config$20,
  $$Request: $$Request$20
};

function t_encode$3(v) {
  if (v === "ref1") {
    return "ref1";
  } else {
    return "all";
  }
}

function t_decode$3(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("ref1" === v) {
          return {
                  TAG: "Ok",
                  _0: "ref1"
                };
        } else if ("all" === v) {
          return {
                  TAG: "Ok",
                  _0: "all"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var ReferenceScope = {
  t_encode: t_encode$3,
  t_decode: t_decode$3
};

function argument_encode$18(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "reference",
                    false,
                    Spice.stringToJson(v.reference)
                  ],
                  [
                    "referenceScope",
                    false,
                    t_encode$3(v.referenceScope)
                  ],
                  [
                    "clientId",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Identifiers.ClientId.t_encode, v.clientId)
                  ]
                ]));
}

function argument_decode$18(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var reference = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "reference"), null));
  if (reference.TAG === "Ok") {
    var referenceScope = t_decode$3(Belt_Option.getWithDefault(Js_dict.get(v, "referenceScope"), null));
    if (referenceScope.TAG === "Ok") {
      var clientId = Toolkit__Decoders.$$Option.t_decode(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  reference: reference._0,
                  referenceScope: referenceScope._0,
                  clientId: clientId._0
                }
              };
      }
      var e = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = referenceScope._0;
    return {
            TAG: "Error",
            _0: {
              path: ".referenceScope" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = reference._0;
  return {
          TAG: "Error",
          _0: {
            path: ".reference" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function owner_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function owner_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function client_encode$5(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ]]));
}

function client_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              name: name._0
            }
          };
  }
  var e = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function point_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "name",
                    false,
                    Spice.stringToJson(v.name)
                  ],
                  [
                    "postalCode",
                    false,
                    Spice.stringToJson(v.postalCode)
                  ],
                  [
                    "city",
                    false,
                    Spice.stringToJson(v.city)
                  ]
                ]));
}

function point_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var name = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "name"), null));
  if (name.TAG === "Ok") {
    var postalCode = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "postalCode"), null));
    if (postalCode.TAG === "Ok") {
      var city = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "city"), null));
      if (city.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  name: name._0,
                  postalCode: postalCode._0,
                  city: city._0
                }
              };
      }
      var e = city._0;
      return {
              TAG: "Error",
              _0: {
                path: ".city" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = postalCode._0;
    return {
            TAG: "Error",
            _0: {
              path: ".postalCode" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = name._0;
  return {
          TAG: "Error",
          _0: {
            path: ".name" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function delivery_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ],
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.DatetimeTimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "statusProvider",
                    false,
                    ColiswebApi__V5_Shared.StatusProvider.t_encode(v.statusProvider)
                  ],
                  [
                    "globalState",
                    false,
                    ColiswebApi__V5_Utils.GlobalState.t_encode(v.globalState)
                  ],
                  [
                    "client",
                    false,
                    client_encode$5(v.client)
                  ],
                  [
                    "owner",
                    false,
                    owner_encode(v.owner)
                  ],
                  [
                    "pickupPoint",
                    false,
                    point_encode(v.pickupPoint)
                  ],
                  [
                    "shippingPoint",
                    false,
                    point_encode(v.shippingPoint)
                  ]
                ]));
}

function delivery_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    var timeSlot = Toolkit__Decoders.DatetimeTimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
    if (timeSlot.TAG === "Ok") {
      var statusProvider = ColiswebApi__V5_Shared.StatusProvider.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "statusProvider"), null));
      if (statusProvider.TAG === "Ok") {
        var globalState = ColiswebApi__V5_Utils.GlobalState.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "globalState"), null));
        if (globalState.TAG === "Ok") {
          var client = client_decode$5(Belt_Option.getWithDefault(Js_dict.get(v, "client"), null));
          if (client.TAG === "Ok") {
            var owner = owner_decode(Belt_Option.getWithDefault(Js_dict.get(v, "owner"), null));
            if (owner.TAG === "Ok") {
              var pickupPoint = point_decode(Belt_Option.getWithDefault(Js_dict.get(v, "pickupPoint"), null));
              if (pickupPoint.TAG === "Ok") {
                var shippingPoint = point_decode(Belt_Option.getWithDefault(Js_dict.get(v, "shippingPoint"), null));
                if (shippingPoint.TAG === "Ok") {
                  return {
                          TAG: "Ok",
                          _0: {
                            deliveryId: deliveryId._0,
                            timeSlot: timeSlot._0,
                            statusProvider: statusProvider._0,
                            globalState: globalState._0,
                            client: client._0,
                            owner: owner._0,
                            pickupPoint: pickupPoint._0,
                            shippingPoint: shippingPoint._0
                          }
                        };
                }
                var e = shippingPoint._0;
                return {
                        TAG: "Error",
                        _0: {
                          path: ".shippingPoint" + e.path,
                          message: e.message,
                          value: e.value
                        }
                      };
              }
              var e$1 = pickupPoint._0;
              return {
                      TAG: "Error",
                      _0: {
                        path: ".pickupPoint" + e$1.path,
                        message: e$1.message,
                        value: e$1.value
                      }
                    };
            }
            var e$2 = owner._0;
            return {
                    TAG: "Error",
                    _0: {
                      path: ".owner" + e$2.path,
                      message: e$2.message,
                      value: e$2.value
                    }
                  };
          }
          var e$3 = client._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: ".client" + e$3.path,
                    message: e$3.message,
                    value: e$3.value
                  }
                };
        }
        var e$4 = globalState._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".globalState" + e$4.path,
                  message: e$4.message,
                  value: e$4.value
                }
              };
      }
      var e$5 = statusProvider._0;
      return {
              TAG: "Error",
              _0: {
                path: ".statusProvider" + e$5.path,
                message: e$5.message,
                value: e$5.value
              }
            };
    }
    var e$6 = timeSlot._0;
    return {
            TAG: "Error",
            _0: {
              path: ".timeSlot" + e$6.path,
              message: e$6.message,
              value: e$6.value
            }
          };
  }
  var e$7 = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e$7.path,
            message: e$7.message,
            value: e$7.value
          }
        };
}

function response_encode$21(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "deliveries",
                    false,
                    Spice.arrayToJson(delivery_encode, v.deliveries)
                  ]]));
}

function response_decode$21(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveries = Spice.arrayFromJson(delivery_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveries"), null));
  if (deliveries.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              deliveries: deliveries._0
            }
          };
  }
  var e = deliveries._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveries" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$21(params) {
  return Axios.WithResult.post(undefined, argument_encode$18(params), config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$21, undefined, baseUrl + "/deliveries/search");
}

var Config$21 = {
  argument_encode: argument_encode$18,
  argument_decode: argument_decode$18,
  owner_encode: owner_encode,
  owner_decode: owner_decode,
  client_encode: client_encode$5,
  client_decode: client_decode$5,
  point_encode: point_encode,
  point_decode: point_decode,
  delivery_encode: delivery_encode,
  delivery_decode: delivery_decode,
  response_encode: response_encode$21,
  response_decode: response_decode$21,
  exec: exec$21
};

var $$Request$21 = Toolkit__Request.Make({
      exec: exec$21
    });

var SearchDeliveries = {
  ReferenceScope: ReferenceScope,
  Config: Config$21,
  $$Request: $$Request$21
};

function argument_encode$19(v) {
  return Curry._1(Identifiers.RouteId.t_encode, v);
}

function argument_decode$19(v) {
  return Curry._1(Identifiers.RouteId.t_decode, v);
}

function location_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "deliveryId",
                    false,
                    Curry._1(Identifiers.DeliveryId.t_encode, v.deliveryId)
                  ],
                  [
                    "geocodedAt",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.geocodedAt)
                  ],
                  [
                    "latitude",
                    false,
                    Spice.floatToJson(v.latitude)
                  ],
                  [
                    "longitude",
                    false,
                    Spice.floatToJson(v.longitude)
                  ]
                ]));
}

function location_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var deliveryId = Curry._1(Identifiers.DeliveryId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "deliveryId"), null));
  if (deliveryId.TAG === "Ok") {
    var geocodedAt = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "geocodedAt"), null));
    if (geocodedAt.TAG === "Ok") {
      var latitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "latitude"), null));
      if (latitude.TAG === "Ok") {
        var longitude = Spice.floatFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "longitude"), null));
        if (longitude.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    deliveryId: deliveryId._0,
                    geocodedAt: geocodedAt._0,
                    latitude: latitude._0,
                    longitude: longitude._0
                  }
                };
        }
        var e = longitude._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".longitude" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = latitude._0;
      return {
              TAG: "Error",
              _0: {
                path: ".latitude" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = geocodedAt._0;
    return {
            TAG: "Error",
            _0: {
              path: ".geocodedAt" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = deliveryId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".deliveryId" + e$3.path,
            message: e$3.message,
            value: e$3.value
          }
        };
}

function response_encode$22(v) {
  return Js_dict.fromArray(Spice.filterOptional([[
                    "locations",
                    false,
                    Spice.arrayToJson(location_encode, v.locations)
                  ]]));
}

function response_decode$22(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var locations = Spice.arrayFromJson(location_decode, Belt_Option.getWithDefault(Js_dict.get(v, "locations"), null));
  if (locations.TAG === "Ok") {
    return {
            TAG: "Ok",
            _0: {
              locations: locations._0
            }
          };
  }
  var e = locations._0;
  return {
          TAG: "Error",
          _0: {
            path: ".locations" + e.path,
            message: e.message,
            value: e.value
          }
        };
}

function exec$22(routeId) {
  return Axios.WithResult.get(undefined, config(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined)(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined), response_decode$22, undefined, baseUrl + "/routes/" + routeId + "/latestLocations");
}

var Config$22 = {
  argument_encode: argument_encode$19,
  argument_decode: argument_decode$19,
  location_encode: location_encode,
  location_decode: location_decode,
  response_encode: response_encode$22,
  response_decode: response_decode$22,
  exec: exec$22
};

var $$Request$22 = Toolkit__Request.Make({
      exec: exec$22
    });

var GetRouteLatestLocations = {
  Config: Config$22,
  $$Request: $$Request$22
};

function body_encode$7(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "timeSlot",
                    false,
                    Toolkit__Decoders.DatetimeTimeSlot.t_encode(v.timeSlot)
                  ],
                  [
                    "modificationCause",
                    false,
                    ColiswebApi__V5_Utils.ModificationCause.t_encode(v.modificationCause)
                  ],
                  [
                    "withCustomerApproval",
                    false,
                    Spice.optionToJson(Spice.boolToJson, v.withCustomerApproval)
                  ]
                ]));
}

function body_decode$7(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var timeSlot = Toolkit__Decoders.DatetimeTimeSlot.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "timeSlot"), null));
  if (timeSlot.TAG === "Ok") {
    var modificationCause = ColiswebApi__V5_Utils.ModificationCause.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "modificationCause"), null));
    if (modificationCause.TAG === "Ok") {
      var withCustomerApproval = Spice.optionFromJson(Spice.boolFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "withCustomerApproval"), null));
      if (withCustomerApproval.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  timeSlot: timeSlot._0,
                  modificationCause: modificationCause._0,
                  withCustomerApproval: withCustomerApproval._0
                }
              };
      }
      var e = withCustomerApproval._0;
      return {
              TAG: "Error",
              _0: {
                path: ".withCustomerApproval" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = modificationCause._0;
    return {
            TAG: "Error",
            _0: {
              path: ".modificationCause" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = timeSlot._0;
  return {
          TAG: "Error",
          _0: {
            path: ".timeSlot" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var response_encode$23 = Spice.unitToJson;

var response_decode$23 = Spice.unitFromJson;

function config$1(param) {
  return {
          kyInstance: kyInstance,
          path: "deliveries/" + param[0] + "/slot",
          requestOptions: {
            method: "PUT",
            json: body_encode$7(param[1])
          }
        };
}

var UpdateDeliverySlot = {
  body_encode: body_encode$7,
  body_decode: body_decode$7,
  response_encode: response_encode$23,
  response_decode: response_decode$23,
  config: config$1
};

export {
  baseUrl ,
  config ,
  RescheduleDelivery ,
  GetClientsPaginated ,
  GetAllClients ,
  GetClientsInformation ,
  GetStoresInformations ,
  GetStoreAddressByApiCode ,
  GetAllStoresAddresses ,
  SearchStoresPaginated ,
  GetAllTransporters ,
  SearchTransportersPaginated ,
  GetTransportersInformations ,
  GetClientStores ,
  GetEligibleTransporters ,
  GetNonEligibleTransporters ,
  GetTransporterEligibilityDetails ,
  GetEligibleTransporterDetails ,
  PostManuallyAssignCourier ,
  GetZonesPaginated ,
  CreateUser ,
  GetRoutePrice ,
  GetStoresListByStoreCode ,
  SearchDeliveries ,
  GetRouteLatestLocations ,
  UpdateDeliverySlot ,
}
/* kyInstance Not a pure module */
