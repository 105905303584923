

import * as Msw from "msw";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../../src/Identifiers.bs.js";
import * as MockOverlay from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/mock/MockOverlay.bs.js";
import * as Browser from "msw/browser";
import * as Caml_splice_call from "../../../../node_modules/rescript/lib/es6/caml_splice_call.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V5_Client from "../../src/v5/ColiswebApi__V5_Client.bs.js";
import * as Toolkit__Primitives_Array from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Array.bs.js";
import * as ApiComponents__DeliveryRouteBanner from "../../src/components/ApiComponents__DeliveryRouteBanner.bs.js";

function generateSignature(param) {
  return {
          url: "https://placehold.co/600x400",
          name: "signature name"
        };
}

var delivery_ref1 = Identifiers.DeliveryReference.make("ref1");

var delivery_ref2 = Identifiers.DeliveryReference.make("ref2");

var delivery_ref3 = Identifiers.DeliveryReference.make("ref3");

var delivery_ref4 = Identifiers.DeliveryReference.make("ref4");

var delivery_salesConsultantName = "salesConsultantName";

var delivery_heftingOptions = {
  isSidewalk: false,
  isInRoom: true,
  isTrailed: false
};

var delivery_createdAt = new Date();

var delivery_distanceKm = 25;

var delivery_skills = [{
    id: "skill",
    tag: "skill-tag",
    description: "skill description",
    isDefault: false
  }];

var delivery_timeSlot = {
  start: new Date(),
  end: new Date()
};

var delivery_pickup = {
  name: "name",
  firstName: "firstName",
  lastName: "lastName",
  postalCode: "postalCode",
  city: "city",
  country: "country",
  line1: "line1",
  line2: "line2",
  comment: "comment",
  floor: 2,
  phone1: "phone1",
  phone2: "phone2",
  coords: undefined,
  lift: "with_lift",
  locationType: "store",
  storeId: "11",
  isDeleted: false,
  hubSlugId: undefined
};

var delivery_shipping = {
  name: "name",
  firstName: "firstName",
  lastName: "lastName",
  postalCode: "postalCode",
  city: "city",
  country: "country",
  line1: "line1",
  line2: "line2",
  comment: "comment",
  floor: 2,
  phone1: "phone1",
  phone2: "phone2",
  coords: {
    latitude: 50,
    longitude: 3
  },
  lift: "with_lift",
  locationType: "consumer",
  storeId: undefined,
  isDeleted: false,
  hubSlugId: undefined
};

var delivery_priceWithoutTaxEur = 2;

var delivery_priceWithTaxEur = 3;

var delivery_customerPriceWithoutTaxEur = 5;

var delivery_customerPriceWithTaxEur = 5;

var delivery_packaging = {
  dimensions: {
    lengthCm: 10,
    widthCm: 10,
    heightCm: 10,
    volumeM3: 10
  },
  weightKg: 10,
  packetsCount: 42,
  description: Curry._2(Toolkit__Primitives_Array.$$Array.makeBy, 70, (function (param) {
            return "packaging description";
          })).join(", "),
  heaviestWeightKg: 10,
  largestVolumeCm3: 11,
  longestPacketLength: 12,
  biggestPacketDimensions: {
    lengthCm: 5,
    widthCm: 10,
    heightCm: 10
  },
  longestPacketDimensions: {
    lengthCm: 5,
    widthCm: 10,
    heightCm: 10
  },
  volumeCm3: 42,
  packetsBarcode: [{
      barcode: Identifiers.Barcode.make("barcode")
    }]
};

var delivery_pickupSignature = {
  url: "https://placehold.co/600x400",
  name: "signature name"
};

var delivery_shippingSignature = {
  url: "https://placehold.co/600x400",
  name: "signature name"
};

var delivery_returnSignature = {
  url: "https://placehold.co/600x400",
  name: "signature name"
};

var delivery_deliveryStateTransitions = [{
    event: "package_withdrawn",
    dateTime: new Date(),
    mobileDateTime: Caml_option.some(new Date()),
    problem: undefined
  }];

var delivery_owner = {
  store: undefined,
  client: {
    name: "Client owner name"
  },
  storeId: "5"
};

var delivery_customerPricesFactor = 10;

var delivery_externalDeliveryCode = "externalDeliveryCode";

var delivery = {
  id: "1",
  uuid: "uuid",
  route: undefined,
  routePlan: undefined,
  ref1: delivery_ref1,
  ref2: delivery_ref2,
  ref3: delivery_ref3,
  ref4: delivery_ref4,
  salesConsultantName: delivery_salesConsultantName,
  heftingOptions: delivery_heftingOptions,
  statusOriginator: "idle",
  statusProvider: "pickedUp",
  createdAt: delivery_createdAt,
  distanceKm: delivery_distanceKm,
  skills: delivery_skills,
  timeSlot: delivery_timeSlot,
  pickup: delivery_pickup,
  shipping: delivery_shipping,
  pickupCustomerSatisfaction: undefined,
  shippingCustomerSatisfaction: undefined,
  priceWithoutTaxEur: delivery_priceWithoutTaxEur,
  priceWithTaxEur: delivery_priceWithTaxEur,
  customerPriceWithoutTaxEur: delivery_customerPriceWithoutTaxEur,
  customerPriceWithTaxEur: delivery_customerPriceWithTaxEur,
  customerPriceWithoutTaxEurComposition: undefined,
  packaging: delivery_packaging,
  pickupSignature: delivery_pickupSignature,
  shippingSignature: delivery_shippingSignature,
  returnSignature: delivery_returnSignature,
  lastPickupTransition: undefined,
  lastShippingTransition: undefined,
  lastReturnTransition: undefined,
  canUpdateSlot: false,
  canUpdatePickupAddress: false,
  canUpdateShippingAddress: false,
  deliveryStateTransitions: delivery_deliveryStateTransitions,
  isArchived: false,
  owner: delivery_owner,
  customerPricesFactor: delivery_customerPricesFactor,
  canReschedule: false,
  canCancelDelivery: false,
  rescheduleDeliveryId: undefined,
  externalDeliveryCode: delivery_externalDeliveryCode,
  clientId: "1"
};

var Data = {
  generateSignature: generateSignature,
  delivery: delivery
};

var handlers = [Msw.http.get("https://api.testing.colisweb.com/api/v5/clients/:clientId/deliveries/:deliveryId/carrierApproach", (function (param) {
          var response_positionHistory = [{
              lat: 50,
              long: 3.011
            }];
          var response_arrivalEstimation = Caml_option.some(new Date());
          var response_updatedDate = Caml_option.some(new Date());
          var response_routePosition = 3;
          var response_endEstimation = Caml_option.some(new Date());
          var response = {
            positionHistory: response_positionHistory,
            arrivalEstimation: response_arrivalEstimation,
            updatedDate: response_updatedDate,
            routePosition: response_routePosition,
            endEstimation: response_endEstimation
          };
          return Msw.HttpResponse.json(Curry._1(ColiswebApi__V5_Client.Deliveries.GetCarrierApproach.response_encode, response));
        }))];

var worker = Caml_splice_call.spliceApply(Browser.setupWorker, [handlers]);

function Playground_DeliveryRouteBanner(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(MockOverlay.make, {
                    worker: worker,
                    workerOptions: import.meta.env.PROD ? ({
                          serviceWorker: {
                            url: "/mockServiceWorker.js"
                          }
                        }) : ({}),
                    className: "!absolute !-top-20",
                    children: JsxRuntime.jsx(ApiComponents__DeliveryRouteBanner.make, {
                          clientId: "1",
                          delivery: {
                            TAG: "Store",
                            _0: delivery
                          }
                        })
                  }),
              className: "relative"
            });
}

var make = Playground_DeliveryRouteBanner;

export {
  Data ,
  handlers ,
  worker ,
  make ,
}
/* delivery Not a pure module */
