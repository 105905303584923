

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as $$Request from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/request/Request.bs.js";
import * as DateFns from "date-fns";
import * as ReactIntl from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/vendors/ReactIntl.bs.js";
import * as ReactIntl$1 from "react-intl";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Toolkit__Hooks from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/hooks/Toolkit__Hooks.bs.js";
import * as Toolkit__Utils from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/utils/Toolkit__Utils.bs.js";
import * as Fa from "react-icons/fa";
import * as Server from "react-dom/server";
import * as Toolkit__Ui_Modal from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Toolkit__Ui_Button from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_Button.bs.js";
import * as Toolkit__Primitives from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives.bs.js";
import * as ColiswebApi__Locales from "../ColiswebApi__Locales.bs.js";
import * as ApiComponents__HereMap from "./ApiComponents__HereMap.bs.js";
import * as ColiswebApi__V5_Client from "../v5/ColiswebApi__V5_Client.bs.js";
import * as DifferenceInMinutes from "date-fns/differenceInMinutes";

function ApiComponents__DeliveryRouteBanner$DeliveryMap$PointIcon(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Fa.FaFlagCheckered, {
                    size: 24
                  }),
              className: "rounded-full text-white flex items-center justify-center bg-success-600 w-10 h-10"
            });
}

var PointIcon = {
  make: ApiComponents__DeliveryRouteBanner$DeliveryMap$PointIcon
};

function ApiComponents__DeliveryRouteBanner$DeliveryMap$CarrierIcon(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(Fa.FaTruck, {
                    size: 24
                  }),
              className: "rounded-full text-white flex items-center justify-center bg-primary-700 w-10 h-10"
            });
}

var CarrierIcon = {
  make: ApiComponents__DeliveryRouteBanner$DeliveryMap$CarrierIcon
};

function ApiComponents__DeliveryRouteBanner$DeliveryMap(props) {
  var delivery = props.delivery;
  var carrierApproach = props.carrierApproach;
  var disclosure = Toolkit__Hooks.useDisclosure(false, undefined);
  var intl = ReactIntl$1.useIntl();
  var statusProvider;
  statusProvider = delivery._0.statusProvider;
  var transition;
  transition = Toolkit__Primitives.$$Array.tail(delivery._0.deliveryStateTransitions);
  var timeSlot;
  timeSlot = delivery._0.timeSlot;
  var shippingPoint;
  shippingPoint = delivery._0.shipping;
  var shippingCoordinates = Belt_Option.getWithDefault(Belt_Option.flatMap(shippingPoint, (function (param) {
              return param.coords;
            })), {
        latitude: 0,
        longitude: 0
      });
  var match = carrierApproach.endEstimation;
  var match$1 = carrierApproach.arrivalEstimation;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Toolkit__Ui_Button.make, {
                      size: "xs",
                      color: "info",
                      variant: "pill",
                      onClick: (function (param) {
                          Curry._1(disclosure.show, undefined);
                        }),
                      className: "!uppercase !text-xs text-info-600",
                      children: Caml_option.some(JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                id: "_2cf559f6",
                                defaultMessage: "Voir la carte"
                              })),
                      leftIcon: {
                        make: Fa.FaMap
                      }
                    }),
                JsxRuntime.jsx(Toolkit__Ui_Modal.make, {
                      isVisible: disclosure.isOpen,
                      title: Caml_option.some(JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                id: "_a5695670",
                                defaultMessage: "Géolocalisation du livreur"
                              })),
                      body: JsxRuntime.jsxs("section", {
                            children: [
                              JsxRuntime.jsx(ApiComponents__HereMap.make, {
                                    mapOptions: {
                                      zoom: 8,
                                      center: {
                                        lat: shippingCoordinates.latitude,
                                        lng: shippingCoordinates.longitude
                                      },
                                      padding: {
                                        top: 20,
                                        left: 300,
                                        bottom: 100,
                                        right: 100
                                      }
                                    },
                                    onLoad: (function (param) {
                                        var mapInstance = param.mapInstance;
                                        var group = new H.map.Group();
                                        mapInstance.addObject(group);
                                        var pointMarker = new H.map.DomMarker({
                                              lat: shippingCoordinates.latitude,
                                              lng: shippingCoordinates.longitude
                                            }, {
                                              icon: Caml_option.some(new H.map.DomIcon(Server.renderToStaticMarkup(JsxRuntime.jsx(ApiComponents__DeliveryRouteBanner$DeliveryMap$PointIcon, {}))))
                                            });
                                        Belt_Option.forEach(Toolkit__Primitives.$$Array.tail(carrierApproach.positionHistory), (function (coordinates) {
                                                var carrierMarker = new H.map.DomMarker({
                                                      lat: coordinates.lat,
                                                      lng: coordinates.long
                                                    }, {
                                                      icon: Caml_option.some(new H.map.DomIcon(Server.renderToStaticMarkup(JsxRuntime.jsx(ApiComponents__DeliveryRouteBanner$DeliveryMap$CarrierIcon, {}))))
                                                    });
                                                group.addObject(carrierMarker);
                                              }));
                                        group.addObject(pointMarker);
                                        mapInstance.getViewModel().setLookAtData({
                                              bounds: group.getBoundingBox()
                                            });
                                      }),
                                    className: "h-[400px]"
                                  }),
                              JsxRuntime.jsxs("aside", {
                                    children: [
                                      JsxRuntime.jsxs("header", {
                                            children: [
                                              JsxRuntime.jsx("p", {
                                                    children: Curry._2(ColiswebApi__Locales.V5.Store.StatusProvider.humanize, intl, statusProvider),
                                                    className: "bg-white rounded-full leading-4 px-2 py-0.5 text-success-700 text-sm font-display font-semibold"
                                                  }),
                                              Belt_Option.mapWithDefault(transition, null, (function (param) {
                                                      var date = Belt_Option.getWithDefault(param.mobileDateTime, param.dateTime);
                                                      return JsxRuntime.jsxs("p", {
                                                                  children: [
                                                                    JsxRuntime.jsx(ReactIntl$1.FormattedDate, {
                                                                          value: date,
                                                                          year: "numeric",
                                                                          month: "numeric",
                                                                          day: "numeric"
                                                                        }),
                                                                    JsxRuntime.jsx("span", {
                                                                          children: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                value: date,
                                                                                separator: "h",
                                                                                hour: "numeric",
                                                                                minute: "numeric"
                                                                              }),
                                                                          className: "ml-1"
                                                                        })
                                                                  ],
                                                                  className: "bg-white rounded-full px-2 py-0.5 text-success-700 font-roboto text-xs"
                                                                });
                                                    }))
                                            ],
                                            className: "flex flex-row items-center justify-between px-2 py-1 bg-success-400 rounded-t-lg"
                                          }),
                                      Belt_Option.mapWithDefault(carrierApproach.updatedDate, null, (function (date) {
                                              return JsxRuntime.jsxs("section", {
                                                          children: [
                                                            JsxRuntime.jsx("p", {
                                                                  children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                        id: "_68cd4940",
                                                                        defaultMessage: "Mise à jour"
                                                                      }),
                                                                  className: "text-xxs text-neutral-600 uppercase"
                                                                }),
                                                            JsxRuntime.jsxs("p", {
                                                                  children: [
                                                                    JsxRuntime.jsx(ReactIntl$1.FormattedDate, {
                                                                          value: date,
                                                                          year: "numeric",
                                                                          month: "numeric",
                                                                          day: "numeric"
                                                                        }),
                                                                    JsxRuntime.jsx("span", {
                                                                          children: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                value: date,
                                                                                separator: "h",
                                                                                hour: "numeric",
                                                                                minute: "numeric"
                                                                              }),
                                                                          className: "ml-1"
                                                                        })
                                                                  ],
                                                                  className: "text-xxs text-neutral-700"
                                                                })
                                                          ],
                                                          className: "flex items-center justify-center gap-2 my-2"
                                                        });
                                            })),
                                      JsxRuntime.jsxs("section", {
                                            children: [
                                              JsxRuntime.jsx("p", {
                                                    children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                          id: "_3c7d4442",
                                                          defaultMessage: "Créneau commandé"
                                                        }),
                                                    className: "text-xs font-semibold text-primary-700 uppercase"
                                                  }),
                                              JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx("p", {
                                                            children: JsxRuntime.jsx(ReactIntl$1.FormattedDate, {
                                                                  value: timeSlot.start,
                                                                  weekday: "short",
                                                                  month: "short",
                                                                  day: "numeric"
                                                                }),
                                                            className: "first-letter:uppercase font-semibold text-primary-700 "
                                                          }),
                                                      JsxRuntime.jsx("p", {
                                                            children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                  id: "_3c96b7bc",
                                                                  defaultMessage: "{start} - {end}",
                                                                  values: {
                                                                    start: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                          value: timeSlot.start,
                                                                          separator: "h"
                                                                        }),
                                                                    end: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                          value: timeSlot.end,
                                                                          separator: "h"
                                                                        })
                                                                  }
                                                                }),
                                                            className: "font-semibold text-primary-700 border-l border-primary-700 ml-1 pl-1"
                                                          })
                                                    ],
                                                    className: "flex flex-row justify-center items-center bg-primary-50 text-center p-0.5 rounded"
                                                  })
                                            ],
                                            className: "mx-2 p-1 border border-primary-700 rounded "
                                          }),
                                      JsxRuntime.jsxs("section", {
                                            children: [
                                              JsxRuntime.jsx("p", {
                                                    children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                          id: "_b730000c",
                                                          defaultMessage: "Manutention"
                                                        }),
                                                    className: "font-semibold uppercase text-neutral-600 text-xs "
                                                  }),
                                              JsxRuntime.jsxs("p", {
                                                    children: [
                                                      JsxRuntime.jsx("span", {
                                                            children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                  id: "_33ce41d6",
                                                                  defaultMessage: "EST."
                                                                }),
                                                            className: "uppercase text-xxs text-neutral-600 mr-1 leading-3"
                                                          }),
                                                      match !== undefined && match$1 !== undefined ? JsxRuntime.jsx("span", {
                                                              children: Toolkit__Utils.durationMinutesToHourMinutesString(DifferenceInMinutes.differenceInMinutes(Caml_option.valFromOption(match), Caml_option.valFromOption(match$1)), "h", undefined),
                                                              className: "text-neutral-700 text-sm font-semibold "
                                                            }) : null
                                                    ],
                                                    className: "bg-gray-100 pl-2 rounded"
                                                  })
                                            ],
                                            className: "m-2 border rounded border-neutral-300 p-1"
                                          }),
                                      Belt_Option.mapWithDefault(carrierApproach.arrivalEstimation, null, (function (arrivalEstimation) {
                                              return JsxRuntime.jsxs("section", {
                                                          children: [
                                                            JsxRuntime.jsx("p", {
                                                                  children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                        id: "_2baf6ef7",
                                                                        defaultMessage: "Heure d’arrivée"
                                                                      }),
                                                                  className: "font-semibold uppercase text-info-700 text-xs "
                                                                }),
                                                            JsxRuntime.jsxs("p", {
                                                                  children: [
                                                                    JsxRuntime.jsx("span", {
                                                                          children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                                id: "_33ce41d6",
                                                                                defaultMessage: "EST."
                                                                              }),
                                                                          className: "uppercase text-xxs text-info-600 mr-1 leading-3"
                                                                        }),
                                                                    JsxRuntime.jsx("span", {
                                                                          children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                                id: "_3c96b7bc",
                                                                                defaultMessage: "{start} - {end}",
                                                                                values: {
                                                                                  start: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                        value: DateFns.subMinutes(arrivalEstimation, 5),
                                                                                        separator: "h",
                                                                                        hour: "numeric",
                                                                                        minute: "numeric"
                                                                                      }),
                                                                                  end: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                        value: DateFns.addMinutes(arrivalEstimation, 10),
                                                                                        separator: "h",
                                                                                        hour: "numeric",
                                                                                        minute: "numeric"
                                                                                      })
                                                                                }
                                                                              }),
                                                                          className: "font-semibold text-info-700"
                                                                        })
                                                                  ],
                                                                  className: "bg-info-50 pl-2 rounded"
                                                                })
                                                          ],
                                                          className: "m-2 border  rounded border-neutral-300 p-1"
                                                        });
                                            })),
                                      Belt_Option.mapWithDefault(shippingPoint, null, (function (param) {
                                              var name = param.name;
                                              return JsxRuntime.jsxs("section", {
                                                          children: [
                                                            JsxRuntime.jsx("p", {
                                                                  children: name !== undefined ? name : Belt_Option.getWithDefault(param.firstName, "") + " " + Belt_Option.getWithDefault(param.lastName, ""),
                                                                  className: "font-semibold text-neutral-700"
                                                                }),
                                                            JsxRuntime.jsx("p", {
                                                                  children: param.line1,
                                                                  className: "text-neutral-700"
                                                                }),
                                                            Belt_Option.mapWithDefault(param.line2, null, (function (line2) {
                                                                    return JsxRuntime.jsx("p", {
                                                                                children: line2,
                                                                                className: "text-neutral-700"
                                                                              });
                                                                  })),
                                                            JsxRuntime.jsx("p", {
                                                                  children: param.postalCode + " " + param.city,
                                                                  className: "text-neutral-600 uppercase"
                                                                }),
                                                            Belt_Option.mapWithDefault(param.country, null, (function (country) {
                                                                    return JsxRuntime.jsx("p", {
                                                                                children: country,
                                                                                className: "text-neutral-600 uppercase"
                                                                              });
                                                                  }))
                                                          ],
                                                          className: "px-1 py-2 rounded bg-neutral-100 m-2"
                                                        });
                                            }))
                                    ],
                                    className: "bg-white rounded-lg shadow-lg absolute left-2 top-2 w-60 z-10"
                                  })
                            ],
                            className: "relative"
                          }),
                      hide: (function (param) {
                          Curry._1(disclosure.hide, undefined);
                        })
                    })
              ]
            });
}

var DeliveryMap = {
  PointIcon: PointIcon,
  CarrierIcon: CarrierIcon,
  make: ApiComponents__DeliveryRouteBanner$DeliveryMap
};

function ApiComponents__DeliveryRouteBanner(props) {
  var delivery = props.delivery;
  var deliveryId;
  deliveryId = delivery._0.id;
  var transition;
  transition = Toolkit__Primitives.$$Array.tail(delivery._0.deliveryStateTransitions);
  var $$let = ColiswebApi__V5_Client.Deliveries.GetCarrierApproach;
  return JsxRuntime.jsxs("section", {
              children: [
                JsxRuntime.jsx("span", {
                      children: JsxRuntime.jsx(Fa.FaRoute, {
                            size: 16,
                            className: "self-center"
                          }),
                      className: "text-white bg-purple-500 flex-shrink-0 h-8 flex px-1"
                    }),
                JsxRuntime.jsx("p", {
                      children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                            id: "_1dc411bd",
                            defaultMessage: "Cette livraison fait partie d’une tournée."
                          }),
                      className: "text-purple-700 flex-shrink-0 px-2 font-semibold"
                    }),
                JsxRuntime.jsx($$Request.FetchAndRenderWithSuspense.make, {
                      config: {
                        response_decode: $$let.response_decode,
                        config: $$let.config
                      },
                      argument: [
                        props.clientId,
                        deliveryId
                      ],
                      children: (function (carrierApproach) {
                          return Belt_Option.mapWithDefault(carrierApproach.routePosition, null, (function (routePosition) {
                                        var match = carrierApproach.arrivalEstimation;
                                        if (match === undefined) {
                                          return null;
                                        }
                                        if (transition === undefined) {
                                          return null;
                                        }
                                        var arrivalEstimation = Caml_option.valFromOption(match);
                                        switch (transition.event) {
                                          case "package_withdrawn" :
                                          case "package_withdrawal_pb_ok" :
                                              break;
                                          default:
                                            return null;
                                        }
                                        return JsxRuntime.jsxs("div", {
                                                    children: [
                                                      JsxRuntime.jsx("p", {
                                                            children: routePosition !== 0 ? JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                    id: "_6fed50ba",
                                                                    defaultMessage: "Encore <countTag></countTag> {count, plural, one {étape} other {étapes}} avant livraison.",
                                                                    values: {
                                                                      count: routePosition,
                                                                      countTag: (function (_chunk) {
                                                                          return JsxRuntime.jsx("span", {
                                                                                      children: routePosition,
                                                                                      className: "bg-white px-1 text-neutral-600 py-0.5 rounded"
                                                                                    });
                                                                        })
                                                                    }
                                                                  }) : JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                    id: "_2a738b07",
                                                                    defaultMessage: "Prochaine étape de livraison"
                                                                  }),
                                                            className: "text-purple-700 font-semibold"
                                                          }),
                                                      JsxRuntime.jsxs("div", {
                                                            children: [
                                                              JsxRuntime.jsx("p", {
                                                                    children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                          id: "_4b51494c",
                                                                          defaultMessage: "Heure d’arrivée:"
                                                                        }),
                                                                    className: "font-semibold leading-3 mr-1 uppercase text-info-700 text-xs"
                                                                  }),
                                                              JsxRuntime.jsx("p", {
                                                                    children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                          id: "_33ce41d6",
                                                                          defaultMessage: "EST."
                                                                        }),
                                                                    className: "text-info-600 text-xxs"
                                                                  }),
                                                              JsxRuntime.jsx("p", {
                                                                    children: JsxRuntime.jsx(ReactIntl$1.FormattedMessage, {
                                                                          id: "_3c96b7bc",
                                                                          defaultMessage: "{start} - {end}",
                                                                          values: {
                                                                            start: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                  value: DateFns.subMinutes(arrivalEstimation, 5),
                                                                                  separator: "h",
                                                                                  hour: "numeric",
                                                                                  minute: "numeric"
                                                                                }),
                                                                            end: JsxRuntime.jsx(ReactIntl.FormattedTimeWithSeparator.make, {
                                                                                  value: DateFns.addMinutes(arrivalEstimation, 10),
                                                                                  separator: "h",
                                                                                  hour: "numeric",
                                                                                  minute: "numeric"
                                                                                })
                                                                          }
                                                                        }),
                                                                    className: "text-sm text-info-700 leading-3 mr-1 font-semibold"
                                                                  }),
                                                              JsxRuntime.jsx(ApiComponents__DeliveryRouteBanner$DeliveryMap, {
                                                                    carrierApproach: carrierApproach,
                                                                    delivery: delivery
                                                                  })
                                                            ],
                                                            className: "ml-auto mr-2 flex flex-row items-center px-1 py-px rounded bg-white border border-info-600"
                                                          })
                                                    ],
                                                    className: "w-full flex flex-row items-center"
                                                  });
                                      }));
                        }),
                      fallbackRender: (function (param) {
                          return null;
                        })
                    })
              ],
              className: "bg-purple-50 flex flex-row items-center border border-purple-500 rounded"
            });
}

var make = ApiComponents__DeliveryRouteBanner;

export {
  DeliveryMap ,
  make ,
}
/* Request Not a pure module */
