

import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as Spice from "../../../../node_modules/@greenlabs/ppx-spice/lib/es6_global/src/rescript/Spice.bs.js";
import * as Js_dict from "../../../../node_modules/rescript/lib/es6/js_dict.js";
import * as DateFns from "date-fns";
import * as Belt_Array from "../../../../node_modules/rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "../../../../node_modules/rescript/lib/es6/belt_Option.js";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as Identifiers from "../Identifiers.bs.js";
import * as Toolkit__Decoders from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/decoders/Toolkit__Decoders.bs.js";
import * as Toolkit__Primitives_Result from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/primitives/Toolkit__Primitives_Result.bs.js";

function t_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "code",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.code)
                  ],
                  [
                    "message",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.message)
                  ],
                  [
                    "detail",
                    false,
                    Toolkit__Decoders.$$Option.t_encode(Spice.stringToJson, v.detail)
                  ]
                ]));
}

function t_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var code = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "code"), null));
  if (code.TAG === "Ok") {
    var message = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "message"), null));
    if (message.TAG === "Ok") {
      var detail = Toolkit__Decoders.$$Option.t_decode(Spice.stringFromJson, Belt_Option.getWithDefault(Js_dict.get(v, "detail"), null));
      if (detail.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  code: code._0,
                  message: message._0,
                  detail: detail._0
                }
              };
      }
      var e = detail._0;
      return {
              TAG: "Error",
              _0: {
                path: ".detail" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = message._0;
    return {
            TAG: "Error",
            _0: {
              path: ".message" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = code._0;
  return {
          TAG: "Error",
          _0: {
            path: ".code" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

var GenericError = {
  t_encode: t_encode,
  t_decode: t_decode
};

function t_encode$1(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "start",
                    false,
                    Toolkit__Decoders.$$Date.t_encode(v.start)
                  ],
                  [
                    "end",
                    false,
                    Spice.optionToJson(Toolkit__Decoders.$$Date.t_encode, v.end)
                  ]
                ]));
}

function t_decode$1(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var start = Toolkit__Decoders.$$Date.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "start"), null));
  if (start.TAG === "Ok") {
    var end_ = Spice.optionFromJson(Toolkit__Decoders.$$Date.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "end"), null));
    if (end_.TAG === "Ok") {
      return {
              TAG: "Ok",
              _0: {
                start: start._0,
                end: end_._0
              }
            };
    }
    var e = end_._0;
    return {
            TAG: "Error",
            _0: {
              path: "." + ("end" + e.path),
              message: e.message,
              value: e.value
            }
          };
  }
  var e$1 = start._0;
  return {
          TAG: "Error",
          _0: {
            path: ".start" + e$1.path,
            message: e$1.message,
            value: e$1.value
          }
        };
}

function toString(period) {
  return DateFns.format(period.start, "yyyy-MM-dd") + Belt_Option.mapWithDefault(period.end, "-", (function (end_) {
                return DateFns.format(end_, "yyyy-MM-dd");
              }));
}

function eq(period1, period2) {
  if (DateFns.isSameDay(period1.start, period2.start)) {
    return Belt_Option.eq(period1.end, period2.end, (function (prim0, prim1) {
                  return DateFns.isSameDay(prim0, prim1);
                }));
  } else {
    return false;
  }
}

function overlapping(period1, period2) {
  var infiniteEndDate = Caml_option.some(new Date(2100.0, 0.0));
  var resetTimePeriod = function (p) {
    return {
            start: DateFns.setSeconds(DateFns.setMinutes(DateFns.setHours(p.start, 0), 0), 0),
            end: Belt_Option.map(p.end, (function (end_) {
                    return DateFns.setSeconds(DateFns.setMinutes(DateFns.setHours(end_, 0), 0), 0);
                  }))
          };
  };
  return DateFns.areIntervalsOverlapping(Belt_Option.mapWithDefault(period1.end, resetTimePeriod({
                      start: period1.start,
                      end: infiniteEndDate
                    }), (function (param) {
                    return resetTimePeriod(period1);
                  })), Belt_Option.mapWithDefault(period2.end, resetTimePeriod({
                      start: period2.start,
                      end: infiniteEndDate
                    }), (function (param) {
                    return resetTimePeriod(period2);
                  })), {
              inclusive: true
            });
}

var Period = {
  t_encode: t_encode$1,
  t_decode: t_decode$1,
  toString: toString,
  eq: eq,
  overlapping: overlapping
};

function encoder(_v) {
  return Spice.stringToJson("");
}

function decoder(json) {
  var unknownErrorCode = json.error;
  var value;
  if (unknownErrorCode !== undefined) {
    switch (unknownErrorCode) {
      case "cant_be_updated_anymore" :
          value = "cant_be_updated_anymore";
          break;
      case "consumer_updated_too_much" :
          value = "consumer_updated_too_much";
          break;
      case "forbidden" :
          value = "forbidden";
          break;
      case "invalid_conditions_from_grid" :
          value = "invalid_conditions_from_grid";
          break;
      case "invalid_slot" :
          value = "invalid_slot";
          break;
      case "max_capacity_reached_for_this_slot" :
          value = "max_capacity_reached_for_this_slot";
          break;
      case "the_request_is_expired" :
          value = "the_request_is_expired";
          break;
      case "too_late" :
          value = "too_late";
          break;
      case "unknown_error" :
          value = "unknown_error";
          break;
      default:
        value = {
          NAME: "unknown_error_code",
          VAL: unknownErrorCode
        };
    }
  } else {
    value = "no_error_code";
  }
  return {
          TAG: "Ok",
          _0: value
        };
}

var codec = [
  encoder,
  decoder
];

var t_encode$2 = encoder;

var t_decode$2 = decoder;

var $$Error = {
  encoder: encoder,
  decoder: decoder,
  codec: codec,
  t_encode: t_encode$2,
  t_decode: t_decode$2
};

function enum_encode(v) {
  switch (v) {
    case "monday" :
        return "monday";
    case "tuesday" :
        return "tuesday";
    case "wednesday" :
        return "wednesday";
    case "thursday" :
        return "thursday";
    case "friday" :
        return "friday";
    case "saturday" :
        return "saturday";
    case "sunday" :
        return "sunday";
    
  }
}

function enum_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("monday" === v) {
          return {
                  TAG: "Ok",
                  _0: "monday"
                };
        } else if ("tuesday" === v) {
          return {
                  TAG: "Ok",
                  _0: "tuesday"
                };
        } else if ("wednesday" === v) {
          return {
                  TAG: "Ok",
                  _0: "wednesday"
                };
        } else if ("thursday" === v) {
          return {
                  TAG: "Ok",
                  _0: "thursday"
                };
        } else if ("friday" === v) {
          return {
                  TAG: "Ok",
                  _0: "friday"
                };
        } else if ("saturday" === v) {
          return {
                  TAG: "Ok",
                  _0: "saturday"
                };
        } else if ("sunday" === v) {
          return {
                  TAG: "Ok",
                  _0: "sunday"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var t_encode$3 = enum_encode;

function t_decode$3(v) {
  return Curry._2(Toolkit__Primitives_Result.Result.flatMap, Spice.stringFromJson(v), (function ($$enum) {
                return enum_decode(Spice.stringToJson($$enum.toLowerCase()));
              }));
}

var toString$1 = t_encode$3;

var enumArray = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

function getNextDay(t) {
  switch (t) {
    case "monday" :
        return "tuesday";
    case "tuesday" :
        return "wednesday";
    case "wednesday" :
        return "thursday";
    case "thursday" :
        return "friday";
    case "friday" :
        return "saturday";
    case "saturday" :
        return "sunday";
    case "sunday" :
        return "monday";
    
  }
}

var DayOfWeek = {
  enum_encode: enum_encode,
  enum_decode: enum_decode,
  t_encode: t_encode$3,
  t_decode: t_decode$3,
  toString: toString$1,
  enumArray: enumArray,
  getNextDay: getNextDay
};

function t_encode$4(v) {
  switch (v) {
    case "today" :
        return "today";
    case "monday" :
        return "monday";
    case "tuesday" :
        return "tuesday";
    case "wednesday" :
        return "wednesday";
    case "thursday" :
        return "thursday";
    case "friday" :
        return "friday";
    case "saturday" :
        return "saturday";
    case "sunday" :
        return "sunday";
    
  }
}

function t_decode$4(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("today" === v) {
          return {
                  TAG: "Ok",
                  _0: "today"
                };
        } else if ("monday" === v) {
          return {
                  TAG: "Ok",
                  _0: "monday"
                };
        } else if ("tuesday" === v) {
          return {
                  TAG: "Ok",
                  _0: "tuesday"
                };
        } else if ("wednesday" === v) {
          return {
                  TAG: "Ok",
                  _0: "wednesday"
                };
        } else if ("thursday" === v) {
          return {
                  TAG: "Ok",
                  _0: "thursday"
                };
        } else if ("friday" === v) {
          return {
                  TAG: "Ok",
                  _0: "friday"
                };
        } else if ("saturday" === v) {
          return {
                  TAG: "Ok",
                  _0: "saturday"
                };
        } else if ("sunday" === v) {
          return {
                  TAG: "Ok",
                  _0: "sunday"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var toString$2 = t_encode$4;

var enumArray$1 = [
  "today",
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

var DeliveryDay = {
  t_encode: t_encode$4,
  t_decode: t_decode$4,
  toString: toString$2,
  enumArray: enumArray$1
};

function referenceStore_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "deliveryStart",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.deliveryStart)
                  ]
                ]));
}

function referenceStore_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
  if (storeId.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var deliveryStart = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStart"), null));
      if (deliveryStart.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  storeId: storeId._0,
                  clientId: clientId._0,
                  deliveryStart: deliveryStart._0
                }
              };
      }
      var e = deliveryStart._0;
      return {
              TAG: "Error",
              _0: {
                path: ".deliveryStart" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = storeId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".storeId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function pickupHub_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ],
                  [
                    "deliveryStart",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.deliveryStart)
                  ]
                ]));
}

function pickupHub_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
  if (clientId.TAG === "Ok") {
    var hubSlugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
    if (hubSlugId.TAG === "Ok") {
      var deliveryStart = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStart"), null));
      if (deliveryStart.TAG === "Ok") {
        return {
                TAG: "Ok",
                _0: {
                  clientId: clientId._0,
                  hubSlugId: hubSlugId._0,
                  deliveryStart: deliveryStart._0
                }
              };
      }
      var e = deliveryStart._0;
      return {
              TAG: "Error",
              _0: {
                path: ".deliveryStart" + e.path,
                message: e.message,
                value: e.value
              }
            };
    }
    var e$1 = hubSlugId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".hubSlugId" + e$1.path,
              message: e$1.message,
              value: e$1.value
            }
          };
  }
  var e$2 = clientId._0;
  return {
          TAG: "Error",
          _0: {
            path: ".clientId" + e$2.path,
            message: e$2.message,
            value: e$2.value
          }
        };
}

function t_encode$5(v) {
  if (v.TAG === "ReferenceStore") {
    return [
            "ReferenceStore",
            referenceStore_encode(v._0)
          ];
  } else {
    return [
            "PickupHub",
            pickupHub_encode(v._0)
          ];
  }
}

function t_decode$5(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (!Array.isArray(v)) {
    return Spice.error(undefined, "Not a variant", v);
  }
  if (v.length === 0) {
    return Spice.error(undefined, "Expected variant, found empty array", v);
  }
  var match = Belt_Array.getExn(v, 0);
  if (!(!Array.isArray(match) && (match === null || typeof match !== "object") && typeof match !== "number" && typeof match !== "string" && typeof match !== "boolean") && typeof match === "string") {
    switch (match) {
      case "PickupHub" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0 = pickupHub_decode(Belt_Array.getExn(v, 1));
          if (v0.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "PickupHub",
                      _0: v0._0
                    }
                  };
          }
          var e = v0._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e.path,
                    message: e.message,
                    value: e.value
                  }
                };
      case "ReferenceStore" :
          if (v.length !== 2) {
            return Spice.error(undefined, "Invalid number of arguments to variant constructor", v);
          }
          var v0$1 = referenceStore_decode(Belt_Array.getExn(v, 1));
          if (v0$1.TAG === "Ok") {
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "ReferenceStore",
                      _0: v0$1._0
                    }
                  };
          }
          var e$1 = v0$1._0;
          return {
                  TAG: "Error",
                  _0: {
                    path: "[0]" + e$1.path,
                    message: e$1.message,
                    value: e$1.value
                  }
                };
      default:
        
    }
  }
  return Spice.error(undefined, "Invalid variant constructor", Belt_Array.getExn(v, 0));
}

function referenceStoreBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type)
                  ],
                  [
                    "storeId",
                    false,
                    Curry._1(Identifiers.StoreId.t_encode, v.storeId)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "deliveryStart",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.deliveryStart)
                  ]
                ]));
}

function referenceStoreBody_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var storeId = Curry._1(Identifiers.StoreId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "storeId"), null));
    if (storeId.TAG === "Ok") {
      var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
      if (clientId.TAG === "Ok") {
        var deliveryStart = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStart"), null));
        if (deliveryStart.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    type: type_._0,
                    storeId: storeId._0,
                    clientId: clientId._0,
                    deliveryStart: deliveryStart._0
                  }
                };
        }
        var e = deliveryStart._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deliveryStart" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = clientId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".clientId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = storeId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".storeId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$3.path),
            message: e$3.message,
            value: e$3.value
          }
        };
}

function pickupHubBody_encode(v) {
  return Js_dict.fromArray(Spice.filterOptional([
                  [
                    "type",
                    false,
                    Spice.stringToJson(v.type)
                  ],
                  [
                    "clientId",
                    false,
                    Curry._1(Identifiers.ClientId.t_encode, v.clientId)
                  ],
                  [
                    "hubSlugId",
                    false,
                    Curry._1(Identifiers.HubSlugId.t_encode, v.hubSlugId)
                  ],
                  [
                    "deliveryStart",
                    false,
                    Toolkit__Decoders.Datetime.t_encode(v.deliveryStart)
                  ]
                ]));
}

function pickupHubBody_decode(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not an object", v);
  }
  if (!(typeof v === "object" && !Array.isArray(v))) {
    return Spice.error(undefined, "Not an object", v);
  }
  var type_ = Spice.stringFromJson(Belt_Option.getWithDefault(Js_dict.get(v, "type"), null));
  if (type_.TAG === "Ok") {
    var clientId = Curry._1(Identifiers.ClientId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "clientId"), null));
    if (clientId.TAG === "Ok") {
      var hubSlugId = Curry._1(Identifiers.HubSlugId.t_decode, Belt_Option.getWithDefault(Js_dict.get(v, "hubSlugId"), null));
      if (hubSlugId.TAG === "Ok") {
        var deliveryStart = Toolkit__Decoders.Datetime.t_decode(Belt_Option.getWithDefault(Js_dict.get(v, "deliveryStart"), null));
        if (deliveryStart.TAG === "Ok") {
          return {
                  TAG: "Ok",
                  _0: {
                    type: type_._0,
                    clientId: clientId._0,
                    hubSlugId: hubSlugId._0,
                    deliveryStart: deliveryStart._0
                  }
                };
        }
        var e = deliveryStart._0;
        return {
                TAG: "Error",
                _0: {
                  path: ".deliveryStart" + e.path,
                  message: e.message,
                  value: e.value
                }
              };
      }
      var e$1 = hubSlugId._0;
      return {
              TAG: "Error",
              _0: {
                path: ".hubSlugId" + e$1.path,
                message: e$1.message,
                value: e$1.value
              }
            };
    }
    var e$2 = clientId._0;
    return {
            TAG: "Error",
            _0: {
              path: ".clientId" + e$2.path,
              message: e$2.message,
              value: e$2.value
            }
          };
  }
  var e$3 = type_._0;
  return {
          TAG: "Error",
          _0: {
            path: "." + ("type" + e$3.path),
            message: e$3.message,
            value: e$3.value
          }
        };
}

function encode(body) {
  if (body.TAG === "ReferenceStore") {
    var match = body._0;
    return referenceStoreBody_encode({
                type: "referenceStore",
                storeId: match.storeId,
                clientId: match.clientId,
                deliveryStart: match.deliveryStart
              });
  }
  var match$1 = body._0;
  return pickupHubBody_encode({
              type: "pickupHub",
              clientId: match$1.clientId,
              hubSlugId: match$1.hubSlugId,
              deliveryStart: match$1.deliveryStart
            });
}

var Eligible = {
  referenceStore_encode: referenceStore_encode,
  referenceStore_decode: referenceStore_decode,
  pickupHub_encode: pickupHub_encode,
  pickupHub_decode: pickupHub_decode,
  t_encode: t_encode$5,
  t_decode: t_decode$5,
  referenceStoreBody_encode: referenceStoreBody_encode,
  referenceStoreBody_decode: referenceStoreBody_decode,
  pickupHubBody_encode: pickupHubBody_encode,
  pickupHubBody_decode: pickupHubBody_decode,
  encode: encode
};

function t_encode$6(v) {
  switch (v) {
    case "owner" :
        return "owner";
    case "admin" :
        return "admin";
    case "user" :
        return "user";
    
  }
}

function t_decode$6(v) {
  if (!Array.isArray(v) && (v === null || typeof v !== "object") && typeof v !== "number" && typeof v !== "string" && typeof v !== "boolean") {
    return Spice.error(undefined, "Not a JSONString", v);
  }
  switch (typeof v) {
    case "string" :
        if ("owner" === v) {
          return {
                  TAG: "Ok",
                  _0: "owner"
                };
        } else if ("admin" === v) {
          return {
                  TAG: "Ok",
                  _0: "admin"
                };
        } else if ("user" === v) {
          return {
                  TAG: "Ok",
                  _0: "user"
                };
        } else {
          return Spice.error(undefined, "Not matched", v);
        }
    case "number" :
        return Spice.error(undefined, "Not matched", v);
    default:
      return Spice.error(undefined, "Not a JSONString", v);
  }
}

var owner = {
  id: "_79115f77",
  defaultMessage: "Propriétaire"
};

var admin = {
  id: "_a67df150",
  defaultMessage: "Administrateur"
};

var user = {
  id: "_f628ae7e",
  defaultMessage: "Utilisateur"
};

function humanize(t, intl) {
  var msg;
  switch (t) {
    case "owner" :
        msg = owner;
        break;
    case "admin" :
        msg = admin;
        break;
    case "user" :
        msg = user;
        break;
    
  }
  return intl.formatMessage(msg);
}

var OrganizationRole = {
  t_encode: t_encode$6,
  t_decode: t_decode$6,
  owner: owner,
  admin: admin,
  user: user,
  humanize: humanize
};

export {
  GenericError ,
  Period ,
  $$Error ,
  DayOfWeek ,
  DeliveryDay ,
  Eligible ,
  OrganizationRole ,
}
/* date-fns Not a pure module */
