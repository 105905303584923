

import * as Msw from "msw";
import * as Curry from "../../../../node_modules/rescript/lib/es6/curry.js";
import * as DateFns from "date-fns";
import * as Caml_option from "../../../../node_modules/rescript/lib/es6/caml_option.js";
import * as MockOverlay from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/mock/MockOverlay.bs.js";
import * as Browser from "msw/browser";
import * as Caml_splice_call from "../../../../node_modules/rescript/lib/es6/caml_splice_call.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as ColiswebApi__V6_Account from "../../src/v6/ColiswebApi__V6_Account.bs.js";
import * as ApiComponents__UsersList from "../../src/components/ApiComponents__UsersList.bs.js";
import * as Toolkit__Ui_ErrorBoundary from "../../../../node_modules/@colisweb/rescript-toolkit/lib/es6_global/src/ui/Toolkit__Ui_ErrorBoundary.bs.js";

var handlers = [
  Msw.http.get("https://api.testing.colisweb.com/api/v6/account/external/users/clients/:clientId/human/list", (function (param) {
          var response = {
            users: [
              {
                uuid: "uuid",
                email: "test@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid2",
                email: "alpha@test.fr",
                organizationRole: "user",
                expiresAt: Caml_option.some(new Date())
              },
              {
                uuid: "uuid229292",
                email: "owner@test.fr",
                organizationRole: "owner",
                expiresAt: undefined
              },
              {
                uuid: "uuid3",
                email: "test2@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid4",
                email: "alpha2@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid5",
                email: "test3@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid6",
                email: "alpha3@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid7",
                email: "test4@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid8",
                email: "alpha4@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid9",
                email: "test5@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid10",
                email: "alpha5@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid2",
                email: "test2@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid22",
                email: "alpha2@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid23",
                email: "test22@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid24",
                email: "alpha22@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid25",
                email: "test32@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid26",
                email: "alpha32@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid27",
                email: "test42@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid28",
                email: "alpha42@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid29",
                email: "test52@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid210",
                email: "alpha52@test.fr",
                organizationRole: "user",
                expiresAt: undefined
              },
              {
                uuid: "uuid2Ad1",
                email: "zest2@test.fr",
                organizationRole: "admin",
                expiresAt: undefined
              }
            ]
          };
          return Msw.HttpResponse.json(Curry._1(ColiswebApi__V6_Account.Users.Clients.Human.List.response_encode, response));
        })),
  Msw.http.get("https://api.testing.colisweb.com/api/v6/account/external/users/clients/:clientId/invitation/list", (function (param) {
          var response = {
            invitations: [{
                uuid: "uuid2223",
                email: "invitation@test.fr",
                organizationRole: "admin",
                expiresAt: Caml_option.some(DateFns.addDays(new Date(), 1))
              }]
          };
          return Msw.HttpResponse.json(Curry._1(ColiswebApi__V6_Account.Users.Clients.Invitation.List.response_encode, response));
        })),
  Msw.http.put("https://api.testing.colisweb.com/api/v6/account/external/users/clients/:clientId/human/:userUuid", (function (param) {
          return Msw.HttpResponse.json({
                      code: "MAX_NUMBER_OF_ADMINISTRATORS"
                    }, {
                      status: 403
                    });
        })),
  Msw.http.post("https://api.testing.colisweb.com/api/v6/account/external/users/clients/:clientId/human/invitation", (function (param) {
          var response = {
            alreadyExistingEmails: []
          };
          return Msw.HttpResponse.json(Curry._1(ColiswebApi__V6_Account.Users.Clients.Human.SendInvitation.response_encode, response));
        }))
];

var worker = Caml_splice_call.spliceApply(Browser.setupWorker, [handlers]);

var Mock = {
  handlers: handlers,
  worker: worker
};

function Playground_UsersList(props) {
  return JsxRuntime.jsx("div", {
              children: JsxRuntime.jsx(MockOverlay.make, {
                    worker: worker,
                    workerOptions: import.meta.env.PROD ? ({
                          serviceWorker: {
                            url: "/mockServiceWorker.js"
                          }
                        }) : ({}),
                    className: "!absolute -top-20",
                    children: JsxRuntime.jsx(Toolkit__Ui_ErrorBoundary.make, {
                          fallbackRender: (function (param) {
                              return null;
                            }),
                          children: JsxRuntime.jsx(ApiComponents__UsersList.Hub.make, {
                                hubId: "de67d993-4076-45ec-94cb-22353731bf50"
                              })
                        })
                  }),
              className: "relative"
            });
}

var make = Playground_UsersList;

export {
  Mock ,
  make ,
}
/* handlers Not a pure module */
